import React from 'react'
import { Segment, Loader, Button, Icon, Divider } from 'semantic-ui-react'
import { Form } from 'react-advanced-form'
import messages from '../../forms/question/validation_message'
import SettingProfileForm from '../../forms/profile/setting'
import {connect} from 'react-redux'
import { toast } from 'react-toastify'
import storage from 'redux-persist/lib/storage'

// to detect every store of redux/state changed
function mapStateToProps(state) {
    return { 
        user_updated: state.user_updated,
        auth: state.auth
    }
}

// to detect every store of redux/state changed
function mapDispatchToProps(dispatch) {
    return { 
        userUpdated: (n) => dispatch({type: 'SET_USER_UPDATED', payload: n})
    }
}

class ProfileSettingSection extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            loading: true,
            loading1: false,
            userData: {}
        }
    }
    form = null

    componentDidMount(){
        this.loadData()
    }

    loadData = () =>{
        this.setState({loading: true})
        fetch(process.env.REACT_APP_API_URL+'api/v1/user/info?username='+this.props.auth.user.username, {
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            })
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }

            this.setState({userData: res.data, loading: false})
        }).catch((e)=>{
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }
    
    saveProfile = ({serialized, fields}) =>{
        if(fields.short_bio.invalid){
            toast.error('Short Bio characters reach maximum value.')
            return new Promise((resolve) => resolve())
        }
        this.setState({loading1: true})
        fetch(process.env.REACT_APP_API_URL+'api/v1/user/info/update', 
            {
                headers: new Headers({
                    'Authorization': 'Token '+this.props.auth.user.token
                }),
                method: 'post',
                body: JSON.stringify({
                    short_bio: serialized.short_bio,
                    contact_info: {
                        web: serialized.w_url !== undefined ? serialized.w_url : '',
                        linkedin: serialized.linkedin !== undefined ? serialized.linkedin : '',
                        twitter: serialized.twitter !== undefined ? serialized.twitter : '',
                        facebook: serialized.facebook !== undefined ? serialized.facebook : '',
                        instagram: serialized.instagram !== undefined ? serialized.instagram : ''
                    },
                    email: serialized.email
                })
            }
        ).then((res)=>{
            return res.json()
        }).then((res)=>{
            this.setState({loading1: false})
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }

            if(res.status){
                this.loadData()
                toast.success('Profile updated.')
                this.props.userUpdated(true)
            }else{
                toast.error(res.message)
                this.loadData()
            }
        }).catch((e)=>{
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
        return new Promise((resolve) => resolve())
    }

    render() {
        return (
            <Segment style={{paddingBottom: 50}}>
                {this.state.loading ? <Loader active/> : 
                    <>
                    <Form messages={messages} action={this.saveProfile} className='ui form' ref={el => this.form = el} id='profile_setting_form'>
                        <SettingProfileForm data={this.state.userData} token={this.props.auth.user.token}/>
                    </Form>
                    <Divider hidden/>
                    <Button loading={this.state.loading1} floated='right' primary onClick={()=>this.form.submit()} id='btn_save_profile'>
                        <Icon name='save'/> Save Changes
                    </Button>
                    </>
                }
            </Segment>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSettingSection)