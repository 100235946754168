import React from 'react'
import { Segment, Header, Icon, Loader, Divider, Pagination } from 'semantic-ui-react'
import { toast } from 'react-toastify'
import {connect} from 'react-redux'
import storage from 'redux-persist/lib/storage';
import CardComponent from '../../components/card';
import EmptyComponent from '../../components/empty';

// to detect every store of redux/state changed
function mapStateToProps(state) {
    return { 
        auth: state.auth
    }
}

class ProfileSubjectSection extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            loading1: true,
            loading2: true,
            ownedSubject: [],
            contributedSubject: [],
            totalPage: 0,
            activePage: 1
        }
    }

    componentDidMount(){
        let username = this.props.match.params.username
        username = username === 'me' ? this.props.auth.user.username : username
        fetch(process.env.REACT_APP_API_URL+'api/v1/user/subjects/owned?username='+username, {
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            })
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
            
            this.setState({ownedSubject: res.data, loading1: false})
        }).catch((e)=>{
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })

        this.loadContributedSubject()
    }

    loadContributedSubject = (activePage = 1) =>{
        let username = this.props.match.params.username
        username = username === 'me' ? this.props.auth.user.username : username
        this.setState({loading2: true})
        fetch(process.env.REACT_APP_API_URL+'api/v1/user/subjects/contributed?username='+username, {
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            }),
            method:'post',
            body: JSON.stringify({
                page: activePage,
                n: 12
            })
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
            
            this.setState({contributedSubject: res.data, loading2: false, totalPage: res.total_page})
        }).catch((e)=>{
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    handleChangePage = (data) =>{
        this.setState({activePage: data})
        this.loadContributedSubject(data)
    }

    render() {
        return (
            <Segment style={{paddingBottom: 40}}>
                <Header as='h3'>
                    <Icon name='folder'/> Owned Subjects
                </Header>
                {this.state.loading1 ? <Loader inline active/> : 
                    this.state.ownedSubject.length > 0 ? <CardComponent data={this.state.ownedSubject} size={4} type='thumbnail'/>
                    : <EmptyComponent size='tiny'/>
                }
                <Divider hidden/>
                <Header as='h3'>
                    <Icon name='folder'/> Contributed Subjects
                </Header>
                {this.state.loading2 ? <Loader inline active/> : 
                    this.state.contributedSubject.length > 0 ?
                    <>
                    <CardComponent data={this.state.contributedSubject} size={4} type='thumbnail'/>
                    <Divider hidden/>
                    <div style={{display:'flex', justifyContent: 'center'}}>
                        <Pagination onPageChange={(event, data) => 
                            this.handleChangePage(data.activePage)} defaultActivePage={this.state.activePage} totalPages={this.state.totalPage} />
                    </div>
                    </>
                    : <EmptyComponent size='tiny'/>
                }
            </Segment>
        )
    }
}

export default connect(mapStateToProps)(ProfileSubjectSection)