import React, {useState, useEffect} from 'react'
import {Grid, Image, Icon, Dropdown, Label, Popup} from 'semantic-ui-react'
import { Input, Textarea } from '../fields'
import ImageCropperComponent from '../../components/image_cropper'
import storage from 'redux-persist/lib/storage'
import {toast} from 'react-toastify'
import countryCode from '../../assets/CountryCodes.json'
import {
    DateInput
} from 'semantic-ui-calendar-react';
import moment from 'moment-timezone'

const CompletionProfileForm = (props) =>{
    const [listCode, setListCode] = useState([])
    const [userInfo, setUserInfo] = useState(null)
    const [modalOpen, setModalOpen] = useState(false)
    const [loadingAva, setLoadingAva] = useState(false)
    const [imgSrc, setImgSrc] = useState(null)
    const [bdate, setBdate] = useState('')
    const [bdateInput, setBdateInput] = useState('')
    const [unValid, setUnValid] = useState(false)
    const [username, setUsername] = useState('')
    const [timezones, setTimezones] = useState([])
    const [selectedTZ, setSelectedTZ] = useState('')
    const [password, setPassword] = useState({value: '', error: false})
    let fileInput = null

    useEffect(()=>{
        getIPData()
        loadData()
        let code = []
        let data = countryCode.slice(0,10)
        data.forEach(val =>{
            code.push({
                key: val.name,
                value: val.number,
                text: val.number+' '+val.name
            })
        })
        setListCode(code)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const loadData = () =>{
        fetch(process.env.REACT_APP_API_URL+'api/v1/user/info?username='+props.auth.user.username, 
            {
                headers: new Headers({
                    'Authorization': 'Token '+props.auth.user.token
                })
            }
        ).then((res)=>{
            return res.json()
        }).then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }

            setUserInfo(res.data)

        }).catch((e)=>{
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    const getIPData = async () => {
        const res = await fetch('https://geolocation-db.com/json/')
        let data = res.json()
        data.then(val=>{
            let tz = []
            let tmzList = moment.tz.names()
            let tzByCountry = moment.tz.zonesForCountry(val.country_code)
            let findTz = tzByCountry.filter(x=>x.includes(val.city))
            let usedTz = tzByCountry[0]
            if(findTz.length > 0){
                usedTz = findTz[0]
            }
            let txIdx = tmzList.findIndex(x=>x===usedTz)
            setSelectedTZ(usedTz)

            if(txIdx !== -1){
                for (let i = txIdx; i < txIdx+10; i++) {
                    tz.push({
                        key: i,
                        value: tmzList[i],
                        text: tmzList[i]
                    })
                }
                setTimezones(tz)
            }
        });
    }

    const onChangeImage = (e) =>{
        e.preventDefault();
        let files;
        
        if(e.target.files[0] === undefined){
            return false
        }

        if(e.target.files[0].type !== 'image/jpeg'){
            toast.error('Please choose correct format image.')
            return false
        }

        if(e.target.files[0].size > 2000000){
            toast.error('Image should be equal to or less than 2MB.')
            return false
        }

        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        
        const reader = new FileReader();
        reader.onload = () => {
            setImgSrc(reader.result)
            setModalOpen(true)
        };
        reader.readAsDataURL(files[0]);
    }

    const handleAvatarSave = (src) =>{
        fileInput.value = null
        if(src === null){
            setImgSrc(null)
            setModalOpen(false)
            return false
        }
        setLoadingAva(true)
        fetch(process.env.REACT_APP_API_URL+'api/v1/user/info/update', 
        {
            headers: new Headers({
                'Authorization': 'Token '+props.auth.user.token
            }),
            method: 'post',
            body: JSON.stringify({avatar: src})
        })
        .then((res)=>{
            return res.json()
        }).then((res)=>{
            setLoadingAva(true)
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }

            if(res.status){
                toast.success('Avatar updated')
                setModalOpen(false)
                loadData()
            }
        })
        .catch((e)=>{
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    const checkUsername = (e) =>{
        setUsername(e.nextValue)
        fetch(process.env.REACT_APP_API_URL+'api/v1/validate/username?value='+e.nextValue)
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(!res.status){
                setUnValid(false)
            }else{
                setUnValid(true)
            }
        })        
    }

    const handleSearchCountryCode = (e, { searchQuery }) =>{
        let data = countryCode
        let lCode = []

        searchQuery = searchQuery.toLowerCase()
        data = data.filter(x=>x.name.toLowerCase().includes(searchQuery) || x.number.toLowerCase().includes(searchQuery))
        if(data.length > 0){
            data = data.slice(0, 10)
            data.forEach(val =>{
                lCode.push({
                    key: val.name,
                    value: val.number,
                    text: val.number+' '+val.name
                })
            })
            setListCode(lCode)
        }
    }

    const handleSearchTimezone = (e, { searchQuery }) =>{
        let data = moment.tz.names()
        let tz = []

        searchQuery = searchQuery.toLowerCase()
        data = data.filter(x=>x.toLowerCase().includes(searchQuery))
        if(data.length > 0){
            data = data.slice(0, 10)
            data.forEach(x=>{
                tz.push({
                    key: x,
                    value: x,
                    text: x
                })
            })
            setTimezones(tz)
        }
    }

    const handleChangeBdate = (event, {name, value}) => {
        setBdate(value)
        let splitDate = value.split('-')
        if(splitDate.length  === 3 && value.length === 10){
            setBdateInput(splitDate[2]+'-'+splitDate[1]+'-'+splitDate[0])
        }else{
            setBdateInput('')
        }
    }

    const changePassword = (e) =>{
        let form = password
        form.value = e.nextValue
        form.error = false
        if(e.nextValue.length < 6){
            form.error = true
        }
        setPassword(form)
    }

    const changeTimezone = (e, data) =>{
        setSelectedTZ(data.value)
    }

    return (
        <Grid columns='equal'>
            <Grid.Column>
                {props.auth.user.is_service ?
                <>
                    {username !== '' ? 
                        !unValid ? <Label style={{float:'right'}} color='red'>
                            Username already exist
                        </Label> : <Label style={{float:'right'}} color='green'>
                            Username available
                        </Label>
                    : null}
                    <Input label='Username' name="username" required placeholder='John' onChange={checkUsername}/>

                    {password.value !== '' ? 
                        password.error ? <Label style={{float:'right'}} color='red'>
                            Password is not valid(at least 6 characters)
                        </Label> : <Label style={{float:'right'}} color='green'>
                            Password is valid
                        </Label>
                    : null}
                    <Input type='password' label='Password' name="password" required placeholder='At least 6 characters' onChange={changePassword}/>
                </>
                : null}
                <Input label='First Name' name="f_name" placeholder='John' required/>
                <Input label='Last Name' name="l_name" placeholder='Doe' required/>
                <div className="field">
                    <label>Phone Number</label>
                    <div className="equal width fields">
                        <div className='six wide field'>
                            <Dropdown
                                onSearchChange={handleSearchCountryCode}
                                id='phone_picker'
                                defaultValue='+62'
                                search
                                selection
                                options={listCode}
                            />
                        </div>
                        <Input type='number' name="phone"/>
                    </div>
                </div>
                <div className='field'>
                    <label>Birth Date</label>
                    <DateInput
                        placeholder='Birth Date'
                        maxDate={new Date()}
                        animation='scale'
                        hideMobileKeyboard
                        value={bdate}
                        iconPosition='right'
                        preserveViewMode={false}
                        autoComplete='off'
                        closable
                        onChange={handleChangeBdate}
                    />
                    <Input name='b_date' value={bdateInput} hidden/>
                </div>
                <div className='field'>
                    <label>Timezone</label>
                    <Dropdown
                        onSearchChange={handleSearchTimezone}
                        id='timezone_picker'
                        value={selectedTZ}
                        search
                        selection
                        options={timezones}
                        onChange={changeTimezone}
                    />
                </div>
                <Textarea label='Short Bio' name="short_bio" wlength={160}/>
            </Grid.Column>
            <Grid.Column>
                <div className='PAvatar'>
                    <Image src={
                        userInfo !== null ?
                        userInfo.avatar !== '' && userInfo.avatar !== null ? 
                        userInfo.avatar : require('../../assets/images/avatar-default.png')
                        : require('../../assets/images/avatar-default.png')
                    } circular />
                    <Popup trigger={
                        <div onClick={()=>fileInput.click()}>
                            <Icon name='camera'/> Change
                        </div> 
                    } content='Image size (Max: 2MB)'></Popup>
                    <input onChange={onChangeImage} accept="image/jpeg" 
                    style={{display:'none'}} 
                    ref={el => fileInput = el} type='file' name='avatar'/>

                    <ImageCropperComponent open={modalOpen} loading={loadingAva} 
                    imgSrc={imgSrc} fileInput={fileInput} 
                    ratio={1 / 1} act={handleAvatarSave}/>
                </div>
                <br/>
                <Input label='Website URL' name="w_url"/>
                <Input label='Linkedin Username' name="linkedin"/>
                <Input label='Twitter Username' name="twitter"/>
                <Input label='Facebook Username' name="facebook"/>
                <Input label='Instagram Username' name="instagram"/>
            </Grid.Column>
        </Grid>
    )
}

export default CompletionProfileForm