// to process questions object that need to send as parameter
export const processQuestionObject = (data, module_id) =>{
    let param = {}
    let mid = module_id
    if(module_id >= 20 && module_id <=25){
        mid = 4
    }else if(module_id >= 26 && module_id <=28){
        mid = 11
    }
    switch (mid) {
        case 1:
            param = {
                text: data.word,
                definition: data.definition,
                hint1: data.hint1,
                hint2: data.hint2,
                hint3: data.hint3
            }
            break;
        case 99:
            param = {
                text: data.word,
                definition: data.definition,
                hint1: data.hint1,
                hint2: data.hint2,
                hint3: data.hint3
            }
            break;
        case 2:
            param = {
                text: data.word
            }
            break;
        case 4:
            param = {
                image: data.image,
                text: data.word
            }
            break;
        case 7:
            let temp = {
                image: data.image,
            }
            if(data.topic !== undefined && data.topic !== ''){
                temp.topic = data.topic
            }
            temp.round1 = {
                question: data.question1,
                answer: data.answer1,
                wrong1: data.a1_wrong1,
                wrong2: data.a1_wrong2,
                wrong3: data.a1_wrong3
            }
            temp.round2 = {
                question: data.question2,
                answer: data.answer2,
                wrong1: data.a2_wrong1,
                wrong2: data.a2_wrong2,
                wrong3: data.a2_wrong3
            }
            temp.round3 = {
                question: data.question3,
                answer: data.answer3,
                wrong1: data.a3_wrong1,
                wrong2: data.a3_wrong2,
                wrong3: data.a3_wrong3
            }
            temp.round4 = {
                question: data.question4,
                answer: data.answer4,
                wrong1: data.a4_wrong1,
                wrong2: data.a4_wrong2,
                wrong3: data.a4_wrong3
            }

            param = temp
            break;
        case 10:
            param = {
                passage: data.passage,
                wrong: [data.wrong1, data.wrong2, data.wrong3]
            }
            break;
        case 12:
            param = {
                image: data.image,
                question: data.question,
                keywords: {
                    required: data.required_keywords,
                    optional: data.optional_keywords,
                },
                other_keywords: data.other_keywords
            }
            break;
        case 13:
            let param13 = {
                topic: data.topic,
                question: data.question,
                response: data.response,
                response_evaluation: [
                    {text: data.response_1, value: data.radioGroup_0},
                ]
            }

            if(data.response_2 !== undefined && data.radioGroup_1){
                param13.response_evaluation.push(
                    {text: data.response_2, value: data.radioGroup_1}
                )
            }
            if(data.response_3 !== undefined && data.radioGroup_2){
                param13.response_evaluation.push(
                    {text: data.response_3, value: data.radioGroup_2}
                )
            }
            if(data.response_4 !== undefined && data.radioGroup_3){
                param13.response_evaluation.push(
                    {text: data.response_4, value: data.radioGroup_3}
                )
            }
            if(data.response_5 !== undefined && data.radioGroup_4){
                param13.response_evaluation.push(
                    {text: data.response_5, value: data.radioGroup_4}
                )
            }

            param = param13
            break;
        case 14:
            param = {
                topic: data.topic,
                question: data.question,
                keywords: data.keywords
            }
            break;
        case 15:
            param = {
                image: data.image,
                topic: data.topic,
                round1: {
                    question: data.question1,
                    answer: data.answer1,
                    wrong1: data.a1_wrong1,
                    wrong2: data.a1_wrong2,
                    wrong3: data.a1_wrong3
                },
                round2: {
                    question: data.question2,
                    answer: data.answer2,
                    wrong1: data.a2_wrong1,
                    wrong2: data.a2_wrong2,
                    wrong3: data.a2_wrong3
                },
                round3: {
                    question: data.question3,
                    answer: data.answer3,
                    wrong1: data.a3_wrong1,
                    wrong2: data.a3_wrong2,
                    wrong3: data.a3_wrong3
                },
                round4: {
                    question: data.question4,
                    answer: data.answer4,
                    wrong1: data.a4_wrong1,
                    wrong2: data.a4_wrong2,
                    wrong3: data.a4_wrong3
                }
            }
            break;
        case 16:
            param = {
                dialogue1: {
                    script: data.script1,
                    wrong: [data.wrong1_script1, data.wrong2_script1, data.wrong3_script1]
                },
                reply1: data.reply1,
                dialogue2: {
                    script: data.script2,
                    wrong: [data.wrong1_script2, data.wrong2_script2, data.wrong3_script2]
                },
                reply2: data.reply2,
                dialogue3: {
                    script: data.script3,
                    wrong: [data.wrong1_script3, data.wrong2_script3, data.wrong3_script3]
                },
                reply3: data.reply3,
                dialogue4: {
                    script: data.script4,
                    wrong: [data.wrong1_script4, data.wrong2_script4, data.wrong3_script4]
                },
                reply4: data.reply4,
                dialogue5: {
                    script: data.script5,
                    wrong: [data.wrong1_script5, data.wrong2_script5, data.wrong3_script5]
                }
            }
            break;
        case 17:
            param = {
                passage: data.passage,
                wrong: [data.wrong1, data.wrong2, data.wrong3]
            }
            break;
        case 18:
            let param18 = {
                topic: data.topic,
                question: data.question,
                response: data.response,
                response_evaluation: [
                    {text: data.response_1, value: data.radioGroup_0},
                ]
            }
            
            if(data.response_2 !== undefined && data.radioGroup_1){
                param18.response_evaluation.push(
                    {text: data.response_2, value: data.radioGroup_1}
                )
            }
            if(data.response_3 !== undefined && data.radioGroup_2){
                param18.response_evaluation.push(
                    {text: data.response_3, value: data.radioGroup_2}
                )
            }
            if(data.response_4 !== undefined && data.radioGroup_3){
                param18.response_evaluation.push(
                    {text: data.response_4, value: data.radioGroup_3}
                )
            }
            if(data.response_5 !== undefined && data.radioGroup_4){
                param18.response_evaluation.push(
                    {text: data.response_5, value: data.radioGroup_4}
                )
            }
            
            param = param18
            break;
        // 11
        default:
            param = {
                dialogue1: {
                    script: data.script1,
                    wrong: [data.wrong1_script1, data.wrong2_script1, data.wrong3_script1]
                },
                reply1: data.reply1,
                dialogue2: {
                    script: data.script2,
                    wrong: [data.wrong1_script2, data.wrong2_script2, data.wrong3_script2]
                },
                reply2: data.reply2,
                dialogue3: {
                    script: data.script3,
                    wrong: [data.wrong1_script3, data.wrong2_script3, data.wrong3_script3]
                },
            }
            if(data.reply3 !== undefined){
                param.reply3 = data.reply3
                param.dialogue4 = {
                    script: data.script4,
                    wrong: [data.wrong1_script4, data.wrong2_script4, data.wrong3_script4]
                }

                param.reply4 = data.reply4
                param.dialogue5 = {
                    script: data.script5,
                    wrong: [data.wrong1_script5, data.wrong2_script5, data.wrong3_script5]
                }
            }
            break;
    }
    return param
}

export const processDiagnosticQuestionObject = (data, module_id) =>{
    let param = {}
    data.topic = parseInt(data.topic)
    switch (module_id) {
        // PRE A
        case 1:
            param = {
                topic: data.topic,
                options: data.options
            }
            break;
        // A1
        case 2:
            param = {
                topic: data.topic,
                category: data.category,
                correct_answer: data.correct_answer,
                random_words: data.random_words
            }
            break;
        // B2
        case 5:
            param = {
                topic: data.topic,
                sentence: data.sentence
            }
            break;
        // C1
        case 6:
            let wrong_answer = data.wrong_answer1
            if(data.wrong_answer2 !== undefined){
                wrong_answer+= ','+data.wrong_answer2
            }
            if(data.wrong_answer3 !== undefined){
                wrong_answer+= ','+data.wrong_answer3
            }
            param = {
                topic: data.topic,
                passage: data.passage,
                correct_answer: data.correct_answer,
                wrong_answer: wrong_answer
            }
            break;
        // A2 & B1
        default:
            param = {
                topic: data.topic,
                sentence: data.sentence,
                wrong_options: data.wrong_options
            }
            break;
    }
    return param
}