import React from 'react'
import {Image, Divider, Icon, Segment, Message, Popup} from 'semantic-ui-react'
import storage from 'redux-persist/lib/storage';
import { toast } from 'react-toastify';
import { Loader } from 'semantic-ui-react';
import Moment from 'react-moment';
import ImageCropperComponent from '../../components/image_cropper';
import { connect } from 'react-redux'

// to detect every store of redux/state changed
function mapStateToProps(state) {
    return { 
        user_updated: state.user_updated
    }
}

// to detect every store of redux/state changed
function mapDispatchToProps(dispatch) {
    return { 
        userUpdated: (n) => dispatch({type: 'SET_USER_UPDATED', payload: n})
    }
}

class ProfileInfoSection extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            loading: true,
            userInfo: {},
            contactInfo: [],
            modalOpen: false,
            loadingAva: false,
            imgSrc: null
        }
    }

    fileInput = null

    componentDidUpdate(oldProps, oldState){
        let loaded = false
        if(oldProps.match.params.username !== this.props.match.params.username){
            loaded = true
            this.loadData()
        }

        if(oldProps.user_updated !==this.props.user_updated){
            if(this.props.user_updated && !loaded){
                this.loadData()
            }
        }
        
    }

    componentDidMount(){
        this.loadData()
    }

    loadData = () =>{
        this.setState({loading: !this.state.loading})
        let username = this.props.match.params.username
        if(username === 'me'){
            username = this.props.auth.user.username
        }
        fetch(process.env.REACT_APP_API_URL+'api/v1/user/info?username='+username, {
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            })
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            this.setState({loading: false})
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }

            if(res.status){
                let contact = []
                let dataContact = res.data.contact_info

                if(dataContact !== null){
                    Object.keys(dataContact).forEach(function (item) {
                        if(dataContact[item] !== ''){
                            let icon = ''
                            let url = ''
                            switch (item) {
                                case 'web':
                                    icon = 'globe'
                                    url = dataContact[item]
                                    break;
                                case 'linkedin':
                                    icon = 'linkedin'
                                    url = 'https://www.linkedin.com/in/'+dataContact[item]
                                    break;
                                case 'twitter':
                                    icon = 'twitter'
                                    url = 'https://twitter.com/'+dataContact[item]
                                    break;
                                case 'facebook':
                                        icon = 'facebook f'
                                        url = 'https://www.facebook.com/'+dataContact[item]
                                        break;
                                default:
                                    icon = 'instagram'
                                    url = 'https://www.instagram.com/'+dataContact[item]
                                    break;
                            }
                            contact.push({
                                icon: icon,
                                url: url
                            })
                        }
                    });
                }
                this.props.userUpdated(false)
                this.setState({userInfo: res.data, contactInfo: contact})
            }else{
                toast.error(res.message)
            }
        }).catch((e)=>{
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    handleAvatarSave = (src) =>{
        this.fileInput.value = null
        if(src === null){
            this.setState({modalOpen: false, imgSrc: null})
            return false
        }
        this.setState({loadingAva: true})
        fetch(process.env.REACT_APP_API_URL+'api/v1/user/info/update', 
            {
                headers: new Headers({
                    'Authorization': 'Token '+this.props.auth.user.token
                }),
                method: 'post',
                body: JSON.stringify({avatar: src})
            }
        ).then((res)=>{
            return res.json()
        }).then((res)=>{
            this.setState({loadingAva: false})
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }

            if(res.status){
                toast.success('Avatar updated')
                this.props.userUpdated(true)
                this.setState({modalOpen: false})
            }
        }).catch((e)=>{
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    handleClickChange = () =>{
        if(this.fileInput !== null){
            this.fileInput.click()
        }
    }

    onChangeImage = (e)=>{
        e.preventDefault();
        let files;

        if(e.target.files[0] === undefined){
            return false
        }

        if(e.target.files[0].type !== 'image/jpeg'){
            toast.error('Please choose correct format image.')
            return false
        }

        if(e.target.files[0].size > 2000000){
            toast.error('Image should be equal to or less than 2MB.')
            return false
        }
        
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        
        const reader = new FileReader();
        reader.onload = () => {
            this.setState({imgSrc: reader.result, modalOpen: true})
        };
        reader.readAsDataURL(files[0]);
    }

    render() {
        return (
            <Segment style={{width: '103%'}}>
                {this.state.loading ? <Loader inline active/> : 
                <>
                    <div className='PAvatar'>
                        <Image src={
                            this.state.userInfo.avatar !== null && this.state.userInfo.avatar !== ''
                            ? this.state.userInfo.avatar : require('../../assets/images/avatar-default.png')
                        } circular />
                        {this.props.match.params.username === 'me' ? 
                        <>
                            <Popup trigger={
                                <div onClick={this.handleClickChange}>
                                    <Icon name='camera'/> Change
                                </div>
                            } content='Image size (Max: 2MB)'></Popup> 
                            <input onChange={this.onChangeImage} accept="image/jpeg" 
                            style={{display:'none'}} 
                            ref={el => this.fileInput = el} type='file' name='avatar'/>

                            <ImageCropperComponent open={this.state.modalOpen} loading={this.state.loadingAva} 
                            imgSrc={this.state.imgSrc} fileInput={this.fileInput} 
                            ratio={1 / 1} act={this.handleAvatarSave}/>
                        </>
                        : null}
                    </div>

                    <Divider hidden/>
                    <div className='PInfo'>
                        <p className='username'>
                           <Icon name='user outline'/> {this.state.userInfo.username} {this.state.userInfo.verified ? <Icon name='check circle' color='green'/> : null}
                        </p>
                        <p className='username'>
                            <Icon name='envelope outline'/> {this.state.userInfo.email}
                        </p>
                        
                        {this.state.userInfo.short_bio !== null && this.state.userInfo.short_bio !== ''
                            ? 
                            <Message size='tiny' info>
                                <Message.Header>Short Bio</Message.Header>
                                {this.state.userInfo.short_bio}
                            </Message>
                             : null
                        }
                        
                        {this.state.contactInfo.length > 0 ?
                            <>
                                <p className='username'>Contact Me</p>
                                <div style={{marginTop:-10}}>
                                    {this.state.contactInfo.map((v,k)=>
                                        <Icon key={k} onClick={()=>{
                                            window.open(
                                                v.url,
                                                '_blank'
                                            );
                                        }} circular inverted
                                        name={v.icon} link/>
                                    )}
                                </div>
                                <Divider hidden/>
                            </>
                        : null}
                        
                        <i className='email' style={{fontWeight:'bold'}}>
                            Last login:  
                            {this.state.userInfo.last_login === null ? 
                                ' Not Loggin yet'
                                : <Moment style={{marginLeft:3}} fromNow>{this.state.userInfo.last_login}</Moment>
                            }
                        </i>
                        <br/>
                        <i className='email'>Timezone: {this.state.userInfo.timezone}</i>
                        <br/>
                        <i className='email'>
                            Account Created: <Moment format="DD MMM YYYY">{this.state.userInfo.created_at}</Moment>
                        </i>
                    </div>
                </>
                }
            </Segment>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileInfoSection)