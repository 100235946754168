import React from 'react';
import './style.scss';

export default class LandingPage extends React.Component{

    componentDidMount(){
        // The checker
        const gambitGalleryIsInView = el => {
            const scroll = window.scrollY || window.pageYOffset
            const boundsTop = el.getBoundingClientRect().top + scroll

            const viewport = {
                top: scroll,
                bottom: scroll + window.innerHeight,
            }

            const bounds = {
                top: boundsTop,
                bottom: boundsTop + el.clientHeight,
            }

            return (bounds.bottom >= viewport.top && bounds.bottom <= viewport.bottom)
                || (bounds.top <= viewport.bottom && bounds.top >= viewport.top);
        }


        // Usage.
        document.addEventListener('DOMContentLoaded', () => {
            const tester = document.querySelector('#lp_masthead')

            const handler = () => raf(() => {
                if (!gambitGalleryIsInView(tester)){
                    document.querySelector(".top.fixed.menu").style.visibility = "visible";
                }else{
                    document.querySelector(".top.fixed.menu").style.visibility = "hidden";
                }
            })

            handler()
            window.addEventListener('scroll', handler)
        })

        const raf =
            window.requestAnimationFrame ||
            window.webkitRequestAnimationFrame ||
            window.mozRequestAnimationFrame ||
            function (callback) {
                window.setTimeout(callback, 1000 / 60)
            }
    }
    
    render() {
        return (
            <div id="landingPage">
                <div className="ui top fixed menu">
                    <a className="item image" href="/">
                        <img alt='content' src={require('../../assets/images/content.png')} />
                    </a>
                    {/* <a className="item" href="#">Browse <i className="caret down icon"></i></a> */}

                    <div className="right item">
                        <a className="item" href='/register' rel='login'>Sign Up</a>
                        <a className="item" href='/login' rel='login'>Log In</a>
                    </div>
                </div>
                <div className="ui vertical basic segment" id="lp_masthead">
                    <div className="ui secondary menu">
                        <a className="item image" href="/">
                            <img alt='content-white' src={require('../../assets/images/content-white.png')} />
                        </a>
                        {/* <a className="item" href="#">Browse <i className="caret down icon"></i></a> */}

                        <div className="right item">
                            <a className="item" href='/register' rel='login'>Sign Up</a>
                            <a className="item" href='/login' rel='login'>Log In</a>
                        </div>
                    </div>

                    <div className="ui container">
                        <div className="ui middle aligned center aligned grid">
                            <div className="sixteen wide column">
                                <h1>Your Knowledge for the World.</h1>
                                <h3>Join us to share your content and educate the world!</h3>
                                <div className="ui hidden divider"></div>
                                <div className="ui hidden divider"></div>
                                <a className="ui primary button" href='/register' rel='login'>GET STARTED</a>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="ui vertical basic segment" id="landing_desc">
                    <div className="ui container">
                        <div className="ui middle aligned stackable grid">
                            <div className="ui eight wide column">
                                <h1>Provide fun, free, <br/> easy-to-access way to learn!</h1>
                                <div className="custom_divider"></div>
                                <p>At Solve Education! We are committed to make a world where everyone can access education and gain confidence in their ability to learn. We gamify the learning contents to provide for a fun and engaging way to learn! But we can’t do it alone, join us to create contents and educate the world together!</p>
                            </div>
                            <div className="ui eight wide right aligned column">
                                <img alt='lp' id="lp_main_image" src={require('../../assets/images/lp_img_01.png')} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="ui vertical basic segment" id="landing_howitwork">
                    <div className="ui container">
                        <div className="ui center aligned stackable grid">
                            <div className="ui sixteen wide column">
                                <h1>How it works.</h1>
                                <div className="custom_divider"></div>
                                <div className="ui stackable grid" id="grid_how_it_works">
                                    <div className="four columns height equal row">
                                        <div className="column">
                                            <div className="ui segment">
                                                <img alt='hiw' src={require('../../assets/images/hiw_1.png')} />
                                                <h4>Create your content</h4>
                                                <p>Create a content together with your friends and colleagues.</p>
                                            </div>
                                        </div>
                                        <div className="column">
                                            <div className="ui segment">
                                                <img alt='hiw2' src={require('../../assets/images/hiw_2.png')} />
                                                <h4>Submit</h4>
                                                <p>When you’re happy with it, submit your content for review by Solve Education!</p>
                                            </div>
                                        </div>
                                        <div className="column">
                                            <div className="ui segment">
                                                <img alt='hiw3' src={require('../../assets/images/hiw_3.png')} />
                                                <h4>Publish</h4>
                                                <p>After passing the review process, your subject will be published to our app, Dawn of Civilization, for all of our users to access!</p>
                                            </div>
                                        </div>
                                        <div className="column">
                                            <div className="ui segment">
                                                <img alt='hiw4' src={require('../../assets/images/hiw_4.png')} />
                                                <h4>Add more!</h4>
                                                <p>If you think one is not enough, create more contents with us!</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="ui vertical basic segment" id="landing_video">
                    <div className="ui container">
                        <div className="ui middle aligned stackable grid">
                            <div className="ui ten wide column">
                                <img alt='ornament' id="ornament" src={require('../../assets/images/ornament.png')} />
                                <iframe title='youtube' width="95%" height="400" src="https://www.youtube.com/embed/us7sSFnnD9M" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </div>
                            <div className="ui six wide column">
                                <h1>Our Learning Platform</h1>
                                <div className="custom_divider"></div>
                                <p>Download our app, Dawn of Civilization, and experience your content in a gamified way.</p>
                                <a href="https://dawnofcivilization.net" target="_blank" rel="noopener noreferrer" className="ui primary button">Learn More</a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}