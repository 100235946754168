import React from 'react';
import {Button, Icon, Loader, Divider,Dropdown, Pagination, Tab, MenuItem, Grid,
    Checkbox, Popup, Modal, Header, Image, TextArea, List, Label, Dimmer} from 'semantic-ui-react';
import {Module2Column, Module4Column, Module2ColumnHistory, Module4ColumnHistory,
Module1Column, Module1ColumnHistory, Module7Column, Module7ColumnHistory, Module10Column, Module10ColumnHistory,
Module1ColumnQuestion, Module2ColumnQuestion, Module10ColumnQuestion, Module4ColumnQuestion, Module7ColumnQuestion,
Module11Column, Module11ColumnHistory, Module11ColumnQuestion, Module14Column, Module14ColumnHistory, Module14ColumnQuestion, 
Module12Column, Module12ColumnHistory, Module12ColumnQuestion, Module13Column, Module13ColumnHistory, Module13ColumnQuestion} from './table_columns';
import Module2Form from '../../../forms/question/module2';
import Module4Form from '../../../forms/question/module4';
import { Form } from 'react-advanced-form';
import { processQuestionObject } from '../../../helpers/questions';
import storage from 'redux-persist/lib/storage';
import { toast } from 'react-toastify';
import {connect} from 'react-redux';
import validationMessage from '../../../forms/question/validation_message';
import Module1Form from '../../../forms/question/module1';
import Module1AForm from '../../../forms/question/module1a';
import Module7Form from '../../../forms/question/module7';
import Module10Form from '../../../forms/question/module10';
import Module11Form from '../../../forms/question/module11';
import Module14Form from '../../../forms/question/module14';
import Module12Form from '../../../forms/question/module12';
import Module13Form from '../../../forms/question/module13';
import {getSubjectRedux} from '../../../helpers/redux';
import { PlatformInit, CheckExistData } from '../../../helpers/global';
import EmptyComponent from '../../../components/empty';
import SlideShowComponent from '../../../components/slide_show';
import DestinationFolderComponent from '../../../components/move_question';

// to detect every store of redux/state changed
function mapStateToProps(state){
    return{
        add_subject_step: state.add_subject_step,
        is_new_question: state.is_new_question,
        banner_data: state.banner_data
    }
}

// to detect every store of redux/state changed
function mapDispatchToProps(dispatch) {
    return { 
        setIsNewQuestion: (n) => dispatch({ type: 'IS_NEW_QUESTION', payload: n }),
        setBannerLoading: (n) => dispatch({ type: 'IS_BANNER_LOADING', payload: n }),
        setTaskManager: (n) => dispatch({ type: 'SET_TASK_MANAGER', payload: n })
    }
}

// table component
class TableSection extends React.Component{
    constructor(props){
        super(props)
        let url = new URL(window.location.href);
        let searchID = url.searchParams.get("id");
        if(searchID !== null){
            CheckExistData({
                header: this.props.myHeaders,
                category: 'question',
                identifier: searchID
            }).then(val=>{
                if(!val.status){
                    window.location.href = '/not-found'
                    return false
                }
            })
        }

        const currentSubject = getSubjectRedux(this.props.add_subject_step, this.props.match.params.code)
        if(currentSubject.role === null && currentSubject.private){
            window.location.href = '/subject/detail/'+currentSubject.code+'/content'
            return false
        }
        let dropdownPlatform = []
        PlatformInit().forEach(x=>{
            dropdownPlatform.push({
                key: x.value,
                text: x.text,
                value: x.value
            })
        })

        this.state = {
            currentSubject: currentSubject,
            currentWidth: 0,
            totalWidth: 0, 
            formNew: [],
            urlParams: this.props.match.params,
            module_game_id: 0,
            loading: true,
            questionList: [],
            totalPage: 0,
            totalRecord: 0,
            filter:{
                "n": 10,
                "page": 1,
                "search": "",
                "is_review": 0,
                "is_adaptive": currentSubject.adaptive ? true : false
            },
            currentQuestionId: null,
            is_new_question: this.props.is_new_question,
            history_modal: false,
            historyList: [],
            view_changes_modal: false,
            viewChangesList: null,
            previewImage: [],
            previewModal: false,
            currentMinigameId: null,
            comment: '',
            declineModal: false,
            declinedId: null,
            rowHover: false,
            rowHoverId: 0,
            searchID: searchID,
            isApprove: false,
            response_ev: null,
            saveLoading: false,
            previewLoading: false,
            portalOpen: false,
            portalMessage: '',
            deleteConfirm: false,
            deleteLoading: false,
            selectedEntry: null,
            refreshPreviewLoading: false,
            selectedQuestion: null,
            selectedPlatform: dropdownPlatform[0].value,
            platformList: dropdownPlatform,
            destinyModal: false,
            sourceData: {
                question_ids: []
            },
            allQuestionIds: [],
            isCheckAll: false,
            subject_module_id: 0
        }
    }

    // detect whether the question form loaded or not
    componentDidUpdate(oldProps, oldStates){
        if(oldProps.is_new_question !== this.props.is_new_question){
            if(this.props.is_new_question){
                let questions = this.state.questionList
                let idxo = questions.findIndex(x=>x.is_edit)
                if(idxo !== -1){
                    questions[idxo].is_edit = false
                }
                this.setState({questionList: questions, currentQuestionId: null})
                setTimeout(()=>{
                    this.setState({is_new_question: this.props.is_new_question})
                }, 100)
            }else{
                this.setState({is_new_question: this.props.is_new_question})
            }
        }

        if(this.props.is_new_question || this.state.currentQuestionId !== null){
            window.onbeforeunload = function(e) {
                return "Changes you made may not be saved.";
            };
        }else{
            window.onbeforeunload = null;
        }
    }

    // decalre ref form
    form = {}

    // set add new question form not active and fetching question list
    componentDidMount(){
        this.props.setIsNewQuestion(false)
        this.loadQuestion()
    }

    // fetching question list
    loadQuestion = () =>{
        if(this.state === null){
            window.location.href = ''
            return false
        }
        let query = this.state.searchID !== null ? '?id='+this.state.searchID : ''
        fetch(process.env.REACT_APP_API_URL+'api/v1/questions/'+this.state.urlParams.mg+'/list'+query, {
            headers: this.props.myHeaders,
            method: 'post',
            body: JSON.stringify(this.state.filter)
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            this.setState({loading: false})
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }

            let questions = []
            res.data.forEach(el => {
                if(this.state.isCheckAll){
                    el.selected = true
                }else{
                    el.selected = false
                }
                el.is_edit = false
                el.status = el.status === 1 ? true : false
                questions.push(el)
            });
            
            if(res.module_id === 13 || res.module_id === 18){
                if(res.data.length > 0){
                    this.setState({response_ev: res.data[0].question_object})
                }else{
                    let res_ev = window.localStorage.getItem('res_ev')
                    res_ev = JSON.parse(res_ev)
                    if(res_ev !== null){
                        let identifier = this.props.match.params.code+'-'+this.props.match.params.mg
                        let exist = res_ev.findIndex(x=>x.identifier === identifier)
                        if(exist !== -1){
                            let modelRes = {
                                response_evaluation: []
                            }
                            res_ev[exist].response.forEach(x=>{
                                modelRes.response_evaluation.push({text: x})
                            })
                            this.setState({response_ev: modelRes})
                        }
                    }
                }
            }

            let source = this.state.sourceData
            if(this.state.isCheckAll){
                source.question_ids = res.all_question_ids
            }else{
                source.question_ids = []
            }

            this.setState({formNew: this.renderModuleFormQuestion(res.module_id), questionList: questions,
                module_game_id: res.module_id === null ? 0 : res.module_id, totalPage: res.total_page, 
                totalRecord: res.total_record, currentMinigameId: res.minigame_id, isApprove: res.question_review, 
                allQuestionIds: res.all_question_ids, sourceData: source, subject_module_id: res.subject_module_id})
        }).catch((e)=>{
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    // render form based on module
    renderModuleFormQuestion = (mid, data=null) =>{
        let k = []
        let module_id = mid
        if(module_id >= 20 && module_id <=25){
            module_id = 4
        }else if(module_id >= 26 && module_id <=28){
            module_id = 11
        }

        if(data !== null){
            k.push(<td key='no'>{data.question_number}</td>)
            switch (module_id) {
                case 1:
                    k.push(<Module1Form data={data} key='fr'/>)
                    break;
                // module 1a
                case 99:
                    k.push(<Module1AForm data={data} key='fr'/>)
                    break;
                case 2:
                    k.push(<Module2Form data={data} key='fr'/>)
                    break;
                case 4:
                    k.push(<Module4Form data={data} key='fr'/>)
                    break;
                case 7:
                    if(this.state.currentSubject.adaptive){
                        k.push(<Module7Form data={data} key='fr' is_adaptive={true}/>)
                    }else{
                        k.push(<Module7Form data={data} key='fr'/>)
                    }
                    break;
                case 10:
                    k.push(<Module10Form data={data} key='fr'/>)
                    break;
                case 12:
                    k.push(<Module12Form data={data} key='fr'/>)
                    break;
                case 13:
                    k.push(<Module13Form data={data} key='fr' is_adaptive={this.state.currentSubject.adaptive}/>)
                    break;
                case 14:
                    k.push(<Module14Form data={data} key='fr'/>)
                    break;
                case 15:
                    k.push(<Module7Form data={data} key='fr' is_adaptive={true}/>)
                    break;
                case 16:
                    k.push(<Module11Form data={data} key='fr' is_adaptive={true}/>)
                    break;
                case 17:
                    k.push(<Module10Form data={data} key='fr'/>)
                    break;
                case 18:
                    k.push(<Module13Form data={data} key='fr' is_adaptive={true}/>)
                    break;
                // 11
                default:
                    k.push(<Module11Form data={data} key='fr' is_adaptive={this.state.currentSubject.adaptive}/>)
                    break;
            }

            k.push(<td key='act'>
                <Button type='button' secondary size='mini' disabled={this.state.saveLoading}
                onClick={this.onCancelClick}>Cancel</Button>
                <Button disabled={this.state.saveLoading} onClick={this.saveButtonClick} type='button'
                loading={this.state.saveLoading} primary size='mini'>
                    <Icon name='save'/> Save
                </Button>
            </td>)
        }else{
            switch (module_id) {
                case 1:
                    k.push(<Module1Form key='fr'/>)
                    break;
                // module 1a
                case 99:
                    k.push(<Module1AForm key='fr'/>)
                    break;
                case 2:
                    k.push(<Module2Form key='fr'/>)
                    break;
                case 4:
                    k.push(<Module4Form key='fr'/>)
                    break;
                case 7:
                    if(this.state.currentSubject.adaptive){
                        k.push(<Module7Form key='fr' is_adaptive={true}/>)
                    }else{
                        k.push(<Module7Form key='fr'/>)
                    }
                    break;
                case 10:
                    k.push(<Module10Form key='fr'/>)
                    break;
                case 12:
                    k.push(<Module12Form key='fr'/>)
                    break;
                case 13:
                    k.push(<Module13Form key='fr' q_object={this.state.response_ev} is_adaptive={this.state.currentSubject.adaptive}/>)
                    break;
                case 14:
                    k.push(<Module14Form key='fr'/>)
                    break;
                case 15:
                    k.push(<Module7Form key='fr' is_adaptive={true}/>)
                    break;
                case 16:
                    k.push(<Module11Form key='fr' is_adaptive={true}/>)
                    break;
                case 17:
                    k.push(<Module10Form key='fr'/>)
                    break;
                case 18:
                    k.push(<Module13Form key='fr' q_object={this.state.response_ev} is_adaptive={true}/>)
                    break;
                // 11
                default:
                    k.push(<Module11Form key='fr' is_adaptive={this.state.currentSubject.adaptive}/>)
                    break;
            }
        }
        
        return k
    }

    // save question record
    saveQuestion = ({serialized, fields}) =>{
        const keys = Object.keys(fields)
        let message = []
        let isValid = true
        keys.forEach(k => {
            if(fields[k].invalid){
                message.push(<div>
                    Please check maximum character or correct input on <b style={{textTransform:'uppercase'}}>{k}</b>
                </div>)
                isValid = false
            }
        });
        if(!isValid){
            toast.error(<List bulleted>
                {message.map((v,k)=><List.Item key={k}>{v}</List.Item>)}
            </List>)
            return new Promise((resolve) => resolve())
        }
        
        this.setState({saveLoading: true})
        let params = this.state.urlParams
        fetch(process.env.REACT_APP_API_URL+'api/v1/questions/'+params.mg+'/save', {
            headers: this.props.myHeaders,
            method: 'post',
            body: JSON.stringify({
                is_adaptive: this.state.currentSubject.adaptive ? true : false,
                id: this.state.currentQuestionId,
                question_object: processQuestionObject(serialized, this.state.module_game_id)
            })
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            this.setState({saveLoading: false})
            this.props.setBannerLoading(false)
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }

            if(res.status){
                let role = this.state.currentSubject.role
                let message = 'Submitted as draft. Waiting for owner/manager approval.'
                if(role === 3){
                    message = 'Entry submitted.'
                }
                toast.success(message)
                
                this.loadQuestion()
                this.props.setIsNewQuestion(false)
                if(this.state.currentQuestionId === null){
                    setTimeout(()=>{
                        let filter = this.state.filter
                        filter.page = this.state.totalPage
                        this.setState({filter: filter, currentQuestionId: null})
                        
                        window.setTimeout(()=>{
                            if(this.state.currentQuestionId === null){
                                this.handleChangePagination(null, {activePage: this.state.totalPage})
                            }
                        }, 10)
                    }, 10)
                }else{
                    this.setState({currentQuestionId: null})
                }

                setTimeout(()=>{
                    this.props.setBannerLoading(true)
                }, 10)

                this.props.setTaskManager(true)
            }else{
                if(res.data.length > 1){
                    toast.error(
                        <div>
                            Fail to submit : <br/>
                            <List bulleted>
                            {res.data.map((v,k)=>
                            <List.Item key={k}>{v}</List.Item>
                            )}
                            </List>
                        </div>
                    )
                }else{
                    if(res.data.length === 1){
                        toast.error('Fail to submit. '+res.data[0])
                    }else{
                        toast.error('Fail to submit. '+res.message)
                    }
                }
                
            }

        }).catch((e)=>{
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })

        return new Promise((resolve) => resolve())
    }

    // approve and decline process
    actionQuestionAll = (type, e) =>{
        if(type==='approve'){
            if(window.confirm('Are you sure want to approve them?')){
                this.processApproveDecline('approve', 'all')
            }
        }else{
            this.setState({declineModal: true, declinedId: null})
        }
    }

    // single approve or decline
    singleActionTable = (type, id, e) =>{
        switch (type) {
            // approve
            case 1:
                this.processApproveDecline('approve', id)
                break;
            // decline
            default:
                this.setState({declineModal: true, declinedId: id})
                break;
        }
    }

    // process approve and decline on database
    processApproveDecline = (type, qid) =>{
        fetch(process.env.REACT_APP_API_URL+'api/v1/questions/'+this.state.urlParams.mg+'/'+type+'/'+qid, {
            headers: this.props.myHeaders,
            method: 'post',
            body: JSON.stringify({
                is_adaptive: this.state.currentSubject.adaptive ? true : false,
                comment: this.state.comment
            })
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            this.props.setBannerLoading(false)
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
            
            if(res.status){
                toast.success('success '+type)
                this.loadQuestion()
                setTimeout(()=>{
                    this.props.setBannerLoading(true)
                }, 10)
                this.props.setTaskManager(true)
            }else{
                toast.error(res.message)
            }
        }).catch((e)=>{
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    // on search table handler
    onChangeSearch = (e) =>{
        let filter = this.state.filter
        filter.search = e.target.value
        filter.page = 1
        this.setState({filter: filter})
        window.setTimeout(()=>{
            this.loadQuestion()
        }, 0)
    }

    // show only question need to review filter
    showReviewContent = (e, data) =>{
        let filter = this.state.filter
        filter.is_review = data.checked ? 1 : 0
        filter.page = 1
        this.setState({filter: filter})
        window.setTimeout(()=>{
            this.loadQuestion()
        }, 0)
    }

    // change how many records display
    changeEntries = (e, data) =>{
        let filter = this.state.filter
        filter.n = data.value
        filter.page = 1
        this.setState({filter: filter})
        window.setTimeout(()=>{
            this.loadQuestion()
        }, 0)
    }

    // pagination change handler
    handleChangePagination = (e, data) =>{
        let filter = this.state.filter
        filter.page = data.activePage
        this.setState({filter: filter})
        window.setTimeout(()=>{
            this.loadQuestion()
        }, 0)
    }

    // when click edit button to load form on row
    onEditClick = (data, e) =>{
        this.setState({previewLoading: true})
        this.props.setIsNewQuestion(false)
        let id = data.question_id
        this.setState({currentQuestionId: id})
        let questions = this.state.questionList
        let idx = questions.findIndex(x=>x.question_id === id)
        let idxo = questions.findIndex(x=>x.is_edit)
        if(idxo !== -1){
            questions[idxo].is_edit = false
        }
        this.setState({questionList: questions})
        
        setTimeout(()=>{
            if(idx !== -1){
                questions[idx].is_edit = true
            }
            this.setState({questionList: questions})

            setTimeout(()=>{
                this.setModuleFormValue(data)
            }, 100)
        }, 100)
    }

    // when click cancel button on edit or add new question process
    onCancelClick = () =>{
        this.props.setIsNewQuestion(false)
        let questions = this.state.questionList
        let idxo = questions.findIndex(x=>x.is_edit)
        if(idxo !== -1){
            questions[idxo].is_edit = false
        }
        this.setState({questionList: questions, currentQuestionId: null})
    }

    // set module default value when click edit
    setModuleFormValue = (data) =>{
        let module_id = this.state.module_game_id
        if(module_id >= 20 && module_id <=25){
            module_id = 4
        }else if(module_id >= 26 && module_id <=28){
            module_id = 11
        }
        switch (module_id) {
            case 1:
                this.form.setValues({
                    word: data.question_object.text,
                    definition: data.question_object.definition,
                    hint1: data.question_object.hint1,
                    hint2: data.question_object.hint2,
                    hint3: data.question_object.hint3
                })
                break;
            // module 1a
            case 99:
                this.form.setValues({
                    word: data.question_object.text,
                    definition: data.question_object.definition,
                    hint1: data.question_object.hint1,
                    hint2: data.question_object.hint2,
                    hint3: data.question_object.hint3
                })
                break;
            case 2:
                this.form.setValues({
                    word: data.question_object.text
                })
                break;
            case 4:
                this.form.setValues({
                    word: data.question_object.text,
                    image: data.question_object.image
                })
                break;
            case 7:
                let param = {
                    image: data.question_object.image,
                    question1: data.question_object.round1.question,
                    question2: data.question_object.round2.question,
                    question3: data.question_object.round3.question,
                    question4: data.question_object.round4.question,
                    a1_wrong1: data.question_object.round1.wrong1,
                    a1_wrong2: data.question_object.round1.wrong2,
                    a1_wrong3: data.question_object.round1.wrong3,
                    a2_wrong1: data.question_object.round2.wrong1,
                    a2_wrong2: data.question_object.round2.wrong2,
                    a2_wrong3: data.question_object.round2.wrong3,
                    a3_wrong1: data.question_object.round3.wrong1,
                    a3_wrong2: data.question_object.round3.wrong2,
                    a3_wrong3: data.question_object.round3.wrong3,
                    a4_wrong1: data.question_object.round4.wrong1,
                    a4_wrong2: data.question_object.round4.wrong2,
                    a4_wrong3: data.question_object.round4.wrong3,
                    answer1: data.question_object.round1.answer,
                    answer2: data.question_object.round2.answer,
                    answer3: data.question_object.round3.answer,
                    answer4: data.question_object.round4.answer,
                }
                if(this.state.currentSubject.adaptive){
                    param.topic = data.question_object.topic
                }
                this.form.setValues(param)
                break;
            case 10:
                this.form.setValues({
                    passage: data.question_object.passage,
                    wrong1: data.question_object.wrong[0],
                    wrong2: data.question_object.wrong[1],
                    wrong3: data.question_object.wrong[2]
                })
                break;
            case 12:
                this.form.setValues({
                    image: data.question_object.image,
                    question: data.question_object.question,
                    required_keywords: data.question_object.keywords.required,
                    optional_keywords: data.question_object.keywords.optional,
                    other_keywords: data.question_object.other_keywords
                })
                break;
            case 13:
                let form13 = {
                    topic: data.question_object.topic,
                    question: data.question_object.question,
                    response: data.question_object.response
                }

                for (let i = 0; i < data.question_object.response_evaluation.length; i++) {
                    if(data.question_object.response_evaluation[i].text && data.question_object.response_evaluation[i].value){
                        switch (i){
                            case 0:
                                form13.response_1 = data.question_object.response_evaluation[i].text
                                form13.radioGroup_0 = data.question_object.response_evaluation[i].value
                                break
                            case 1:
                                form13.response_2 = data.question_object.response_evaluation[i].text
                                form13.radioGroup_1 = data.question_object.response_evaluation[i].value
                                break
                            case 2:
                                form13.response_3 = data.question_object.response_evaluation[i].text
                                form13.radioGroup_2 = data.question_object.response_evaluation[i].value
                                break
                            case 3:
                                form13.response_4 = data.question_object.response_evaluation[i].text
                                form13.radioGroup_3 = data.question_object.response_evaluation[i].value
                                break
                            case 4:
                                form13.response_5 = data.question_object.response_evaluation[i].text
                                form13.radioGroup_4 = data.question_object.response_evaluation[i].value
                                break
                            default:
                                continue
                        }
                    }
                }
                
                this.form.setValues(form13)
                break;
            case 14:
                this.form.setValues({
                    topic: data.question_object.topic,
                    question: data.question_object.question,
                    keywords: data.question_object.keywords
                })
                break;
            case 15:
                this.form.setValues({
                    image: data.question_object.image,
                    topic: data.question_object.topic,
                    question1: data.question_object.round1.question,
                    question2: data.question_object.round2.question,
                    question3: data.question_object.round3.question,
                    question4: data.question_object.round4.question,
                    a1_wrong1: data.question_object.round1.wrong1,
                    a1_wrong2: data.question_object.round1.wrong2,
                    a1_wrong3: data.question_object.round1.wrong3,
                    a2_wrong1: data.question_object.round2.wrong1,
                    a2_wrong2: data.question_object.round2.wrong2,
                    a2_wrong3: data.question_object.round2.wrong3,
                    a3_wrong1: data.question_object.round3.wrong1,
                    a3_wrong2: data.question_object.round3.wrong2,
                    a3_wrong3: data.question_object.round3.wrong3,
                    a4_wrong1: data.question_object.round4.wrong1,
                    a4_wrong2: data.question_object.round4.wrong2,
                    a4_wrong3: data.question_object.round4.wrong3,
                    answer1: data.question_object.round1.answer,
                    answer2: data.question_object.round2.answer,
                    answer3: data.question_object.round3.answer,
                    answer4: data.question_object.round4.answer,
                })
                break;
            case 16:
                this.form.setValues({
                    script1: data.question_object.dialogue1.script,
                    wrong1_script1: data.question_object.dialogue1.wrong[0],
                    wrong2_script1: data.question_object.dialogue1.wrong[1],
                    wrong3_script1: data.question_object.dialogue1.wrong[2],
                    reply1: data.question_object.reply1,
                    script2: data.question_object.dialogue2.script,
                    wrong1_script2: data.question_object.dialogue2.wrong[0],
                    wrong2_script2: data.question_object.dialogue2.wrong[1],
                    wrong3_script2: data.question_object.dialogue2.wrong[2],
                    reply2: data.question_object.reply2,
                    script3: data.question_object.dialogue3.script,
                    wrong1_script3: data.question_object.dialogue3.wrong[0],
                    wrong2_script3: data.question_object.dialogue3.wrong[1],
                    wrong3_script3: data.question_object.dialogue3.wrong[2],
                    reply3: data.question_object.reply3,
                    script4: data.question_object.dialogue4.script,
                    wrong1_script4: data.question_object.dialogue4.wrong[0],
                    wrong2_script4: data.question_object.dialogue4.wrong[1],
                    wrong3_script4: data.question_object.dialogue4.wrong[2],
                    reply4: data.question_object.reply4,
                    script5: data.question_object.dialogue5.script,
                    wrong1_script5: data.question_object.dialogue5.wrong[0],
                    wrong2_script5: data.question_object.dialogue5.wrong[1],
                    wrong3_script5: data.question_object.dialogue5.wrong[2]
                })
                break;
            case 17:
                this.form.setValues({
                    passage: data.question_object.passage,
                    wrong1: data.question_object.wrong[0],
                    wrong2: data.question_object.wrong[1],
                    wrong3: data.question_object.wrong[2]
                })
                break;
            case 18:
                let form18 = {
                    topic: data.question_object.topic,
                    question: data.question_object.question,
                    response: data.question_object.response,
                }

                for (let i = 0; i < data.question_object.response_evaluation.length; i++) {
                    if(data.question_object.response_evaluation[i].text && data.question_object.response_evaluation[i].value){
                        switch (i){
                            case 0:
                                form13.response_1 = data.question_object.response_evaluation[i].text
                                form13.radioGroup_0 = data.question_object.response_evaluation[i].value
                                break
                            case 1:
                                form13.response_2 = data.question_object.response_evaluation[i].text
                                form13.radioGroup_1 = data.question_object.response_evaluation[i].value
                                break
                            case 2:
                                form13.response_3 = data.question_object.response_evaluation[i].text
                                form13.radioGroup_2 = data.question_object.response_evaluation[i].value
                                break
                            case 3:
                                form13.response_4 = data.question_object.response_evaluation[i].text
                                form13.radioGroup_3 = data.question_object.response_evaluation[i].value
                                break
                            case 4:
                                form13.response_5 = data.question_object.response_evaluation[i].text
                                form13.radioGroup_4 = data.question_object.response_evaluation[i].value
                                break
                            default:
                                continue
                        }
                    }
                }

                this.form.setValues(form18)
                break;
                // 11
            default:
                let param11 = {
                    script1: data.question_object.dialogue1.script,
                    wrong1_script1: data.question_object.dialogue1.wrong[0],
                    wrong2_script1: data.question_object.dialogue1.wrong[1],
                    wrong3_script1: data.question_object.dialogue1.wrong[2],
                    reply1: data.question_object.reply1,
                    script2: data.question_object.dialogue2.script,
                    wrong1_script2: data.question_object.dialogue2.wrong[0],
                    wrong2_script2: data.question_object.dialogue2.wrong[1],
                    wrong3_script2: data.question_object.dialogue2.wrong[2],
                    reply2: data.question_object.reply2,
                    script3: data.question_object.dialogue3.script,
                    wrong1_script3: data.question_object.dialogue3.wrong[0],
                    wrong2_script3: data.question_object.dialogue3.wrong[1],
                    wrong3_script3: data.question_object.dialogue3.wrong[2]
                }

                if(this.state.currentSubject.adaptive){
                    param11.reply3 = data.question_object.reply3
                    param11.script4 = data.question_object.dialogue4.script
                    param11.wrong1_script4 = data.question_object.dialogue4.wrong[0]
                    param11.wrong2_script4 = data.question_object.dialogue4.wrong[1]
                    param11.wrong3_script4 = data.question_object.dialogue4.wrong[2]
                    param11.reply4 = data.question_object.reply4
                    param11.script5 = data.question_object.dialogue5.script
                    param11.wrong1_script5 = data.question_object.dialogue5.wrong[0]
                    param11.wrong2_script5 = data.question_object.dialogue5.wrong[1]
                    param11.wrong3_script5 = data.question_object.dialogue5.wrong[2]
                }
                
                this.form.setValues(param11)
                break;
        }

        this.setState({previewLoading: false})
    }

    // when click history button load the history of question
    openHistory = (id, e) =>{
        this.setState({previewLoading: true})
        fetch(process.env.REACT_APP_API_URL+'api/v1/questions/'+id+'/history', {
            headers: this.props.myHeaders
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            this.setState({previewLoading: false})
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
            if(res.status){
                this.setState({history_modal: true, historyList: res.data})
            }else{
                toast.error(res.message)
            }
        }).catch((e)=>{
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    // open view changes modal
    openViewChanges = (id, e) =>{
        this.setState({previewLoading: true})
        fetch(process.env.REACT_APP_API_URL+'api/v1/questions/'+id+'/changes', {
            headers: this.props.myHeaders
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            this.setState({previewLoading: false})
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
            if(res.status){
                this.setState({view_changes_modal: true, viewChangesList: res.data})
            }else{
                toast.error(res.message)
            }
        }).catch((e)=>{
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    refreshPreview = () =>{
        this.setState({refreshPreviewLoading: true})
        this.openPreview(this.state.selectedQuestion)
    }

    // open preview image of minigame
    openPreview = (data, e) =>{
        this.setState({previewLoading: true, selectedQuestion: data})

        if(data.question_object.response_evaluation !== undefined){
            let eval_res = data.question_object.response_evaluation
            let tmp = []
            eval_res.forEach(x=>{
                if(x.text !== undefined && x.text !== ""){
                    tmp.push(x)
                }
            })
            
            if(tmp.length > 0){
                data.question_object.response_evaluation = tmp
            }
        }

        let param = {
            is_adaptive: this.state.currentSubject.adaptive ? true : false,
            question_object: data.question_object,
            question_id: data.question_id
        }

        if(this.state.currentSubject.adaptive){
            param.module_id = this.state.module_game_id
        }else{
            param.minigame_id = this.state.currentMinigameId
        }
        fetch(process.env.REACT_APP_API_URL+'api/v1/questions/generate-preview', {
            method: 'post',
            headers: this.props.myHeaders,
            body: JSON.stringify(param)
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            this.setState({previewLoading: false})
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }

            if(res.status){
                let preview = []
                if(this.state.currentSubject.adaptive){
                    res.data.forEach(v=>{
                        preview.push({ menuItem: <MenuItem key={v.mini_game_id}>
                        <Image src={v.icon} avatar />
                        {v.mini_game_name}
                        </MenuItem>, render: () => <Tab.Pane>
                            {v.preview[this.state.selectedPlatform] !== undefined ? 
                            <SlideShowComponent id={"slide-about-"+v.mini_game_name+"-"+this.state.selectedPlatform} images={v.preview[this.state.selectedPlatform]}/>
                            : <EmptyComponent/>}
                        </Tab.Pane> })
                    })
                }else{
                    let v = res.data
                    preview.push({ menuItem: <MenuItem key={v.mini_game_id}>
                    <Image src={v.icon} avatar />
                    {v.mini_game_name}
                    </MenuItem>, render: () => <Tab.Pane>
                        {v.preview[this.state.selectedPlatform] !== undefined ? 
                        <SlideShowComponent id={"slide-about-"+v.mini_game_id+"-"+this.state.selectedPlatform} images={v.preview[this.state.selectedPlatform]}/>
                        : <EmptyComponent/>}
                    </Tab.Pane> })
                }
                this.setState({previewImage: preview, previewModal: true, refreshPreviewLoading: false})
            }else{
                toast.error('Chapter cannot be preview.')
            }
        }).catch(()=>{
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    // display button when hover table row
    onHoverTableRow = (data, e) =>{
        let rowHover = false
        if(data.type === 'in'){
            rowHover = true
        }
        this.setState({rowHover: rowHover, rowHoverId: data.id})
    }

    // render table body for history, view changes and table display
    renderTableBody = (data) =>{
        let component = <div dangerouslySetInnerHTML={{__html:data}} />
        let module_id = this.state.module_game_id
        if(module_id >= 20 && module_id <= 25){
            module_id = 4
        }else if(module_id >= 26 && module_id <= 28){
            module_id = 11
        }

        if(module_id === 7 || module_id === 15){
            if(typeof data !== 'string'){
                component = <>
                    <Header as='h5' color='grey'>Question</Header>
                    <p>{data.question}</p>
            
                    <Divider hidden/>
            
                    <Header as='h5' color='grey'>Answer</Header>
                    <p dangerouslySetInnerHTML={{__html:data.answer}} />
            
                    <Divider hidden/>
            
                    <Header color='red' as='h5'>Wrong Answers</Header>
                    <p><Icon name='circle' size='small' color='grey'/> {data.wrong1}</p>
                    <p><Icon name='circle' size='small' color='grey'/> {data.wrong2}</p>
                    <p><Icon name='circle' size='small' color='grey'/> {data.wrong3}</p>
                </>
            }
        }else if(module_id === 10 || module_id === 17){
            if(Array.isArray(data)){
                component = <>
                    <p><Icon name='circle' size='small' color='grey'/> {data[0]}</p>
                    <p><Icon name='circle' size='small' color='grey'/> {data[1]}</p>
                    <p><Icon name='circle' size='small' color='grey'/> {data[2]}</p>
                </>
            }
        }else if(module_id === 11 || module_id === 16){
            if(typeof data === 'string'){

            }else{
                component = <>
                    <Header as='h5' color='green'>Player Script</Header>
                    <p>{data.script}</p>
                    <Divider hidden/>
                    <Header as='h5' color='red'>Wrong Scripts</Header>
                    <p><Icon name='circle' size='small' color='grey'/> {data.wrong[0]}</p>
                    <p><Icon name='circle' size='small' color='grey'/> {data.wrong[1]}</p>
                    <p><Icon name='circle' size='small' color='grey'/> {data.wrong[2]}</p>
                </>
            }
        }else if(module_id === 12){
            if(typeof data === 'string'){

            }else{
                component = data.required+(data.optional !== undefined && data.optional !== '' ? ','+data.optional : '')
            }
        }else if(module_id === 13 || module_id === 18){
            if(Array.isArray(data)){
                component = 
                <List verticalAlign='middle'>
                    {data.map((v,k)=>
                    v.text !== undefined && v.text !== "" ? 
                    <List.Item key={k}>
                        <List.Content floated='right'>
                            <Header as='h5' color={v.value === 'Yes' ? 'green' : 'red'}>
                            {v.value}
                            </Header>
                        </List.Content>
                        <List.Item>
                            {v.text}
                        </List.Item>
                    </List.Item>
                    : null
                    )}
                </List>
            }
        }

        return component
    }

    addSuggestedReason = (text, e) =>{
        let comment = this.state.comment
        comment += text+' '
        this.setState({comment: comment})
    }
    
    saveButtonClick = () =>{
        let is_error = false
        let inputs = []
        let fields = this.form.state.fields
        Object.keys(fields).forEach(x=>{
            if(!fields[x].valid && fields[x].required){
                is_error = true
                inputs.push(x)
            }
        })

        if(is_error){
            let msg = <p>
                <b>{inputs.slice(0, -1).join(', ')+
                (inputs.length > 1 ? ' and '+inputs.slice(-1) : inputs.join(', '))}</b> {inputs.length > 1 ? 'are' : 'is'} required.
                Please complete the form before submitting your entry!
            </p>
            this.setState({portalOpen: true, portalMessage: msg})
        }else{
            if(this.state.module_game_id === 7){
                let sameWithAnswer = []
                let duplicateWrong = []

                for (let i = 1; i <= 4; i++) {
                    let correct_answer = /<a [^>]+>(.*?)<\/a>/g.exec(fields['answer'+i].value)[1]

                    for (let x = 1; x < 4; x++) {
                        if(correct_answer === fields['a'+i+'_wrong'+x].value){
                            sameWithAnswer.push({
                                round: i,
                                wrong: x
                            })
                        }
                    }

                    if(fields['a'+i+'_wrong1'].value === fields['a'+i+'_wrong2'].value || fields['a'+i+'_wrong1'].value === fields['a'+i+'_wrong3'].value || fields['a'+i+'_wrong2'].value === fields['a'+i+'_wrong3'].value){
                        duplicateWrong.push(i)
                    }
                }

                let msg = <>
                <List bulleted>
                    {sameWithAnswer.map((v, k)=>
                    <List.Item key={k}>Correct answer is duplicated with wrong answer {v.wrong} on round {v.round}</List.Item>
                    )}
                    {duplicateWrong.map((v, k)=>
                    <List.Item key={k}>There is a duplication value input on round {v} wrong answer section</List.Item>
                    )}
                </List>
                </>

                if(sameWithAnswer.length === 0 && duplicateWrong.length === 0){
                    is_error = false
                }else{
                    is_error = true
                    this.setState({portalOpen: true, portalMessage: msg})
                }
            }
        }

        if(!is_error){
            this.form.submit()
        }
    }

    deleteEntry = () =>{
        this.setState({deleteLoading: true})
        fetch(process.env.REACT_APP_API_URL+'api/v1/questions/'+this.state.selectedEntry.question_id+'/delete',{
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            }),
            method: 'delete'
        })
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href='/'
            }
            if(res.status){
                toast.success('Entry deleted.')
                this.setState({deleteLoading: false, deleteConfirm: false})
                this.loadQuestion()
            }else{
                toast.error(res.message)
            }
        })
        .catch((e)=>{
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    onCheckMove = (e, data) =>{
        let questions = this.state.questionList
        let idx = questions.findIndex(x=>x.question_id === data.qid)
        if(idx !== -1){
            questions[idx].selected = data.checked
        }

        let source = this.state.sourceData
        source.question_ids = questions.filter(x=>x.selected).map(val=>{
            return val.question_id
        })

        if(this.state.isCheckAll){
            this.setState({isCheckAll: false})
        }

        this.setState({questionList: questions, sourceData: source})
    }

    hideDestiny = () =>{
        this.props.setBannerLoading(false)
        this.loadQuestion()
        setTimeout(()=>{
            this.props.setBannerLoading(true)
        }, 100)
        this.setState({destinyModal: false, sourceData: {question_ids: []}})
    }

    moveClick = (e, data) =>{
        let source = this.state.sourceData
        if(data.is_bulk === "true"){
            if(source.question_ids.length === 0){
                toast.warn('Please select the question first.')
                return false
            }

            this.setState({destinyModal: true})
        }else{
            let questions = this.state.questionList
            for (let i = 0; i < questions.length; i++) {
                questions[i].selected = false                
            }
            source.question_ids = [data.qid]
            this.setState({destinyModal: true, sourceData: source, questionList: questions, isCheckAll: false})
        }
    }

    selectAllQuestion = (is_checked) =>{
        let questions = this.state.questionList
        let source = this.state.sourceData

        if(is_checked){
            for (let i = 0; i < questions.length; i++) {
                questions[i].selected = true                
            }

            source.question_ids = this.state.allQuestionIds
        }else{
            source.question_ids = []
            for (let i = 0; i < questions.length; i++) {
                questions[i].selected = false                
            }
        }

        this.setState({questionList: questions, sourceData: source, isCheckAll: is_checked})
    }

    render() {
        const suggestedReason = [
            'Not relevant.',
            'Typo(s) detected.',
            'Bad word(s) detected.',
            'Duplicate.'
        ]
        
        let col = null
        let colModal = null
        let colForm = null

        if(this.state !== null && this.state.module_game_id !== null){
            let module_id = this.state.module_game_id
            if(module_id >= 20 && module_id <= 25){
                module_id = 4
            }else if(module_id >= 26 && module_id <= 28){
                module_id = 11
            }
            switch (module_id) {
                case 1:
                    col = <Module1Column is_adaptive={this.state.currentSubject.adaptive} is_checked={this.state.isCheckAll} checkAll={this.selectAllQuestion}/>
                    colModal = <Module1ColumnHistory/>
                    colForm = <Module1ColumnQuestion/>
                    break;
                // module 1a
                case 99:
                    col = <Module1Column is_adaptive={this.state.currentSubject.adaptive} is_checked={this.state.isCheckAll} checkAll={this.selectAllQuestion}/>
                    colModal = <Module1ColumnHistory/>
                    colForm = <Module1ColumnQuestion/>
                    break;
                case 2:
                    col = <Module2Column is_adaptive={this.state.currentSubject.adaptive} is_checked={this.state.isCheckAll} checkAll={this.selectAllQuestion}/>
                    colModal = <Module2ColumnHistory/>
                    colForm = <Module2ColumnQuestion/>
                    break;
                case 4:
                    col = <Module4Column is_adaptive={this.state.currentSubject.adaptive} is_checked={this.state.isCheckAll} checkAll={this.selectAllQuestion}/>
                    colModal = <Module4ColumnHistory/>
                    colForm = <Module4ColumnQuestion/>
                    break;
                case 7:
                    col = <Module7Column is_checked={this.state.isCheckAll} is_adaptive={this.state.currentSubject.adaptive} checkAll={this.selectAllQuestion}/>
                    colModal = <Module7ColumnHistory is_adaptive={this.state.currentSubject.adaptive}/>
                    colForm = <Module7ColumnQuestion is_adaptive={this.state.currentSubject.adaptive}/>
                    break;
                case 10:
                    col = <Module10Column is_adaptive={this.state.currentSubject.adaptive} is_checked={this.state.isCheckAll} checkAll={this.selectAllQuestion}/>
                    colModal = <Module10ColumnHistory/>
                    colForm = <Module10ColumnQuestion/>
                    break;
                case 12:
                    col = <Module12Column is_adaptive={this.state.currentSubject.adaptive} is_checked={this.state.isCheckAll} checkAll={this.selectAllQuestion}/>
                    colModal = <Module12ColumnHistory/>
                    colForm = <Module12ColumnQuestion/>
                    break;
                case 13:
                    col = <Module13Column is_checked={this.state.isCheckAll} {...this.props} res_ev={this.state.response_ev} 
                    is_adaptive={this.state.currentSubject.adaptive} checkAll={this.selectAllQuestion}/>
                    colModal = <Module13ColumnHistory/>
                    colForm = <Module13ColumnQuestion/>
                    break;
                case 14:
                    col = <Module14Column is_adaptive={this.state.currentSubject.adaptive} is_checked={this.state.isCheckAll} checkAll={this.selectAllQuestion}/>
                    colModal = <Module14ColumnHistory/>
                    colForm = <Module14ColumnQuestion/>
                    break;
                case 15:
                    col = <Module7Column is_checked={this.state.isCheckAll} is_adaptive={this.state.currentSubject.adaptive} checkAll={this.selectAllQuestion}/>
                    colModal = <Module7ColumnHistory is_adaptive={this.state.currentSubject.adaptive}/>
                    colForm = <Module7ColumnQuestion is_adaptive={this.state.currentSubject.adaptive}/>
                    break;
                case 16:
                    col = <Module11Column is_checked={this.state.isCheckAll} is_adaptive={this.state.currentSubject.adaptive} checkAll={this.selectAllQuestion}/>
                    colModal = <Module11ColumnHistory is_adaptive={this.state.currentSubject.adaptive}/>
                    colForm = <Module11ColumnQuestion is_adaptive={this.state.currentSubject.adaptive}/>
                    break;
                case 17:
                    col = <Module10Column is_adaptive={this.state.currentSubject.adaptive} is_checked={this.state.isCheckAll} checkAll={this.selectAllQuestion}/>
                    colModal = <Module10ColumnHistory/>
                    colForm = <Module10ColumnQuestion/>
                    break;
                case 18:
                    col = <Module13Column is_checked={this.state.isCheckAll} {...this.props} res_ev={this.state.response_ev} 
                    is_adaptive={true} checkAll={this.selectAllQuestion}/>
                    colModal = <Module13ColumnHistory/>
                    colForm = <Module13ColumnQuestion/>
                    break;
                // 11
                default:
                    col = <Module11Column is_checked={this.state.isCheckAll} is_adaptive={this.state.currentSubject.adaptive} checkAll={this.selectAllQuestion}/>
                    colModal = <Module11ColumnHistory is_adaptive={this.state.currentSubject.adaptive}/>
                    colForm = <Module11ColumnQuestion is_adaptive={this.state.currentSubject.adaptive}/>
                    break;
            }
        }


        let entries = [
            {key: 10, text: '10', value: 10},
            {key: 25, text: '25', value: 25},
            {key: 50, text: '50', value: 50},
            {key: 100, text: '100', value: 100},
        ]

        if(this.state === null){
            return <Loader active></Loader>
        }

        return (
            <>
            {/* filter */}
            <div className="ui icon rounded input">
                <input onChange={this.onChangeSearch} type="text" placeholder="Search a word or sentences" id='qst_search'/>
                <i className="search icon"></i>
            </div>
            {this.state.currentSubject.role === 3 ? 
            <Checkbox onChange={this.showReviewContent} style={{marginLeft:10}} toggle label='Show on review content only'/> : null}
            
            {this.state.currentSubject.role === 3 && this.state.currentSubject.adaptive ? 
            <Button floated="right" primary onClick={this.moveClick} is_bulk="true">
                <Icon name='move'/> Move Selected Question
            </Button>
            : null}

            {this.state.searchID !== null ?
            <Label style={{marginLeft: 20}}>
                Search Result for “<b>{this.state.searchID}</b>”
                <Icon name='delete' onClick={()=>{
                    window.location.href='/subject/'+this.state.currentSubject.code+'/'+(this.state.questionList[0].chapter_mini_game_id !== null ? this.state.questionList[0].chapter_mini_game_id : this.state.questionList[0].topic_id)+'/question'
                }}/>
            </Label>
            : null}
            <div style={{float: 'right'}}>
                {this.state.currentSubject.role === 3 && this.state.isApprove ? <div className='btnWrapper'>
                    <Button positive size='tiny' onClick={this.actionQuestionAll.bind(null, 'approve')}>
                        <Icon name='check'/> Approve All
                    </Button>
                    <Button negative size='tiny' onClick={this.actionQuestionAll.bind(null, 'decline')}>
                        <Icon name='remove'/> Decline All
                    </Button>
                </div> : ''}
            </div>

            <div className='questionTable' id='questionTable'>
                {this.state.loading ? <Loader active/> : 
                    <Form messages={validationMessage} ref={el => this.form = el} action={this.saveQuestion} className='ui form'>
                    <table className='ui fixed table' id='entry_table'>
                        <thead>
                            {col !== null ? col : null}
                        </thead>
                        <tbody>
                            <Dimmer inverted active={this.state.previewLoading}>
                                <Loader active/>
                            </Dimmer>
                            {this.state.questionList.map((v,k)=>{
                                const keys = Object.keys(v.question_object)
                                return(
                                    <tr valign="top" key={k} onMouseEnter={this.onHoverTableRow.bind(null, {
                                        type: 'in', id: v.question_number
                                    })} 
                                    onMouseLeave={this.onHoverTableRow.bind(null, {
                                        type: 'out', id: v.question_number
                                    })}>
                                        {v.is_edit ? this.renderModuleFormQuestion(this.state.module_game_id, v) : 
                                            <>
                                            <td>
                                                {this.state.currentSubject.adaptive ? 
                                                <Checkbox checked={v.selected} qid={v.question_id} onChange={this.onCheckMove}/>
                                                : null}
                                                {v.question_number}
                                                <Popup content={
                                                    this.state.currentSubject.role === 3 ? 'Question needs to be approved or declined.'
                                                    : 'Question is being reviewed.'
                                                } position='right center' trigger={
                                                    v.status ? 
                                                        this.state.currentSubject.role === 3 ? 
                                                        <div className="ui yellow right corner tiny label">
                                                            <i className="exclamation triangle tiny icon"></i>
                                                        </div>
                                                        : <div className="ui blue info right corner tiny label">
                                                            <i className="exclamation circle tiny icon"></i>
                                                        </div>
                                                    : null
                                                }/>
                                                {this.state.rowHover && this.state.rowHoverId === v.question_number ?
                                                <div className='hiddenAction'>
                                                    <Popup content='Preview Content' trigger={
                                                    <Button size='mini' icon='eye' primary
                                                    type='button' onClick={this.openPreview.bind(null, v)}/>
                                                    }/>
                                                    <br/>
                                                    <Popup content='View History' trigger={
                                                    <Button size='mini' icon='history' primary
                                                    type='button' onClick={this.openHistory.bind(null, v.question_id)}/>
                                                    }/>
                                                </div> 
                                                : null }
                                            </td>
                                            {keys.map((i,j)=><td key={j}>
                                                {i === 'image' ? <img alt='img' style={{width: 100, height: 100}} src={v.question_object[i]}/>
                                                    : this.renderTableBody(v.question_object[i])
                                                }
                                            </td>)}
                                            <td>
                                                <div className='actionBtn'>
                                                    {v.status && this.state.currentSubject.role === 3 ?
                                                        <div>
                                                            <Button type='button' positive size='mini' 
                                                            onClick={this.singleActionTable.bind(null, 1, v.question_id)}>
                                                                <Icon name='check'/> Approve
                                                            </Button>
                                                            <Button type='button' negative size='mini'
                                                            onClick={this.singleActionTable.bind(null, 2, v.question_id)}>
                                                                <Icon name='remove'/> Decline
                                                            </Button>
                                                            {this.state.currentSubject.role === 3 ? 
                                                                !v.is_new && v.status ? 
                                                                <Button type='button' onClick={this.openViewChanges.bind(null, v.question_id)}
                                                                    secondary size='mini'>
                                                                    <Icon name='file alternate'/> View Changes
                                                                </Button>
                                                                : null
                                                                : null
                                                            }
                                                        </div>
                                                    : null}
                                                    {this.state.currentSubject.role === 3 && v.status ? <Divider/> : null}
                                                    <div>
                                                        {this.state.currentSubject.role === 3 && this.state.currentSubject.adaptive ? 
                                                        <Button type='button' qid={v.question_id} onClick={this.moveClick}
                                                        secondary size='mini' is_bulk="false">
                                                            <Icon name='move'/> Move
                                                        </Button>
                                                        : null}
                                                        
                                                        {this.state.currentSubject.role === 3 ? 
                                                            <Button type='button' onClick={this.onEditClick.bind(null, v)} 
                                                            secondary size='mini'>
                                                                <Icon name='pencil'/> Edit
                                                            </Button>
                                                            : 
                                                            <Button disabled={v.status || this.state.currentSubject.role === null || this.state.currentSubject.is_demo} type='button' onClick={this.onEditClick.bind(null, v)}
                                                            secondary size='mini'>
                                                                <Icon name='pencil'/> Edit
                                                            </Button>
                                                        }

                                                        {this.state.currentSubject.role === 3 ? 
                                                        <Button type='button' onClick={()=>this.setState({deleteConfirm: true, selectedEntry: v})}
                                                        secondary size='mini'>
                                                            <Icon name='trash'/> Delete
                                                        </Button>
                                                        : null}
                                                    </div>
                                                </div>
                                            </td>
                                            </>
                                        }
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    {this.state.questionList.length === 0 ? <EmptyComponent/> : null}
                    <Modal open={this.state.is_new_question} size='large'>
                        <Modal.Header>
                            Add New Question
                            <Modal closeIcon trigger={
                                <Button style={{marginLeft: 5, verticalAlign: 'bottom'}} color='teal' size='mini'>
                                    <Icon name='keyboard'/> Keyboard
                                </Button>
                            }>
                                <Modal.Content>
                                    <iframe title='keyboard' src="https://mathkeyboard.dawnofcivilization.net/" width="100%" height="500"></iframe>
                                </Modal.Content>
                            </Modal>
                        </Modal.Header>
                        <Modal.Content style={{overflow: 'auto'}}>
                            <table className='ui fixed table form'>
                                <thead>
                                    {colForm !== null ? colForm : null}
                                </thead>
                                <tbody>
                                    <tr valign="top">
                                        {this.state.formNew}
                                    </tr>
                                </tbody>
                            </table>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button type='button' disabled={this.state.saveLoading} 
                            onClick={this.onCancelClick} className='tertiary'>Cancel</Button>
                            <Button primary onClick={this.saveButtonClick}
                            disabled={this.state.saveLoading} loading={this.state.saveLoading}>Save</Button>
                        </Modal.Actions>
                    </Modal>
                    </Form>
                }
            </div>

            {/* pagination */}
            <Divider hidden/>
            <div>
                Show <Dropdown onChange={this.changeEntries} defaultValue={10} compact selection options={entries} /> entries
                <div className='pagination'>
                    <span style={{marginRight: 10}}>
                        Showing {this.state.questionList.length} of {this.state.totalRecord} entries
                    </span>
                    <Pagination activePage={this.state.filter.page} size='small' onPageChange={this.handleChangePagination} 
                    totalPages={this.state.totalPage} />
                </div>
            </div>

            {/* modal */}
            {/* history */}
            <Modal centered={false} open={this.state.history_modal} closeIcon 
            onClose={()=>this.setState({history_modal: false})} size='large'>
                <Modal.Header>History</Modal.Header>
                <Modal.Content>
                    <div style={{maxHeight: 500, overflow: 'auto'}}>
                        <table className='ui fixed table'>
                            <thead>
                                {colModal !== null ? colModal : null}
                            </thead>
                            <tbody>
                                {this.state.historyList.map((v,k)=>{
                                    const keys = Object.keys(v.question_object)
                                    return(
                                        <tr valign="top" key={k}>
                                            {keys.map((i,j)=><td key={j}>
                                                {i === 'image' ? <img alt='img' style={{width: 100, height: 100}} src={v.question_object[i]}/>
                                                    : this.renderTableBody(v.question_object[i])
                                                }
                                            </td>)}
                                            <td>{v.editor}</td>
                                            <td>{v.date}</td>
                                            <td>{v.approved === null ? 'On Review' : v.approved ? 'Approved' : 'Declined'}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </Modal.Content>
            </Modal>

            {/* view changes */}
            <Modal centered={false} open={this.state.view_changes_modal} closeIcon size='large'
            onClose={()=>this.setState({view_changes_modal: false})}>
                <Modal.Header>View Changes</Modal.Header>
                <Modal.Content>
                    {this.state.viewChangesList !== null ?
                        <div style={{maxHeight: 500, overflow: 'auto'}}>
                            <Header as='h3'>Old</Header>
                            <table className='ui fixed table'>
                                <thead>
                                    {colModal !== null ? colModal : null}
                                </thead>
                                <tbody>
                                    {[0].map((v,k)=>{
                                        v = this.state.viewChangesList.old
                                        const keys = Object.keys(v.question_object)
                                        return(
                                            <tr valign="top" key={k}>
                                                {keys.map((i,j)=><td key={j}>
                                                    {i === 'image' ? <img alt='img' style={{width: 100, height: 100}} 
                                                    src={v.question_object[i]}/>
                                                        : this.renderTableBody(v.question_object[i])
                                                    }
                                                </td>)}
                                                <td>{v.editor}</td>
                                                <td>{v.date}</td>
                                                <td>{v.approved === null ? 'On Review' : v.approved ? 'Approved' : 'Declined'}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            <Divider hidden/>
                            <Header as='h3' color='green'>New</Header>
                            <table className='ui fixed green table'>
                                <thead>
                                    {colModal !== null ? colModal : null}
                                </thead>
                                <tbody>
                                    {[0].map((v,k)=>{
                                        v = this.state.viewChangesList.new
                                        const keys = Object.keys(v.question_object)
                                        return(
                                            <tr valign="top"r key={k}>
                                                {keys.map((i,j)=><td key={j}>
                                                    {i === 'image' ? <img alt='img' style={{width: 100, height: 100}} src={v.question_object[i]}/>
                                                        : this.renderTableBody(v.question_object[i])
                                                    }
                                                </td>)}
                                                <td>{v.editor}</td>
                                                <td>{v.date}</td>
                                                <td>{v.approved === null ? 'On Review' : v.approved ? 'Approved' : 'Declined'}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div> 
                        : null
                    }
                </Modal.Content>
                <Modal.Actions>
                    <Button type='button' positive size='mini' 
                    onClick={()=>{
                        this.singleActionTable(1, this.state.viewChangesList.new.question_id)
                        this.setState({view_changes_modal: false})
                    }}>
                        <Icon name='check'/> Approve
                    </Button>
                    <Button type='button' negative size='mini'
                    onClick={()=>{
                        this.singleActionTable(2, this.state.viewChangesList.new.question_id)
                        this.setState({view_changes_modal: false})
                    }}>
                        <Icon name='remove'/> Decline
                    </Button>
                </Modal.Actions>
            </Modal>

            {/* content preview */}
            <Modal open={this.state.previewModal} onClose={()=>this.setState({previewModal: false})} closeIcon>
                <Modal.Header>
                    Content on Game Preview
                    <p className='refreshModal'>Game display not loaded well? <Button size='mini' color='green' onClick={this.refreshPreview}>refresh</Button></p>
                </Modal.Header>
                <Modal.Content>
                    <Dimmer inverted active={this.state.refreshPreviewLoading}>
                        <Loader active>Loading...</Loader>
                    </Dimmer>
                    <Grid>
                        <Grid.Column width={4}>
                        </Grid.Column>
                        <Grid.Column width={12} style={{paddingBottom: 0}}>
                        <Header as="h4" style={{float: 'left'}}>
                            <Icon name='images'/>
                            Screenshot
                        </Header>
                        <span style={{float: 'right'}}>
                            Platform : {' '}
                            <Dropdown
                                direction='left'
                                inline
                                options={this.state.platformList}
                                defaultValue={this.state.selectedPlatform}
                                onChange={(e, data)=>this.setState({selectedPlatform: data.value})}
                            />
                        </span>
                        
                        </Grid.Column>
                    </Grid>

                    {this.state.previewImage.length > 0 ? 
                    <Tab className="bannerTab" menu={{ vertical: true }} panes={this.state.previewImage}/>
                    : null}
                </Modal.Content>
            </Modal>

            {/* decline modal */}

            <Modal size='small' open={this.state.declineModal}>
                <Modal.Header>Decline Entry</Modal.Header>
                <Modal.Content>
                    <p>Why do you decline this entry?</p>
                    <div className='ui form'>
                    <TextArea placeholder='Type your comment' value={this.state.comment} 
                    onChange={(e, data)=>this.setState({comment: data.value})}/>
                    </div>
                    <Divider hidden/>
                    <p>User suggested reason:</p>
                    <Label.Group color='blue'>
                        {suggestedReason.map((v,k)=>
                            <Label as='a' key={k} onClick={
                                this.addSuggestedReason.bind(null, v)
                            }>
                                {v}
                            </Label>
                        )}
                    </Label.Group>
                </Modal.Content>
                <Modal.Actions>
                    <Button className='tertiary' onClick={()=>{
                        this.setState({comment: ''})
                        this.setState({declineModal: false})
                    }}>Cancel</Button>
                    <Button negative onClick={()=>{
                        if(this.state.declinedId === null){
                            this.processApproveDecline('decline', 'all')
                        }else{
                            this.processApproveDecline('decline', this.state.declinedId)
                        }
                        this.setState({declineModal: false})
                    }}>Decline</Button>
                </Modal.Actions>
            </Modal>
            <Modal size='mini' open={this.state.portalOpen} className='formErrorModal' closeIcon 
            onClose={()=>this.setState({portalOpen: false})}>
                <Modal.Content>
                    <Header>Form is not complete!</Header>
                    {this.state.portalMessage}
                    <div style={{textAlign:'center'}}>
                    <Button primary
                        content='Ok'
                        onClick={()=>this.setState({portalOpen: false})}
                    />
                    </div>
                </Modal.Content>
            </Modal>

            <Modal size='small' open={this.state.deleteConfirm}>
                <Modal.Content style={{padding: 20, paddingBottom: 50}}>
                    <div className='publishModal'>
                        <div>
                            <Icon name='exclamation triangle' circular/>
                        </div>
                        <div className='content'>
                            <h3>Are you sure you want to delete this question?</h3>
                            <p>
                                You cannot undo this. This question will immediately be deleted from Content+ and deleted permanently from the game when the subject is published.
                            </p>
                        </div>
                    </div>
                    <Divider hidden/>
                    <Button onClick={()=>this.setState({deleteConfirm: false})} 
                    floated='right' primary disabled={this.state.deleteLoading}>Cancel</Button>
                    <Button onClick={this.deleteEntry} disabled={this.state.deleteLoading}
                    loading={this.state.deleteLoading} floated='right' secondary>Yes</Button>
                </Modal.Content>
            </Modal>
            <DestinationFolderComponent destinyModal={this.state.destinyModal} hideDestiny={this.hideDestiny} closeDestiny={()=>this.setState({destinyModal: false})}
            auth={this.props.auth.user.token} id={this.state.subject_module_id} subject={this.props.match.params.code}
            sourceData={this.state.sourceData} banner_data={this.props.banner_data}/>
            </>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TableSection)