import React from 'react'
import { Grid, Header, Button, Icon, Divider, Table, Pagination, Dropdown,
Modal, TextArea, Label, List,
Loader,
Checkbox} from 'semantic-ui-react'
import './style.scss'
import { Module1ColumnHistory, Module2ColumnHistory, Module4ColumnHistory, 
    Module7ColumnHistory, Module10ColumnHistory, Module11ColumnHistory, Module12ColumnHistory,
    Module13ColumnHistory, Module14ColumnHistory } from '../subject/questions/table_columns'
import {connect} from 'react-redux'
import storage from 'redux-persist/lib/storage';
import { toast } from 'react-toastify'
import EmptyComponent from '../../components/empty'

// to detect every store of redux/state changed
function mapStateToProps(state){
    return{
        auth: state.auth
    }
}

// to detect every store of redux/state changed
function mapDispatchToProps(dispatch) {
    return { 
        setTaskManager: (n) => dispatch({ type: 'SET_TASK_MANAGER', payload: n })
    }
}

class TaskManagerPage extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            taskList: [],
            view_changes_modal: false,
            columnPreview: null,
            loading: true,
            selectedTask: null,
            activePage: 1,
            filter: {
                page: 1,
                n: 10,
                order_by: 6,
                asc: 0
            },
            totalPage: 0,
            totalRecord: 0,
            nextValidate: false,
            declineModal: false,
            comment: '',
            declinedId: null
        }
    }

    componentDidMount(){
        this.loadData()
    }

    loadData = () =>{
        fetch(process.env.REACT_APP_API_URL+'api/v1/manager/tasks', {
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            }),
            method: 'post',
            body: JSON.stringify(this.state.filter)
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
            this.setState({taskList: res.data, loading: false, 
                totalPage: res.total_page, 
                totalRecord: res.total_record})
        }).catch((e)=>{
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    handleSort = (clickedColumn) => () => {
        let filter = this.state.filter
        filter.order_by = clickedColumn
        filter.asc = filter.asc === 1 ? 0 : 1
        this.setState({
            filter: filter
        })

        setTimeout(()=>{
            this.loadData()
        }, 100)
    }

    generateColumn = (data, e) =>{
        fetch(process.env.REACT_APP_API_URL+'api/v1/manager/tasks/'+data.question_id, {
        headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            }),
            method: 'post',
            body: JSON.stringify(this.state.filter)
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
            let colModal = null
            let module_id = res.data.task.module_game_id
            if(module_id >= 20 && module_id <= 25){
                module_id = 4
            }else if(module_id >= 26 && module_id <= 28){
                module_id = 11
            }
            switch (module_id) {
                case 1:
                    colModal = <Module1ColumnHistory/>
                    break;
                // module 1a
                case 99:
                    colModal = <Module1ColumnHistory/>
                    break;
                case 2:
                    colModal = <Module2ColumnHistory/>
                    break;
                case 4:
                    colModal = <Module4ColumnHistory/>
                    break;
                case 7:
                    colModal = <Module7ColumnHistory/>
                    break;
                case 10:
                    colModal = <Module10ColumnHistory/>
                    break;
                case 12:
                    colModal = <Module12ColumnHistory/>
                    break;
                case 13:
                    colModal = <Module13ColumnHistory/>
                    break;
                case 14:
                    colModal = <Module14ColumnHistory/>
                    break;
                case 15:
                    colModal = <Module7ColumnHistory/>
                    break;
                case 16:
                    colModal = <Module11ColumnHistory/>
                    break;
                case 17:
                    colModal = <Module10ColumnHistory/>
                    break;
                case 18:
                    colModal = <Module13ColumnHistory/>
                    break;
                // 11
                default:
                    colModal = <Module11ColumnHistory/>
                    break;
            }

            this.setState({selectedTask: res.data, columnPreview: colModal, view_changes_modal: true})
        }).catch((e)=>{
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    // render table body for history, view changes and table display
    renderTableBody = (data) =>{
        let module_game_id = this.state.selectedTask.task.module_game_id
        let component = <div dangerouslySetInnerHTML={{__html:data}} />
        if(module_game_id === 7 || module_game_id === 15){
            if(typeof data !== 'string'){
                component = <>
                    <Header as='h5' color='grey'>Question</Header>
                    <p>{data.question}</p>
            
                    <Divider hidden/>
            
                    <Header as='h5' color='grey'>Answer</Header>
                    <p dangerouslySetInnerHTML={{__html:data.answer}} />
            
                    <Divider hidden/>
            
                    <Header color='red' as='h5'>Wrong Answers</Header>
                    <p><Icon name='circle' size='small' color='grey'/> {data.wrong1}</p>
                    <p><Icon name='circle' size='small' color='grey'/> {data.wrong2}</p>
                    <p><Icon name='circle' size='small' color='grey'/> {data.wrong3}</p>
                </>
            }
        }else if(module_game_id === 10 || module_game_id === 17){
            if(Array.isArray(data)){
                component = <>
                    <p><Icon name='circle' size='small' color='grey'/> {data[0]}</p>
                    <p><Icon name='circle' size='small' color='grey'/> {data[1]}</p>
                    <p><Icon name='circle' size='small' color='grey'/> {data[2]}</p>
                </>
            }
        }else if(module_game_id === 11 || module_game_id === 16){
            if(typeof data === 'string'){

            }else{
                component = <>
                    <Header as='h5' color='green'>Player Script</Header>
                    <p>{data.script}</p>
                    <Divider hidden/>
                    <Header as='h5' color='red'>Wrong Scripts</Header>
                    <p><Icon name='circle' size='small' color='grey'/> {data.wrong[0]}</p>
                    <p><Icon name='circle' size='small' color='grey'/> {data.wrong[1]}</p>
                    <p><Icon name='circle' size='small' color='grey'/> {data.wrong[2]}</p>
                </>
            }
        }else if(module_game_id === 12){
            if(typeof data === 'string'){

            }else{
                component = data.required+(data.optional !== '' ? ','+data.optional : '')
            }
        }else if(module_game_id === 13 || module_game_id === 18){
            if(Array.isArray(data)){
                component = 
                <List verticalAlign='middle'>
                    {data.map((v,k)=>
                    <List.Item key={k}>
                        <List.Content floated='right'>
                            <Header as='h5' color={v.value === 'Yes' ? 'green' : 'red'}>
                            {v.value}
                            </Header>
                        </List.Content>
                        <List.Item>
                            {v.text}
                        </List.Item>
                    </List.Item>
                    )}
                </List>
            }
        }

        return component
    }

    handleChangePagination = (e, data) =>{
        let filter = this.state.filter
        filter.page = data.activePage
        this.setState({filter: filter})
        window.setTimeout(()=>{
            this.loadData()
        }, 100)
    }

    handleChangeEntries = (e, data) =>{
        let filter = this.state.filter
        filter.n = data.value
        this.setState({filter: filter})
        window.setTimeout(()=>{
            this.loadData()
        }, 100)
    }

    singleActionTable = (type, data, e) =>{
        switch (type) {
            // approve
            case 1:
                this.processApproveDecline('approve', data)
                break;
            // decline
            default:
                this.setState({declineModal: true, declinedId: data.new.question_id})
                break;
        }
    }

    // process approve and decline on database
    processApproveDecline = (type, data) =>{
        let mg_id = this.state.taskList
        if(data.new !== undefined){
            mg_id = mg_id.filter(x=>x.question_id === data.new.question_id)
            mg_id = mg_id[0]
        }else{
            mg_id = this.state.selectedTask.task
        }

        if(data.task.is_adaptive){
            mg_id.chapter_mini_game_id = data.task.topic_id
        }

        fetch(process.env.REACT_APP_API_URL+'api/v1/questions/'+mg_id.chapter_mini_game_id+'/'+type+'/'+(data.new !== undefined ? data.new.question_id : data), {
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            }),
            method: 'post',
            body: JSON.stringify({
                is_adaptive: data.task.is_adaptive,
                comment: this.state.comment
            })
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
            
            if(res.status){
                toast.success('success '+type)
                this.loadData()

                if(this.state.nextValidate && data.next_question_id !== null){
                    this.generateColumn({question_id: data.next_question_id}, null)
                }

                this.props.setTaskManager(true)
            }else{
                toast.success(res.message)
            }
        }).catch((e)=>{
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    // approve and decline process
    actionQuestionAll = (type, e) =>{
        if(type==='approve'){
            if(window.confirm('Are you sure want to approve them?')){
                this.approveDeclineAll('approve')
            }
        }else{
            this.setState({declineModal: true, declinedId: null})
        }
    }

    approveDeclineAll = (type) =>{
        fetch(process.env.REACT_APP_API_URL+'api/v1/manager/tasks/'+type+'/all', {
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            }),
            method: 'post',
            body: JSON.stringify({
                comment: this.state.comment
            })
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
            
            if(res.status){
                toast.success('success '+type)
                this.loadData()
                this.props.setTaskManager(true)
            }else{
                toast.success(res.message)
            }
        }).catch((e)=>{
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    addSuggestedReason = (text, e) =>{
        let comment = this.state.comment
        comment += text+' '
        this.setState({comment: comment})
    }

    renderNumber = (k)=>{
        let n = this.state.filter.n * this.state.filter.page
        n = n - this.state.filter.n + k + 1
        return n
    }

    render() {
        const suggestedReason = [
            'Not relevant.',
            'Typo(s) detected.',
            'Bad word(s) detected.',
            'Duplicate.'
        ]
        return (
            <div>
                {this.state.loading ? <Loader active/> :
                    this.state.taskList.length > 0 ? 
                    <>
                        <Grid columns='equal' id="task_exist">
                            <Grid.Column>
                                <Header as='h1'>Manager Task</Header>
                            </Grid.Column>
                            <Grid.Column>
                                <Button size='small' onClick={this.actionQuestionAll.bind(null, 'decline')} negative floated='right'>
                                    <Icon name='remove'/> Decline All
                                </Button>

                                <Button size='small' onClick={this.actionQuestionAll.bind(null, 'approve')} positive floated='right'>
                                    <Icon name='check'/> Approve All
                                </Button>
                            </Grid.Column>
                        </Grid>
                        <Table fixed sortable className='task' striped>
                            <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell
                                sorted={this.state.filter.order_by === 0 ? 
                                    this.state.filter.asc === 1 ? 'ascending' : 'descending'    
                                : null}
                                onClick={this.handleSort(0)}
                                >
                                No
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                sorted={this.state.filter.order_by === 1 ? 
                                    this.state.filter.asc === 1 ? 'ascending' : 'descending'    
                                : null}
                                onClick={this.handleSort(1)}
                                >
                                Entry ID
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                sorted={this.state.filter.order_by === 2 ? 
                                    this.state.filter.asc === 1 ? 'ascending' : 'descending'    
                                : null}
                                onClick={this.handleSort(2)}
                                >
                                Subject
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                sorted={this.state.filter.order_by === 3 ? 
                                    this.state.filter.asc === 1 ? 'ascending' : 'descending'    
                                : null}
                                onClick={this.handleSort(3)}
                                >
                                Chapter
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                sorted={this.state.filter.order_by === 4 ? 
                                    this.state.filter.asc === 1 ? 'ascending' : 'descending'    
                                : null}
                                onClick={this.handleSort(4)}
                                >
                                Minigame
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                sorted={this.state.filter.order_by === 5 ? 
                                    this.state.filter.asc === 1 ? 'ascending' : 'descending'    
                                : null}
                                onClick={this.handleSort(5)}
                                >
                                Topic Name
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                sorted={this.state.filter.order_by === 6 ? 
                                    this.state.filter.asc === 1 ? 'ascending' : 'descending'    
                                : null}
                                onClick={this.handleSort(6)}
                                >
                                Created By
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                sorted={this.state.filter.order_by === 7 ? 
                                    this.state.filter.asc === 1 ? 'ascending' : 'descending'    
                                : null}
                                onClick={this.handleSort(7)}
                                >
                                Date
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                Action
                                </Table.HeaderCell>
                            </Table.Row>
                            </Table.Header>
                            <Table.Body>
                            {this.state.taskList.map((v, k)=>
                            <Table.Row key={k}>
                                <Table.Cell>{this.renderNumber(k)}</Table.Cell>
                                <Table.Cell>
                                    <a href={
                                        '/subject/'+v.subject_code+'/'+(v.is_adaptive ? v.topic_id : v.chapter_mini_game_id)+'/question?id='+v.question_id
                                    } rel='link'>{v.question_id}</a>
                                </Table.Cell>
                                <Table.Cell>
                                    <a href={
                                        '/subject/detail/'+v.subject_code+'/content'
                                    } rel='link'>{v.subject_name}</a>
                                </Table.Cell>
                                <Table.Cell>
                                    <a href={
                                        '/subject/detail/'+v.subject_code+'/content?id='+v.chapter_id
                                    } rel='link'>{v.chapter_name}</a>
                                </Table.Cell>
                                <Table.Cell>
                                    <a href={
                                        '/subject/'+v.subject_code+'/'+v.chapter_mini_game_id+'/question'
                                    } rel='link'>{v.mini_game_name}</a>
                                </Table.Cell>
                                <Table.Cell>
                                    <a href={
                                        '/subject/'+v.subject_code+'/'+(v.is_adaptive ? v.topic_id : v.chapter_mini_game_id)+'/question'
                                    } rel='link'>{v.topic_name}</a>
                                </Table.Cell>
                                <Table.Cell>
                                    <a href={
                                        '/profile/'+(v.editor === this.props.auth.user.username ? 'me' : v.editor)
                                    } rel='link'>{v.editor}</a>
                                </Table.Cell>
                                <Table.Cell>{v.date}</Table.Cell>
                                <Table.Cell>
                                    <Button size='mini' 
                                    primary onClick={this.generateColumn.bind(null, v)}>
                                        Preview Update
                                    </Button>
                                </Table.Cell>
                            </Table.Row>
                            )}
                            </Table.Body>
                        </Table>
                        
                        
                        {/* pagination */}
                        <Divider hidden/>
                        <div>
                            Show <Dropdown defaultValue={10} compact selection options={[
                                {key: 10, text: '10', value: 10},
                                {key: 25, text: '25', value: 25},
                                {key: 50, text: '50', value: 50},
                                {key: 100, text: '100', value: 100},
                            ]} onChange={this.handleChangeEntries} /> entries
                            <div className='pagination'>
                                <span style={{marginRight: 10}}>
                                    Showing {this.state.taskList.length} of {this.state.totalRecord} entries
                                </span>
                                <Pagination activePage={this.state.filter.page} size='small' onPageChange={this.handleChangePagination} 
                                totalPages={this.state.totalPage} />
                            </div>
                        </div>

                        <Modal centered={false} open={this.state.view_changes_modal} closeIcon size='large'
                        onClose={()=>this.setState({view_changes_modal: false})}>
                            <Modal.Header>View Changes</Modal.Header>
                            <Modal.Content>
                            {this.state.selectedTask !== null ?
                                <div style={{maxHeight: 500, overflow: 'auto'}}>
                                    <Header as='h3'>Old</Header>
                                    <table className='ui fixed table'>
                                        <thead>
                                            {this.state.columnPreview !== null ? this.state.columnPreview : null}
                                        </thead>
                                        <tbody>
                                            {[0].map((v,k)=>{
                                                v = this.state.selectedTask.old
                                                let comp = []
                                                if(v !== null){
                                                    const keys = Object.keys(v.question_object)
                                                    comp.push(<tr key={k}>
                                                        {keys.map((i,j)=><td key={j}>
                                                            {i === 'image' ? <img alt='img' style={{width: 100, height: 100}} 
                                                            src={v.question_object[i]}/>
                                                                : this.renderTableBody(v.question_object[i])
                                                            }
                                                        </td>)}
                                                        <td>{v.editor}</td>
                                                        <td>{v.date}</td>
                                                        <td>{v.approved === null ? 'On Review' : v.approved ? 'Approved' : 'Declined'}</td>
                                                    </tr>)
                                                    
                                                }
                                                return comp
                                            })}
                                        </tbody>
                                    </table>
                                    <Divider hidden/>
                                    <Header as='h3' color='green'>New</Header>
                                    <table className='ui fixed green table'>
                                        <thead>
                                            {this.state.columnPreview !== null ? this.state.columnPreview : null}
                                        </thead>
                                        <tbody>
                                            {[0].map((v,k)=>{
                                                v = this.state.selectedTask.new
                                                const keys = Object.keys(v.question_object)
                                                return(
                                                    <tr key={k}>
                                                        {keys.map((i,j)=><td key={j}>
                                                            {i === 'image' ? <img alt='img' style={{width: 100, height: 100}} src={v.question_object[i]}/>
                                                                : this.renderTableBody(v.question_object[i])
                                                            }
                                                        </td>)}
                                                        <td>{v.editor}</td>
                                                        <td>{v.date}</td>
                                                        <td>{v.approved === null ? 'On Review' : v.approved ? 'Approved' : 'Declined'}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div> 
                                : null
                            }
                            </Modal.Content>
                            <Modal.Actions>
                                <Checkbox defaultChecked={this.state.nextValidate} label='Validate Another' onChange={(e, data)=>this.setState({nextValidate: data.checked})}/>
                                <Button type='button' positive size='mini' 
                                onClick={()=>{
                                    this.singleActionTable(1, this.state.selectedTask)
                                    this.setState({view_changes_modal: false})
                                }}>
                                    <Icon name='check'/> Approve
                                </Button>
                                <Button type='button' negative size='mini'
                                onClick={()=>{
                                    this.singleActionTable(2, this.state.selectedTask)
                                    this.setState({view_changes_modal: false})
                                }}>
                                    <Icon name='remove'/> Decline
                                </Button>
                            </Modal.Actions>
                        </Modal>

                        <Modal size='small' open={this.state.declineModal}>
                            <Modal.Header>Decline Entry</Modal.Header>
                            <Modal.Content>
                                <p>Why do you decline this entry?</p>
                                <div className='ui form'>
                                <TextArea placeholder='Type your comment' value={this.state.comment} 
                                onChange={(e, data)=>this.setState({comment: data.value})}/>
                                </div>
                                <Divider hidden/>
                                <p>User suggested reason:</p>
                                <Label.Group color='blue'>
                                    {suggestedReason.map((v,k)=>
                                        <Label as='a' key={k} onClick={
                                            this.addSuggestedReason.bind(null, v)
                                        }>
                                            {v}
                                        </Label>
                                    )}
                                </Label.Group>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button className='tertiary' onClick={()=>{
                                    this.setState({comment: ''})
                                    this.setState({declineModal: false})
                                }}>Cancel</Button>
                                <Button negative onClick={()=>{
                                    if(this.state.declinedId === null){
                                        this.approveDeclineAll('decline')
                                    }else{
                                        this.processApproveDecline('decline', this.state.selectedTask)
                                    }
                                    this.setState({declineModal: false})
                                }}>Decline</Button>
                            </Modal.Actions>
                        </Modal>
                    </>
                    : <EmptyComponent/>
                }
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskManagerPage)