import React from 'react';
import {Segment, Container, Grid, Header, List, Icon, Image} from 'semantic-ui-react';

const FooterAdminComponent = () =>{
    return(
        <Segment className='footer' vertical>
            <Container>
                <Grid stackable columns='equal'>
                <Grid.Row>
                    <Grid.Column className='firstSection'>
                    <Header as='h5' content='Solve Education' />
                    <List bulleted horizontal link>
                        <List.Item as='a' href='https://solveeducation.org'>About</List.Item>
                        <List.Item as='a' href='https://solveeducation.org/who-we-are/'>Our Team</List.Item>
                        <List.Item as='a' href='https://solveeducation.org/category/blog-en/'>Blog</List.Item>
                    </List>
                    <br/>
                    <List bulleted horizontal link>
                        <List.Item as='a' href='https://dawnofcivilization.net'>Dawn of Civilization</List.Item>
                        <List.Item as='a' href='https://dawnofcivilization.net'>Solve Employment!</List.Item>
                    </List>
                    </Grid.Column>
                    <Grid.Column width={7}>
                        <Grid columns='equal'>
                            <Grid.Row>
                                <Grid.Column className='contactUs'>
                                    <Header as='h5' content='Follow Us' />
                                    <Icon link name='linkedin' circular 
                                    onClick={()=>window.location.href='https://www.linkedin.com/company/solve-education-/'}/>
                                    <Icon link name='twitter' circular 
                                    onClick={()=>window.location.href='https://twitter.com/solveeducation'}/>
                                    <Icon link name='facebook f' circular 
                                    onClick={()=>window.location.href='https://www.facebook.com/solveeducation/'}/>
                                    <Icon link name='instagram' circular 
                                    onClick={()=>window.location.href='https://www.instagram.com/solveeducation/'}/>
                                    <Icon link name='youtube play' circular 
                                    onClick={()=>window.location.href='https://www.youtube.com/channel/UCXFFHqO7BzyiaG1qt8MBhlg'}/>
                                </Grid.Column>
                                <Grid.Column>
                                <Header as='h5' textAlign='right' content='Download Dawn of Civilization' />
                                <Image src={require('../assets/images/google_play.png')} 
                                as='a'
                                floated='right'
                                rounded
                                href='https://play.google.com/store/apps/details?id=com.solveedu.dawnofcivilization'
                                size='small'/>
                                </Grid.Column>
                                <Grid.Column width={16}>
                                    <span style={{
                                        fontSize: 12,
                                        float: 'right',
                                        color: 'rgba(3, 28, 48, 0.5)'
                                    }}>© 2017-2020 Solve Education | All Rights Reserved.</span>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
                </Grid>
            </Container>
            </Segment>
    )
}

export default FooterAdminComponent