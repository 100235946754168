import React from 'react';
import { Segment, Table, Checkbox, Loader, Button, Icon, Modal, Divider, Pagination, 
Dropdown, Label, TextArea, Popup, Dimmer, Menu } from 'semantic-ui-react';
import {getSubjectRedux} from '../../../helpers/redux';
import {connect} from 'react-redux';
import RCForm from '../../../forms/reading_comp';
import {toast} from 'react-toastify';
import storage from 'redux-persist/lib/storage';
import RCPreview from '../../../forms/reading_comp/preview';

// to detect every store of redux/state changed
function mapStateToProps(state){
    return{
        add_subject_step: state.add_subject_step,
        is_rc_question_open: state.is_rc_question_open
    }
}

// to detect every store of redux/state changed
function mapDispatchToProps(dispatch) {
    return { 
        setRCQuestionOpen: (n) => dispatch({ type: 'IS_RC_NEW_QUESTION', payload: n }),
        setBannerLoading: (n) => dispatch({ type: 'IS_BANNER_LOADING', payload: n }),
        setTaskManager: (n) => dispatch({ type: 'SET_TASK_MANAGER', payload: n })
    }
}

class RCTable extends React.Component{
    constructor(props){
        super(props)
        const currentSubject = getSubjectRedux(this.props.add_subject_step, this.props.match.params.code)
        if(currentSubject.role === null && currentSubject.private){
            window.location.href = '/subject/detail/'+currentSubject.code+'/content'
            return false
        }
        this.state = {
            currentSubject: currentSubject,
            questionList: [],
            filter:{
                "n": 10,
                "page": 1,
                "search": "",
                "is_review": 0,
                "is_adaptive": currentSubject.adaptive ? true : false
            },
            loadingQuestion: true,
            totalRecord: 0,
            totalPage: 0,
            declineModal: false,
            comment: '',
            declinedId: 0,
            viewModal: false,
            deleteConfirm: false,
            selectedEntry: null,
            deleteLoading: false,
            rowHoverId: null,
            rowHover: false,
            historyModal: false,
            historyList: [],
            previewLoading: false,
            intervalFunc: null,
        }
    }

    componentDidMount(){
        if(this.props.is_rc_question_open){
            this.props.setRCQuestionOpen(false)
        }
        this.loadQuestion()
        this.setState({intervalFunc: setInterval(this.loadQuestion, 10000)})
    }

    componentDidUpdate(prevState, prevProp){
        if(this.props.is_rc_question_open){
            window.onbeforeunload = function(e) {
                return "Changes you made may not be saved.";
            };
        }else{
            window.onbeforeunload = null;
        }
    }

    loadQuestion = () =>{
        let params = this.props.match.params
        fetch(process.env.REACT_APP_API_URL+'api/v1/questions/'+params.mg+'/list', {
            headers: this.props.myHeaders,
            method: 'post',
            body: JSON.stringify(this.state.filter)
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            this.setState({loadingQuestion: false, questionList: res.data, totalRecord: res.total_record, 
                totalPage: res.total_page})
        }).catch((e)=>{
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })   
    }

    closeModal = () =>{
        this.props.setRCQuestionOpen(false)
        this.loadQuestion()
    }

    changeEntries = (e, data) =>{
        let filter = this.state.filter
        filter.n = data.value
        filter.page = 1
        this.setState({filter: filter})
        window.setTimeout(()=>{
            this.loadQuestion()
        }, 0)
    }

    handleChangePagination = (e, data) =>{
        let filter = this.state.filter
        filter.page = data.activePage
        this.setState({filter: filter})
        window.setTimeout(()=>{
            this.loadQuestion()
        }, 0)
    }

    onChangeSearch = (e) =>{
        let filter = this.state.filter
        filter.search = e.target.value
        filter.page = 1
        this.setState({filter: filter})
        window.setTimeout(()=>{
            this.loadQuestion()
        }, 0)
    }

    showReviewContent = (e, data) =>{
        let filter = this.state.filter
        filter.is_review = data.checked ? 1 : 0
        filter.page = 1
        this.setState({filter: filter})
        window.setTimeout(()=>{
            this.loadQuestion()
        }, 0)
    }

    singleActionTable = (type, id, e) =>{
        switch (type) {
            // approve
            case 1:
                this.processApproveDecline('approve', id)
                break;
            // decline
            default:
                this.setState({declineModal: true, declinedId: id})
                break;
        }
    }

    addSuggestedReason = (text, e) =>{
        let comment = this.state.comment
        comment += text+' '
        this.setState({comment: comment})
    }

    processApproveDecline = (type, qid) =>{
        fetch(process.env.REACT_APP_API_URL+'api/v1/questions/'+this.props.match.params.mg+'/'+type+'/'+qid, {
            headers: this.props.myHeaders,
            method: 'post',
            body: JSON.stringify({
                is_adaptive: this.state.currentSubject.adaptive ? true : false,
                comment: this.state.comment
            })
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            this.props.setBannerLoading(false)
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
            
            if(res.status){
                toast.success('success '+type)
                this.loadQuestion()
                setTimeout(()=>{
                    this.props.setBannerLoading(true)
                }, 10)
                this.props.setTaskManager(true)
            }else{
                toast.error(res.message)
            }
        }).catch((e)=>{
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    viewQuestion = (id, e) =>{
        let question = this.state.questionList
        let idx = question.findIndex(x=>x.question_id === id)
        if(idx !== -1){
            let qs = question[idx].question_object
            let param = {
                response_time: qs.response_time,
                question: qs.questions
            }
            let passage = {
                topic: qs.topic,
                passage: qs.passage,
                source_type: qs.source_type,
                source_title: qs.source_title,
                source_url: qs.source_url,
                retrieve_date: qs.retrieve_date,
                image: qs.image !== undefined ? qs.image : null,
                show_image: qs.show_image,
                reading_time: qs.reading_time,
                is_automatic: qs.is_automatic
            }
            localStorage.setItem('passage', JSON.stringify(passage))
            localStorage.setItem('question', JSON.stringify(param))
            this.setState({viewModal: true})
        }else{
            toast.error('Cannot find the question.')
        }
    }

    editQuestion = (id, e) =>{
        let question = this.state.questionList
        let idx = question.findIndex(x=>x.question_id === id)
        if(idx !== -1){
            let qs = question[idx].question_object
            let param = {
                response_time: qs.response_time,
                question: qs.questions
            }
            let passage = {
                topic: qs.topic,
                passage: qs.passage,
                source_type: qs.source_type,
                source_title: qs.source_title,
                source_url: qs.source_url,
                retrieve_date: qs.retrieve_date,
                image: qs.image !== undefined ? qs.image : null,
                show_image: qs.show_image,
                reading_time: qs.reading_time,
                is_automatic: qs.is_automatic
            }
            localStorage.setItem('passage', JSON.stringify(passage))
            localStorage.setItem('question', JSON.stringify(param))
            localStorage.setItem('rc_edit_id', id)
            if(question[idx].qgen_data.status !== undefined){
                localStorage.setItem('qgen_data', JSON.stringify(question[idx].qgen_data))
            }
            this.props.setRCQuestionOpen(true)
        }else{
            toast.error('Cannot find the question.')
        }
    }

    deleteEntry = () =>{
        this.setState({deleteLoading: true})
        fetch(process.env.REACT_APP_API_URL+'api/v1/questions/'+this.state.selectedEntry.question_id+'/delete',{
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            }),
            method: 'delete'
        })
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href='/'
            }
            if(res.status){
                toast.success('Entry deleted.')
                this.setState({deleteLoading: false, deleteConfirm: false, selectedEntry: null})
                this.loadQuestion()
            }else{
                toast.error(res.message)
            }
        })
        .catch((e)=>{
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    onHoverTableRow = (data, e) =>{
        let rowHover = false
        if(data.type === 'in'){
            rowHover = true
        }
        this.setState({rowHover: rowHover, rowHoverId: data.id})
    }

    openHistory = (id, e) =>{
        this.setState({previewLoading: true})
        fetch(process.env.REACT_APP_API_URL+'api/v1/questions/'+id+'/history', {
            headers: this.props.myHeaders
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
            if(res.status){
                this.setState({historyModal: true, historyList: res.data})
            }else{
                toast.error(res.message)
            }
            this.setState({previewLoading: false})
        }).catch((e)=>{
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    hoverAction = (data, e) =>{
        let qsList = this.state.questionList
        let qsIdx = qsList.findIndex(x=>x.question_id === data.question_id)
        if(qsIdx !== -1){
            qsList[qsIdx].qgen_data.is_hover = !qsList[qsIdx].qgen_data.is_hover
        }

        this.setState({questionList: qsList})
    }

    generateQuestionStatus = (v) =>{
        let template = null
        let btnRetry = <Menu secondary size='small'>
            <Menu.Item
            link
            onClick={this.generateActionStatus.bind(null, 'retry', v.qgen_data.queue_id)}>
                <Icon color='blue' name='refresh' />
                Retry
            </Menu.Item>
        </Menu>
        let btnCancel = <Menu secondary size='small'>
            <Menu.Item
            link
            onClick={this.generateActionStatus.bind(null, 'cancel', v.qgen_data.queue_id)}>
                <Icon color='red' name='stop' />
                Cancel
            </Menu.Item>
        </Menu>
        if(v.qgen_data.status !== undefined){
            if(v.qgen_data.is_canceled){
                template = <Popup style={{padding: 0}} size='mini' hoverable position='top right' 
                trigger={<Button size="mini" color='red' style={{cursor: 'auto'}}><Icon name='remove circle'/> Canceled</Button>} content={btnRetry}></Popup>
            }else if(v.qgen_data.status < 2){
                template = <Popup style={{padding: 0}} size='mini' hoverable position='top right' 
                trigger={<Button style={{cursor: 'auto'}} color={v.qgen_data.status ? 'teal' : ''}
                size="mini"><Icon name='time'/> {v.qgen_data.status_text}</Button>} content={btnCancel}></Popup>
            }else if(v.qgen_data.status === 3){
                template = <Popup style={{padding: 0}} size='mini' hoverable position='top right' 
                trigger={<Button style={{cursor: 'auto'}} size="mini" color='red'><Icon name='times circle'/> {v.qgen_data.status_text}</Button>} content={btnRetry}></Popup>
            }else{
                template = <Button color='green' style={{pointerEvents: 'none'}} size="mini">
                   <Icon name='check circle'/> {v.qgen_data.status_text}
                </Button>
            }
        }
        
        return template === null ? template : <>
        <Divider/>
        <span style={{fontSize: 10, marginRight: 5}}>{v.qgen_data.created_date_relative}</span>
        {template}
        </>
    }

    generateActionStatus = (type, id, e) =>{
        fetch(process.env.REACT_APP_API_URL+'api/v1/external/question-generation/'+type+'/'+id,{
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            })
        })
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href='/'
            }
            if(res.status){
                this.loadQuestion()
            }else{
                toast.error(res.message)
            }
        })
        .catch((e)=>{
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    render() {
        let entries = [
            {key: 10, text: '10', value: 10},
            {key: 25, text: '25', value: 25},
            {key: 50, text: '50', value: 50},
            {key: 100, text: '100', value: 100},
        ]

        const suggestedReason = [
            'Not relevant.',
            'Typo(s) detected.',
            'Bad word(s) detected.',
            'Duplicate.'
        ]
      return (
        <>
            <div className="ui icon rounded input">
                <input onChange={this.onChangeSearch} type="text" placeholder="Search Topics" id='qst_search'/>
                <i className="search icon"></i>
            </div>
            {this.state.currentSubject.role === 3 ? 
            <Checkbox onChange={this.showReviewContent} 
            style={{marginLeft:10}} toggle label='Show on review content only'/> : null}
            {this.state.loadingQuestion ? <Loader active/> : this.state.questionList.length === 0 ? 
            <Segment textAlign='center' padded style={{padding: '3em'}}>
                <p>No question data.</p>
            </Segment>
            :
            <>
            <div className='questionTable' id='questionTable'>
                <Table singleLine fixed>
                    <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>No.</Table.HeaderCell>
                        <Table.HeaderCell>Topic</Table.HeaderCell>
                        <Table.HeaderCell>Length of Passage (words)</Table.HeaderCell>
                        <Table.HeaderCell>Reading Time (minutes)</Table.HeaderCell>
                        <Table.HeaderCell>Number of Questions</Table.HeaderCell>
                        <Table.HeaderCell style={{width: 200}}>Action</Table.HeaderCell>
                    </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {this.state.questionList.map((v, k)=>
                        <Table.Row key={k} onMouseEnter={this.onHoverTableRow.bind(null, {
                            type: 'in', id: v.question_number
                        })} 
                        onMouseLeave={this.onHoverTableRow.bind(null, {
                            type: 'out', id: v.question_number
                        })}>
                            <Table.Cell>
                                {v.question_number}
                                {this.state.rowHover && this.state.rowHoverId === v.question_number ? 
                                <div className='hiddenAction'>
                                    <Popup content='Preview Content' trigger={
                                    <Button size='mini' icon='eye' primary
                                    type='button'/>
                                    }/>
                                    <br/>
                                    <Popup content='View History' trigger={
                                    <Button size='mini' icon='history' primary
                                    type='button' onClick={this.openHistory.bind(null, v.question_id)}/>
                                    }/>
                                </div>
                                : null}
                            </Table.Cell>
                            <Table.Cell>{v.question_object.topic}</Table.Cell>
                            <Table.Cell>{v.question_object.passage.split(' ').length}</Table.Cell>
                            <Table.Cell>{v.question_object.reading_time}</Table.Cell>
                            <Table.Cell>{v.question_object.questions.length}</Table.Cell>
                            <Table.Cell>
                                <div className='actionBtn'>
                                    <div>
                                    {v.status && this.state.currentSubject.role === 3 ?
                                        <>
                                            <Button type='button' positive size='mini' 
                                            onClick={this.singleActionTable.bind(null, 1, v.question_id)}
                                            >
                                                <Icon name='check'/> Approve
                                            </Button>
                                            <Button type='button' negative size='mini'
                                            onClick={this.singleActionTable.bind(null, 2, v.question_id)}
                                            >
                                                <Icon name='remove'/> Decline
                                            </Button>
                                            <br/>
                                        </>
                                    : null}
                                    <Button type='button' 
                                    onClick={this.viewQuestion.bind(null, v.question_id)}
                                        secondary size='mini'>
                                        <Icon name='eye'/> View
                                    </Button>
                                    </div>
                                    {this.state.currentSubject.role === 3 && v.status ? <Divider/> : null}
                                    <div>
                                        <Button disabled={v.status || this.state.currentSubject.role === null || this.state.currentSubject.is_demo || (v.qgen_data.status !== undefined && !v.qgen_data.is_canceled && v.qgen_data.status < 2)} type='button' 
                                        onClick={this.editQuestion.bind(null, v.question_id)}
                                        secondary size='mini'>
                                            <Icon name='pencil'/> Edit
                                        </Button>
                                        {this.state.currentSubject.role === 3 ? 
                                        <Button type='button' 
                                        onClick={()=>this.setState({deleteConfirm: true, selectedEntry: v})}
                                        secondary size='mini'>
                                            <Icon name='trash'/> Delete
                                        </Button>
                                        : null}

                                        {this.generateQuestionStatus(v)}
                                    </div>
                                </div>
                            </Table.Cell>
                        </Table.Row>
                        )}
                    </Table.Body>
                </Table>
                {this.state.previewLoading ? <Dimmer active inverted>
                    <Loader active content='Loading...'/>
                </Dimmer> : null}
            </div>
            </> 
            }

            {/* pagination */}
            <Divider hidden/>
            <div>
                Show <Dropdown onChange={this.changeEntries} defaultValue={10} compact selection options={entries} /> entries
                <div className='pagination'>
                    <span style={{marginRight: 10}}>
                        Showing {this.state.questionList.length} of {this.state.totalRecord} entries
                    </span>
                    <Pagination activePage={this.state.filter.page} size='small' onPageChange={this.handleChangePagination} 
                    totalPages={this.state.totalPage} />
                </div>
            </div>

            <Modal open={this.props.is_rc_question_open} size='large' closeIcon onClose={()=>{
                if(window.confirm('You are about to exit the form, want to continue exit?')){
                    localStorage.removeItem('passage')
                    localStorage.removeItem('question')
                    localStorage.removeItem('qgen_data')
                    localStorage.removeItem('rc_edit_id')
                    this.props.setRCQuestionOpen(false)
                }
            }}>
                <Modal.Content>
                    <RCForm header={this.props.myHeaders} mg={this.props.match.params.mg} action={this.closeModal} 
                    is_adaptive={this.state.currentSubject.adaptive} closeModal={()=>{
                        this.props.setRCQuestionOpen(false)
                        this.loadQuestion()
                    }}/>
                </Modal.Content>
            </Modal>

            <Modal open={this.state.viewModal} closeIcon onClose={()=>{
                localStorage.removeItem('passage')
                localStorage.removeItem('question')
                localStorage.removeItem('qgen_data')
                localStorage.removeItem('rc_edit_id')
                this.setState({viewModal: false})
            }}>
                <Modal.Header>Question Preview</Modal.Header>
                <Modal.Content>
                    {this.state.viewModal ? 
                    <RCPreview is_preview={true}/>
                    : null}
                </Modal.Content>
            </Modal>

            <Modal size='small' open={this.state.declineModal}>
                <Modal.Header>Decline Entry</Modal.Header>
                <Modal.Content>
                    <p>Why do you decline this entry?</p>
                    <div className='ui form'>
                    <TextArea placeholder='Type your comment' value={this.state.comment} 
                    onChange={(e, data)=>this.setState({comment: data.value})}/>
                    </div>
                    <Divider hidden/>
                    <p>User suggested reason:</p>
                    <Label.Group color='blue'>
                        {suggestedReason.map((v,k)=>
                            <Label as='a' key={k} onClick={
                                this.addSuggestedReason.bind(null, v)
                            }>
                                {v}
                            </Label>
                        )}
                    </Label.Group>
                </Modal.Content>
                <Modal.Actions>
                    <Button className='tertiary' onClick={()=>{
                        this.setState({comment: ''})
                        this.setState({declineModal: false})
                    }}>Cancel</Button>
                    <Button negative onClick={()=>{
                        this.processApproveDecline('decline', this.state.declinedId)
                        this.setState({declineModal: false})
                    }}>Decline</Button>
                </Modal.Actions>
            </Modal>

            <Modal size='small' open={this.state.deleteConfirm}>
                <Modal.Content style={{padding: 20, paddingBottom: 50}}>
                    <div className='publishModal'>
                        <div>
                            <Icon name='exclamation triangle' circular/>
                        </div>
                        <div className='content'>
                            <h3>Are you sure you want to delete this question?</h3>
                            <p>
                                You cannot undo this. This question will immediately be deleted from Content+ and deleted permanently from the game when the subject is published.
                            </p>
                        </div>
                    </div>
                    <Divider hidden/>
                    <Button onClick={()=>this.setState({deleteConfirm: false})} 
                    floated='right' primary disabled={this.state.deleteLoading}>Cancel</Button>
                    <Button onClick={this.deleteEntry} disabled={this.state.deleteLoading}
                    loading={this.state.deleteLoading} floated='right' secondary>Yes</Button>
                </Modal.Content>
            </Modal>
            
            <Modal size='large' open={this.state.historyModal} closeIcon onClose={()=>this.setState({historyModal: false})}>
                <Modal.Header>Question History</Modal.Header>
                <Modal.Content>
                    <Table singleLine fixed>
                        <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Topic</Table.HeaderCell>
                            <Table.HeaderCell>Length of Passage (words)</Table.HeaderCell>
                            <Table.HeaderCell>Reading Time (minutes)</Table.HeaderCell>
                            <Table.HeaderCell>Number of Questions</Table.HeaderCell>
                            <Table.HeaderCell>Editor</Table.HeaderCell>
                            <Table.HeaderCell>Datetime</Table.HeaderCell>
                            <Table.HeaderCell>Status</Table.HeaderCell>
                        </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.state.historyList.map((v, k)=>
                                <Table.Row key={k}>
                                    <Table.Cell>{v.question_object.topic}</Table.Cell>
                                    <Table.Cell>{v.question_object.passage.split(' ').length}</Table.Cell>
                                    <Table.Cell>{v.question_object.reading_time}</Table.Cell>
                                    <Table.Cell>{v.question_object.questions.length}</Table.Cell>
                                    <Table.Cell>{v.editor}</Table.Cell>
                                    <Table.Cell>{v.date}</Table.Cell>
                                    <Table.Cell>{v.approved === null ? 'On Review' : v.approved ? 'Approved' : 'Declined'}</Table.Cell>
                                </Table.Row>
                            )}
                        </Table.Body>
                    </Table>
                </Modal.Content>
            </Modal>
        </>
      )
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RCTable)