import React from 'react';
import { Segment, Icon, Menu, Loader, Popup, Button, Divider, Message, Modal, Form, 
    Dropdown, Grid, Table, Breadcrumb } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import {connect} from 'react-redux';
import { toast } from 'react-toastify';
import storage from 'redux-persist/lib/storage'
import {
    DateInput
} from 'semantic-ui-calendar-react';
import moment from 'moment-timezone'
import {getSubjectRedux} from '../../../../helpers/redux';
import DestinationFolderComponent from '../../../../components/move_question';

function mapStateToProps(state) {
    return { 
        add_subject_step: state.add_subject_step,
        auth: state.auth
    }
}

class DetailAdaptive extends React.Component{
    constructor(props){
        super(props)
        let url_string = window.location.href
        let url = new URL(url_string)
        let l_val = url.searchParams.get("l")
        let t_val = url.searchParams.get("t")
        this.state = {
            loadingLevel: true,
            levels: null,
            currentTopic: 0,
            topics: null,
            subTopics: [],
            assignLevelModal: false,
            levelList: [],
            levelSelected: null,
            topicModal: false,
            topicForm:{
                name: '',
                has_sub_topic: false
            },
            isUpdateTopic: false,
            isSubTopic: false,
            topicParent: null,
            selectedTopic: null,
            contributors: [],
            assigModal: false,
            assignForm:{
                username: '',
                due_date: moment(new Date()).format('YYYY-MM-DD')
            },
            excludeModal: false,
            minigamesExcludeable: [],
            minigamesExcluded: [],
            selectedExcludeMG: null,
            moduleName: localStorage.getItem('ADAPTIVE_MODULE_NAME'),
            urlParam: {
                level: l_val,
                topics: t_val !== null ? t_val.split(',') : [],
                is_active: true
            },
            breadcrumbs: [],
            assignLevelLoading: false,
            addTopicLoading: false,
            assignTopicLoading: false,
            currentSubject: getSubjectRedux(this.props.add_subject_step, this.props.match.params.code),
            granularList: [
                {
                    key: 1,
                    text: 'Granular 1',
                    value: 1
                },
                {
                    key: 2,
                    text: 'Granular 2',
                    value: 2
                },
                {
                    key: 3,
                    text: 'Granular 3',
                    value: 3
                },
            ],
            chapterForm: {
                name: ''
            },
            is_edit_chapter: false,
            selectedChapter: null,
            is_chapter_move: false,
            destinyModal: false,
            sourceData: null,
            deleteConfirm: false,
            dataTobeRemoved: null,
            removeLoading: false
        }
    }

    componentDidMount(){
        let param = this.state.urlParam
        if(param.level !== null && param.topics.length > 0){
            this.setState({levelSelected: parseInt(param.level)})
            this.loadModule()
        }
        this.loadDataLevel()
        this.loadContributor()
        
    }

    loadModule = () =>{
        fetch(process.env.REACT_APP_API_URL+'api/v1/adaptive/'+this.props.match.params.code+'/modules', {
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            })
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href='/'
            }
            let idx = res.data.findIndex(x=>x.subject_module_id === parseInt(this.props.match.params.mid))
            if(idx !==-1){
                let mName = res.data[idx].module_name
                localStorage.setItem('ADAPTIVE_MODULE_NAME', mName)
                this.setState({moduleName: mName})
            }
        }).catch((e)=>{
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    loadDataLevel = () =>{
        this.setState({loadingLevel: true})
        fetch(process.env.REACT_APP_API_URL+'api/v1/adaptive/modules/'+this.props.match.params.mid+'/levels',{
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            })
        })
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href='/'
            }
            let comp = {
                assignments: [],
                data: []
            }


            Object.keys(res.data).forEach(value=>{
                res.data[value].forEach(val => {
                    val.active = this.state.levelSelected !== val.subject_module_level_id ? false : true
                    if(val.assigned_to_me){
                        if(comp.assignments[value] === undefined){
                            comp.assignments[value] = []
                            comp.assignments[value].push(val)
                        }else{
                            comp.assignments[value].push(val)
                        }
                    }else{
                        if(comp.data[value] === undefined){
                            comp.data[value] = []
                            comp.data[value].push(val)
                        }else{
                            comp.data[value].push(val)
                        }
                    }
                })
            })

            this.setState({levels: comp, loadingLevel: false})
            
            setTimeout(()=>{
                let param = this.state.urlParam
                let l_val = param.level !== null ? parseInt(param.level) : null
                if(l_val !== null && param.is_active){
                    this.loadGranularItem(l_val, null)
                }else if(this.state.levelSelected !== null){
                    this.loadGranularItem(this.state.levelSelected, null)
                }
                if(param.topics.length === 0){
                    param.is_active = false
                    this.setState({urlParam: param})
                }
            }, 100)
        })
        .catch((e)=>{
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    // loadTopic = (id, is_active, e) =>{
    //     // if(is_active){
    //     //     return false
    //     // }
    //     let br_list = this.state.breadcrumbs
    //     let br_idx = br_list.findIndex(x=>x.index === 0)

    //     let levels = this.state.levels
    //     let levelIndex = levels.assignments.findIndex(x=>x.subject_module_level_id === id)
    //     if(levelIndex !== -1){
    //         levels.assignments[levelIndex].active = true
    //         if(br_idx < 0){
    //             br_list.push({
    //                 index: 0,
    //                 id: levels.assignments[levelIndex].subject_module_level_id,
    //                 name: levels.assignments[levelIndex].level_name,
    //                 type: 'level'
    //             })
    //         }else{
    //             br_list[br_idx].id = levels.assignments[levelIndex].subject_module_level_id
    //             br_list[br_idx].name = levels.assignments[levelIndex].level_name
    //         }
    //     }else{
    //         levelIndex = levels.data.findIndex(x=>x.subject_module_level_id === id)
    //         if(levelIndex !== -1){
    //             levels.data[levelIndex].active = true
    //             if(br_idx < 0){
    //                 br_list.push({
    //                     index: 0,
    //                     id: levels.data[levelIndex].subject_module_level_id,
    //                     name: levels.data[levelIndex].level_name,
    //                     type: 'level'
    //                 })
    //             }else{
    //                 br_list[br_idx].id = levels.data[levelIndex].subject_module_level_id
    //                 br_list[br_idx].name = levels.data[levelIndex].level_name
    //             }
    //         }
    //     }

    //     let levelIndexOther = levels.assignments.findIndex(x=>x.subject_module_level_id !== id && x.active)
    //     if(levelIndexOther !== -1){
    //         levels.assignments[levelIndexOther].active = false
    //     }else{
    //         levelIndexOther = levels.data.findIndex(x=>x.subject_module_level_id !== id && x.active)
    //         if(levelIndexOther !== -1){
    //             levels.data[levelIndexOther].active = false
    //         }
    //     }
    //     if(br_list.length > 1){
    //         br_list.splice(1, br_list.length - 1)
    //     }

    //     this.setState({levels: levels, levelSelected: id, breadcrumbs: br_list})
    //     fetch(process.env.REACT_APP_API_URL+'api/v1/adaptive/topics/'+id,{
    //         headers: new Headers({
    //             'Authorization': 'Token '+this.props.auth.user.token
    //         })
    //     })
    //     .then((res)=>{
    //         return res.json()
    //     })
    //     .then((res)=>{
    //         if(res.status === undefined){
    //             storage.removeItem('persist:root')
    //             window.location.href='/'
    //         }

    //         let param = this.state.urlParam
    //         let t_val = param.topics.length > 0 && param.is_active ? param.topics[0] : null
    //         let topicData = null
    //         let data = []
    //         let comp = {
    //             assignments: [],
    //             data: []
    //         }
    //         res.data.forEach(val => {
    //             val.active = val.topic_id === parseInt(t_val) ? true : false
    //             val.is_topic = true
    //             data.push(val)
    //             if(val.topic_id === parseInt(t_val)){
    //                 topicData = val
    //             }
    //         })
    //         comp.assignments = data.filter(x=>x.assigned_to_me)
    //         comp.data = data.filter(x=>!x.assigned_to_me)


    //         this.setState({topics: comp, subTopics: [], currentTopic: 0})
    //         if(topicData === null){
    //             param.is_active = false
    //             this.setState({urlParam: param})
    //         }
    //         setTimeout(()=>{
    //             if(topicData !== null && param.is_active){
    //                 this.loadSubTopic(topicData, false, true, null)
    //             }
    //         }, 100)
    //     })
    //     .catch((e)=>{
    //         toast.error(<div>
    //             Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
    //         </div>)
    //     })
    // }

    loadSubTopic = (data, is_active, is_topic, e) =>{
        let currentLevel = this.state.currentTopic < 1 ? 1 : data.level !== undefined ? data.level : 1
        if(!data.has_sub_topic){
            return false
        }
        let br_list = this.state.breadcrumbs
        if(is_topic){
            let br_idx = br_list.findIndex(x=>x.index === currentLevel && x.type !== 'level')
            let topics = this.state.topics
            let indexTopic = topics.assignments.findIndex(x=>x.topic_id === data.topic_id)
            if(indexTopic !== -1){
                topics.assignments[indexTopic].active = true
                if(br_idx < 0){
                    br_list.push({
                        index: currentLevel,
                        id: topics.assignments[indexTopic].topic_id,
                        name: topics.assignments[indexTopic].topic_name,
                        type: 'topic'
                    })
                }else{
                    br_list[br_idx].id = topics.assignments[indexTopic].topic_id
                    br_list[br_idx].name = topics.assignments[indexTopic].topic_name
                }
            }else{
                indexTopic = topics.data.findIndex(x=>x.topic_id === data.topic_id)
                if(indexTopic !== -1){
                    topics.data[indexTopic].active = true
                    if(br_idx < 0){
                        br_list.push({
                            index: currentLevel,
                            id: topics.data[indexTopic].topic_id,
                            name: topics.data[indexTopic].topic_name,
                            type: 'topic'
                        })
                    }else{
                        br_list[br_idx].id = topics.data[indexTopic].topic_id
                        br_list[br_idx].name = topics.data[indexTopic].topic_name
                    }
                }
            }
    
            let indexTopicOther = topics.assignments.findIndex(x=>x.topic_id !== data.topic_id && x.active)
            if(indexTopicOther !== -1){
                topics.assignments[indexTopicOther].active = false
            }else{
                indexTopicOther = topics.data.findIndex(x=>x.topic_id !== data.topic_id && x.active)
                if(indexTopicOther !== -1){
                    topics.data[indexTopicOther].active = false
                }
            }
        }else{
            currentLevel++
            let br_idx = br_list.findIndex(x=>x.index === currentLevel && x.type !== 'level')
            let subTopics = this.state.subTopics
            let indexSubTopic = subTopics.findIndex(x=>x.level === data.level)
            if(indexSubTopic !== -1){
                let subT = subTopics[indexSubTopic].data
                let indexTopic = subT.assignments.findIndex(x=>x.topic_id === data.topic_id)
                if(indexTopic !== -1){
                    subT.assignments[indexTopic].active = true
                    if(br_idx < 0){
                        br_list.push({
                            index: currentLevel,
                            id: subT.assignments[indexTopic].topic_id,
                            name: subT.assignments[indexTopic].topic_name,
                            type: 'subtopic',
                            data: subT.assignments[indexTopic]
                        })
                    }else{
                        br_list[br_idx].id = subT.assignments[indexTopic].topic_id
                        br_list[br_idx].name = subT.assignments[indexTopic].topic_name
                        br_list[br_idx].data = subT.assignments[indexTopic]
                    }
                }else{
                    indexTopic = subT.data.findIndex(x=>x.topic_id === data.topic_id)
                    if(indexTopic !== -1){
                        subT.data[indexTopic].active = true
                        if(br_idx < 0){
                            br_list.push({
                                index: currentLevel,
                                id: subT.data[indexTopic].topic_id,
                                name: subT.data[indexTopic].topic_name,
                                type: 'subtopic',
                                data: subT.data[indexTopic]
                            })
                        }else{
                            br_list[br_idx].id = subT.data[indexTopic].topic_id
                            br_list[br_idx].name = subT.data[indexTopic].topic_name
                            br_list[br_idx].data = subT.data[indexTopic]
                        }
                    }
                }
        
                let indexTopicOther = subT.assignments.findIndex(x=>x.topic_id !== data.topic_id && x.active)
                if(indexTopicOther !== -1){
                    subT.assignments[indexTopicOther].active = false
                }else{
                    indexTopicOther = subT.data.findIndex(x=>x.topic_id !== data.topic_id && x.active)
                    if(indexTopicOther !== -1){
                        subT.data[indexTopicOther].active = false
                    }
                }

                subTopics[indexSubTopic].data = subT
                this.setState({subTopics: subTopics})
            }
        }

        if(br_list.length > currentLevel){
            br_list.splice(currentLevel+1, br_list.length-(currentLevel+1))
        }
        this.setState({topicParent: data.topic_id, breadcrumbs: br_list})

        fetch(process.env.REACT_APP_API_URL+'api/v1/adaptive/topics/'+data.subject_module_level_id+'/'+data.topic_id,{
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            })
        })
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href='/'
            }
            let subTop = is_topic ? [] : this.state.subTopics
            let param = this.state.urlParam
            let t_val = param.topics.length > 1 && param.is_active ? param.topics : []
            let selectedData = null

            if(subTop.length > 1){
                if(data.level !== currentLevel){
                    let lastLevel = subTop[subTop.length - 1].level
                    if(data.level < lastLevel){
                        subTop.splice(data.level, lastLevel-data.level)
                    }

                    let dataTemp = []
                    let comp = {
                        assignments: [],
                        data: []
                    }
                    res.data.forEach(val => {
                        let t_idx = t_val.findIndex(x=>parseInt(x) === val.topic_id)
                        val.active = t_idx !== -1 ? true : false
                        val.is_topic = false
                        val.level = currentLevel
                        dataTemp.push(val)
                        if(t_idx !== -1 && parseInt(t_val[t_idx]) === val.topic_id){
                            selectedData = val
                        }
                    })
                    comp.assignments = dataTemp.filter(x=>x.assigned_to_me)
                    comp.data = dataTemp.filter(x=>!x.assigned_to_me)
                    subTop.push({
                        level: currentLevel,
                        data: comp
                    })
                }
            }else{
                let dataTemp = []
                let comp = {
                    assignments: [],
                    data: []
                }
                res.data.forEach(val => {
                    let t_idx = t_val.findIndex(x=>parseInt(x) === val.topic_id)
                    val.active = t_idx !== -1 ? true : false
                    val.is_topic = false
                    val.level = currentLevel
                    dataTemp.push(val)
                    if(t_idx !== -1 && parseInt(t_val[t_idx]) === val.topic_id){
                        selectedData = val
                    }
                })
                comp.assignments = dataTemp.filter(x=>x.assigned_to_me)
                comp.data = dataTemp.filter(x=>!x.assigned_to_me)
                subTop.push({
                    level: currentLevel,
                    data: comp
                })
            }

            this.setState({subTopics: subTop, currentTopic: currentLevel})
            if(selectedData === null){
                param.is_active = false
                this.setState({urlParam: param})
            }
            setTimeout(()=>{
                if(selectedData !== null && param.is_active){
                    this.loadSubTopic(selectedData, false, false, null)
                }
            }, 100)
        })
        .catch((e)=>{
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    loadContributor = () =>{
        fetch(process.env.REACT_APP_API_URL+'api/v1/contributors?code='+this.props.match.params.code,{
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            })
        })
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href='/'
            }
            let opt = []
            if(res !== undefined && res.data !== undefined){
                res.data.forEach(x=>{
                    if(x.role !== 'Owner'){
                        opt.push({
                            key: x.username,
                            text: x.username+' - '+x.email,
                            value: x.username,
                            image: { avatar: true, src: x.avatar !== null ? x.avatar : require('../../../../assets/images/avatar-default.png') }
                        })
                    }
                })
            }
            
            this.setState({contributors: opt})
        })
    }

    generateTopicAction = (data, e) =>{
        let actions = []
        if(this.state.currentSubject.role === 3){
            actions.push(<Menu.Item icon='pencil' key='edit' 
            onClick={()=>this.setState({topicModal: true, topicForm: {
                name: data.topic_name,
                has_sub_topic: data.has_sub_topic
            }, isUpdateTopic: true, isSubTopic: data.parent_id !== null ? true : false,
            selectedTopic: data})} name='Edit Name'/>)
            if(data.assignment !== null){
                actions.push(<Menu.Item icon='check circle' key='mdone' onClick={this.markASDoneTopic.bind(null, data)} 
                name='Mark as Done'/>)
            }else{
                actions.push(<Menu.Item icon='user circle' key='assign' 
                onClick={()=>this.setState({assigModal: true, isSubTopic: data.parent_id !== null ? true : false,
                    selectedTopic: data})} name='Assign'/>)
            }
            actions.push(<Menu.Item icon='ban' onClick={this.openExclude.bind(null, data)} name='Exclude' key='exl'/>)
            actions.push(<>
                <Menu.Item icon='arrow up'   onClick={this.moveTopDownContent} name='Move Up'/>
                <Menu.Item icon='arrow down' onClick={this.moveTopDownContent} name='Move Down'/>
            </>)
            actions.push(<Menu.Item icon='move' onClick={()=>this.setState({destinyModal: true, sourceData: data})} name='move' key='move'/>)
            actions.push(<Menu.Item icon='trash' onClick={this.deleteTopic.bind(null, data)} 
            name='Delete' key='del'/>)
        }else if(data.assignment !== null && !this.state.currentSubject.is_demo){
            actions.push(<Menu.Item icon='check circle' onClick={this.markASDoneTopic.bind(null, data)} 
            name='Mark as Done' key='mdone'/>)
        }
        

        return (
            <Menu vertical compact size='mini'>
                {actions.map((v)=>
                    { return v }
                )}
            </Menu>
        )
    }

    generateTopic = (v, k) =>{
        return(
            <div key={k} className={v.active ? 'grachild active' : !v.has_sub_topic ? 'grachild noChild' : 'grachild'}
            style={
                v.hidden ? {opacity: 0.5} : null
            }>
                <Menu.Item as='div' className={v.assigned_to_me ? 'assigned' : ''} 
                onClick={this.loadSubTopic.bind(null, v, v.active, v.is_topic)} >
                    {v.topic_name}
                    <Message color={
                        v.message.status === 'error' ? 'red' :
                        v.message.status === 'warning' ? 'yellow' : 'green'
                    } size='mini'>
                        {v.message.status === 'error' ? <Icon name='remove circle'/> :
                        v.message.status === 'warning' ? <Icon name='exclamation triangle'/> : 
                        <Icon name='check circle'/>} 
                        {v.message.message}
                    </Message>
                    <Divider hidden/>
                    {v.assignment !== null ? <>
                        <p><Icon name='user' className='folderLevel'/> Assignee: <b>{v.assignment.assignee}</b></p>
                        <p><Icon name='calendar' className='folderLevel'/> Due Date: <b>{v.assignment.date}</b></p>
                    </> : <>
                        <p><Icon name='user' className='folderLevel'/> Assignee: None</p>
                        <p><Icon name='calendar' className='folderLevel'/> Due Date: None</p>
                    </>}
                    <Divider hidden/>
                    {!v.excluded_mini_games ? null : 
                        <Button size='mini' onClick={this.openExclude.bind(null, v)} disabled={this.state.currentSubject.role !== 3 || this.state.currentSubject.is_demo}>
                            <Icon name='gamepad'/> Excluded Minigames
                        </Button>
                    }

                    {!v.has_sub_topic ? 
                        <Link to={
                            '/subject/'+this.props.match.params.code+'/'+v.topic_id+'/question'
                        } className='ui mini primary button'>
                            <Icon name='folder open'/> Content
                        </Link>
                    : null}
                </Menu.Item>

                {this.state.currentSubject.role === 3 ? 
                    <Popup className='adaptivePopUp' content={this.generateTopicAction.bind(null, v)}
                    on='click' basic
                    pinned trigger={<Icon onClick={(e)=>{
                        e.preventDefault()
                        return false
                    }} link name='ellipsis vertical' />}/>                
                : v.assignment !== null && v.assignment.assignee === this.props.auth.user.username && !this.state.currentSubject.is_demo ?
                    <Popup className='adaptivePopUp' content={this.generateTopicAction.bind(null, v)}
                    on='click' basic
                    pinned trigger={<Icon onClick={(e)=>{
                        e.preventDefault()
                        return false
                    }} link name='ellipsis vertical' />}/>
                : null
                }

                {v.has_sub_topic ? <Icon name='angle right' className='subIndicator'/> : null}
            </div>
        )
    }

    loadGranularItem = (id, e) =>{
        let levels = this.state.levels
        let br_list = this.state.breadcrumbs
        let data = this.getSingleChapter(id)

        // set inactive
        Object.keys(levels.assignments).forEach(v=>{
            let idx = levels.assignments[v].findIndex(x=>x.active && x.subject_module_level_id !== data.subject_module_level_id)
            if(idx !== -1){
                levels.assignments[v][idx].active = false
            }
        })

        Object.keys(levels.data).forEach(v=>{
            let idx = levels.data[v].findIndex(x=>x.active && x.subject_module_level_id !== data.subject_module_level_id)
            if(idx !== -1){
                levels.data[v][idx].active = false
            }
        })

        // set active
        if(levels.assignments.hasOwnProperty(data.granular)){
            let assignIdx = levels.assignments[data.granular].findIndex(x=>x.subject_module_level_id === data.subject_module_level_id)
            if(assignIdx !== -1 && !data.active){
                levels.assignments[data.granular][assignIdx].active = true
            }
        }

        if(levels.data.hasOwnProperty(data.granular)){
            let dataIdx = levels.data[data.granular].findIndex(x=>x.subject_module_level_id === data.subject_module_level_id)
            if(dataIdx !== -1 && !data.active){
                levels.data[data.granular][dataIdx].active = true
            }
        }

        br_list = [
            {name: 'Granular '+data.granular},
            {name: data.level_name}
        ]
        
        this.setState({levels: levels, levelSelected: data.subject_module_level_id, breadcrumbs: br_list})
        fetch(process.env.REACT_APP_API_URL+'api/v1/adaptive/topics/'+data.subject_module_level_id,{
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            })
        })
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href='/'
            }

            let param = this.state.urlParam
            let t_val = param.topics.length > 0 && param.is_active ? param.topics[0] : null
            let topicData = null
            let data = []
            let comp = {
                assignments: [],
                data: []
            }
            res.data.forEach(val => {
                val.active = val.topic_id === parseInt(t_val) ? true : false
                val.is_topic = true
                data.push(val)
                if(val.topic_id === parseInt(t_val)){
                    topicData = val
                }
            })
            comp.assignments = data.filter(x=>x.assigned_to_me)
            comp.data = data.filter(x=>!x.assigned_to_me)


            this.setState({topics: comp, subTopics: [], currentTopic: 0})

            setTimeout(()=>{
                if(topicData !== null && param.is_active){
                    this.loadSubTopic(topicData, false, true, null)
                }
            }, 100)
        })
        .catch((e)=>{
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    getSingleChapter = (id) =>{
        let levels = this.state.levels
        let result = null

        Object.keys(levels.assignments).forEach(v=>{
            let idx = levels.assignments[v].findIndex(x=>x.subject_module_level_id === id)
            if(idx !== -1){
                result = levels.assignments[v][idx]
            }
        })

        Object.keys(levels.data).forEach(v=>{
            let idx = levels.data[v].findIndex(x=>x.subject_module_level_id === id)
            if(idx !== -1){
                result = levels.data[v][idx]
            }
        })

        return result
    }

    generateLevel = (v, k) =>{
        return (
            <div key={k} className={v.active ? 'grachild active' : 'grachild'} style={
                v.hidden ? {opacity: 0.5} : null
            }>
                <Menu.Item className={v.assigned_to_me ? 'assigned' : ''}
                onClick={this.loadGranularItem.bind(null, v.subject_module_level_id)}>
                    <Icon name='folder' className='folderLevel'/> {v.level_name}
                </Menu.Item>
                {this.state.currentSubject.role === 3 && this.state.currentSubject.code !== "english" ? 
                <Popup className='adaptivePopUp' content={
                    <Menu vertical compact size='mini'>
                        <Menu.Item icon='pencil' onClick={()=>this.setState({selectedChapter: v.subject_module_level_id, assignLevelModal: true, is_edit_chapter: true, chapterForm: {name: v.level_name}})} name='Edit Name'/>
                        <Menu.Item icon='arrow up'   onClick={this.levelAction.bind(null, v.subject_module_level_id, 'up')} name='Move Up'/>
                        <Menu.Item icon='arrow down' onClick={this.levelAction.bind(null, v.subject_module_level_id, 'down')} name='Move Down'/>
                        <Menu.Item icon='move' onClick={()=>this.setState({assignLevelModal: true, is_chapter_move: true, is_edit_chapter: true, selectedChapter: v.subject_module_level_id, chapterForm:{name: v.level_name, granular: 1}})} name='Move Granular'/>
                        <Menu.Item icon='trash' onClick={this.levelAction.bind(null, v.subject_module_level_id, 'delete')} name='Delete'/>
                        {v.hidden ? <Menu.Item icon='eye' onClick={this.levelAction.bind(null, v.subject_module_level_id, 'unhide')} name='Unhide'/> : 
                        <Menu.Item icon='eye slash' onClick={this.levelAction.bind(null, v.subject_module_level_id, 'hide')} name='Hide'/>}
                    </Menu>
                }
                on='click' basic
                pinned trigger={<Icon onClick={(e)=>{
                    e.preventDefault()
                    return false
                }} link name='ellipsis vertical' />}/>
                : null}
            </div>
        )
    }

    // assignLevel = () =>{
    //     fetch(process.env.REACT_APP_API_URL+'api/v1/adaptive/'+this.props.match.params.code+'/levels/'+this.props.match.params.mid,{
    //         headers: new Headers({
    //             'Authorization': 'Token '+this.props.auth.user.token
    //         })
    //     })
    //     .then((res)=>{
    //         return res.json()
    //     })
    //     .then((res)=>{
    //         if(res.status === undefined){
    //             storage.removeItem('persist:root')
    //             window.location.href='/'
    //         }

    //         let levels = []
    //         res.data.forEach(val=>{
    //             val.checked = val.assigned ? true : false
    //             levels.push(val)
    //         })
    //         this.setState({assignLevelModal: true, levelList: levels, chapterForm: {name:''}})
    //     })
    //     .catch((e)=>{
    //         toast.error(<div>
    //             Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
    //         </div>)
    //     })
    // }

    saveLevel = () =>{
        let form = this.state.chapterForm
        if(form.name === ''){
            toast.error('Chapter name is required')
            return false
        }

        if(this.state.is_edit_chapter){
            form.id = this.state.selectedChapter
        }

        this.setState({assignLevelLoading: true})
        fetch(process.env.REACT_APP_API_URL+'api/v1/adaptive/chapter/save/'+this.props.match.params.mid,{
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            }),
            method:'post',
            body: JSON.stringify(form)
        })
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            this.setState({assignLevelLoading: false})
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href='/'
            }
            if(res.status){
                if(this.state.is_chapter_move){
                    toast.success('Chapter moved.')
                }else{
                    toast.success('Chapter saved.')
                }
                this.loadDataLevel()
                this.setState({assignLevelModal: false, chapterForm: {name:''}})
            }else{
                if(this.state.is_chapter_move){
                    toast.success('Cannot move chapter.')
                }else{
                    toast.error('Cannot save chapter.')
                }
            }
        })
        .catch((e)=>{
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    addNewTopic = () =>{
        let form = this.state.topicForm
        if(form.name === ''){
            toast.error('Please complete the form')
            return false
        }

        if(this.state.isUpdateTopic){
            form.id = this.state.selectedTopic.topic_id
            form.subject_module_level_id = this.state.selectedTopic.subject_module_level_id
        }else{
            form.subject_module_level_id = this.state.levelSelected
            if(this.state.isSubTopic){
                form.parent_id = this.state.topicParent
            }
        }

        this.setState({addTopicLoading: true})
        fetch(process.env.REACT_APP_API_URL+'api/v1/adaptive/topics/save',{
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            }),
            method:'post',
            body: JSON.stringify(form)
        })
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            this.setState({addTopicLoading: false})
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href='/'
            }
            if(res.status){
                toast.success('Topic saved.')
            }else{
                toast.error('Topic cannot be saved.')
                return false
            }

            if(this.state.isSubTopic){
                let is_called = false
                let subTop = this.state.topics
                let idx = subTop.assignments.findIndex(x=>x.topic_id === this.state.topicParent)
                if(idx !== -1){
                    this.loadSubTopic(subTop.assignments[idx], false, true)
                    is_called = true
                }else{
                    idx = subTop.data.findIndex(x=>x.topic_id === this.state.topicParent)
                    if(idx !== -1){
                        this.loadSubTopic(subTop.data[idx], false, true)
                        is_called = true
                    }
                }

                if(!is_called && this.state.subTopics.length > 0){
                    subTop = this.state.subTopics.length > 1 ? this.state.subTopics[this.state.subTopics.length - 2].data :
                    this.state.subTopics[this.state.subTopics.length - 1].data
                    idx = subTop.assignments.findIndex(x=>x.topic_id === this.state.topicParent)
                    if(idx !== -1){
                        this.loadSubTopic(subTop.assignments[idx], false, false)
                    }else{
                        idx = subTop.data.findIndex(x=>x.topic_id === this.state.topicParent)
                        if(idx !== -1){
                            this.loadSubTopic(subTop.data[idx], false, false)
                        }
                    }
                }
            }else{
                this.loadGranularItem(form.subject_module_level_id)
                // this.loadTopic(form.subject_module_level_id, false)
            }
            this.setState({topicForm: {
                name: '',
                has_sub_topic: false
            }, topicModal: false})
        })
        .catch((e)=>{
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    levelAssignOnChange = (e, data) =>{
        let levelList = this.state.levelList
        let lIndex = levelList.findIndex(x=>x.level_id === data.value)
        if(lIndex !== -1){
            levelList[lIndex].checked = data.checked ? true : false
            this.setState({levelList: levelList})
        }
    }

    topicOnChange = (e, data) =>{
        let form = this.state.topicForm
        switch (data.name) {
            case 'name':
                form.name = data.value
                break;
            default:
                form.has_sub_topic = data.checked
                break;
        }
        this.setState({topicForm: form})
    }

    levelAction = (id, type, e) =>{
        if(type === 'delete'){
            this.setState({deleteConfirm: true, dataTobeRemoved: id})
        }else if(type === 'hide' || type === 'unhide'){
            if(window.confirm('Are you sure want to '+type+'?')){
                fetch(process.env.REACT_APP_API_URL+'api/v1/adaptive/levels/'+type+'/'+id,{
                    headers: new Headers({
                        'Authorization': 'Token '+this.props.auth.user.token
                    })
                })
                .then((res)=>{
                    return res.json()
                })
                .then((res)=>{
                    if(res.status === undefined){
                        storage.removeItem('persist:root')
                        window.location.href='/'
                    }
                    
                    if(res.status){
                        toast.success('Chapter '+type+' successfully.')
                        this.loadDataLevel()
                    }else{
                        toast.error('Cannot '+type+' chapter. '+res.message)
                    }
                })
                .catch((e)=>{
                    toast.error(<div>
                        Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
                    </div>)
                })
            }
        }else{
            fetch(process.env.REACT_APP_API_URL+'api/v1/adaptive/chapter/move/'+type+'/'+id,{
                headers: new Headers({
                    'Authorization': 'Token '+this.props.auth.user.token
                })
            })
            .then((res)=>{
                return res.json()
            })
            .then((res)=>{
                if(res.status === undefined){
                    storage.removeItem('persist:root')
                    window.location.href='/'
                }
                
                if(res.status){
                    this.loadDataLevel()
                }else{
                    toast.error('Cannot move '+type+' chapter. '+res.message)
                }
            })
            .catch((e)=>{
                toast.error(<div>
                    Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
                </div>)
            })
        }
    }

    processDeleteChapter = () =>{
        this.setState({removeLoading: true})
        fetch(process.env.REACT_APP_API_URL+'api/v1/adaptive/levels/delete/'+this.state.dataTobeRemoved,{
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            }),
            method: 'delete'
        })
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href='/'
            }
            
            if(res.status){
                toast.success('Chapter deleted.')
                this.setState({topics: null, subTopics: [], levelSelected: null, removeLoading: false, dataTobeRemoved: null, deleteConfirm: false})
                this.loadDataLevel()
            }else{
                toast.error('Cannot delete chapter. '+res.message)
            }
        })
        .catch((e)=>{
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    assignTopic = () =>{
        let form = this.state.assignForm
        if(form.name === '' || form.due_date === ''){
            toast.error('Please complete the form.')
            return false
        }

        form.topic_id = this.state.selectedTopic.topic_id

        this.setState({assignTopicLoading: true})

        fetch(process.env.REACT_APP_API_URL+'api/v1/adaptive/topics/assign',{
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            }),
            method:'post',
            body: JSON.stringify(form)
        })
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            this.setState({assignTopicLoading: false})
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href='/'
            }
            if(res.status){
                toast.success('Topic assigned.')
            }else{
                toast.error('Topic cannot be assigned.')
                return false
            }

            if(this.state.isSubTopic){
                let is_called = false
                let subTop = this.state.topics
                let idx = subTop.assignments.findIndex(x=>x.topic_id === this.state.topicParent)
                if(idx !== -1){
                    this.loadSubTopic(subTop.assignments[idx], false, true)
                    is_called = true
                }else{
                    idx = subTop.data.findIndex(x=>x.topic_id === this.state.topicParent)
                    if(idx !== -1){
                        this.loadSubTopic(subTop.data[idx], false, true)
                        is_called = true
                    }
                }

                if(!is_called && this.state.subTopics.length > 0){
                    subTop = this.state.subTopics.length > 1 ? this.state.subTopics[this.state.subTopics.length - 2].data :
                    this.state.subTopics[this.state.subTopics.length - 1].data
                    idx = subTop.assignments.findIndex(x=>x.topic_id === this.state.topicParent)
                    if(idx !== -1){
                        this.loadSubTopic(subTop.assignments[idx], false, false)
                    }else{
                        idx = subTop.data.findIndex(x=>x.topic_id === this.state.topicParent)
                        if(idx !== -1){
                            this.loadSubTopic(subTop.data[idx], false, false)
                        }
                    }
                }
            }else{
                this.loadGranularItem(this.state.selectedTopic.subject_module_level_id)
                // this.loadTopic(this.state.selectedTopic.subject_module_level_id, false)
            }
            this.setState({assignForm: {
                username: '',
                due_date: moment(new Date()).format('YYYY-MM-DD')
            }, assigModal: false})
        })
        .catch((e)=>{
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    deleteTopic = (data, e) =>{
        this.setState({deleteConfirm: true, dataTobeRemoved: data})
    }

    processDeleteTopic = () =>{
        this.setState({removeLoading: true})
        let data = this.state.dataTobeRemoved
        fetch(process.env.REACT_APP_API_URL+'api/v1/adaptive/topics/delete/'+data.topic_id,{
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            }),
            method:'delete'
        })
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href='/'
            }
            if(res.status){
                if(data.parent_id !== null){
                    let is_called = false
                    let subTop = this.state.topics
                    let idx = subTop.assignments.findIndex(x=>x.topic_id === data.parent_id)
                    if(idx !== -1){
                        this.loadSubTopic(subTop.assignments[idx], false, true)
                        is_called = true
                    }else{
                        idx = subTop.data.findIndex(x=>x.topic_id === data.parent_id)
                        if(idx !== -1){
                            this.loadSubTopic(subTop.data[idx], false, true)
                            is_called = true
                        }
                    }
    
                    if(!is_called && this.state.subTopics.length > 0){
                        subTop = this.state.subTopics.length > 1 ? this.state.subTopics[this.state.subTopics.length - 2].data :
                        this.state.subTopics[this.state.subTopics.length - 1].data
                        idx = subTop.assignments.findIndex(x=>x.topic_id === data.parent_id)
                        if(idx !== -1){
                            this.loadSubTopic(subTop.assignments[idx], false, false)
                        }else{
                            idx = subTop.data.findIndex(x=>x.topic_id === data.parent_id)
                            if(idx !== -1){
                                this.loadSubTopic(subTop.data[idx], false, false)
                            }
                        }
                    }

                    this.setState({dataTobeRemoved: null, removeLoading: false, deleteConfirm: false})
                    toast.success('Topic deleted.')
                }else{
                    // this.loadTopic(data.subject_module_level_id, false)
                    // this.loadGranularItem(data.subject_module_level_id)
                    toast.success("Topic deleted.")
                    setTimeout(()=>{
                        this.setState({dataTobeRemoved: null, removeLoading: false, deleteConfirm: false})
                        window.location.reload()
                    }, 1000)
                }
            }else{
                toast.error('Cannot delete topic.')
                this.setState({removeLoading: false})
            }
        })
        .catch((e)=>{
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    markASDoneTopic = (data, e) =>{
        fetch(process.env.REACT_APP_API_URL+'api/v1/adaptive/assignments/finish/'+data.assignment.assignment_id,{
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            }),
        })
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href='/'
            }
            if(res.status){
                toast.success('Topic marked as done.')
                if(data.parent_id !== null){
                    let is_called = false
                    let subTop = this.state.topics
                    let idx = subTop.assignments.findIndex(x=>x.topic_id === data.parent_id)
                    if(idx !== -1){
                        this.loadSubTopic(subTop.assignments[idx], false, true)
                        is_called = true
                    }else{
                        idx = subTop.data.findIndex(x=>x.topic_id === data.parent_id)
                        if(idx !== -1){
                            this.loadSubTopic(subTop.data[idx], false, true)
                            is_called = true
                        }
                    }
    
                    if(!is_called && this.state.subTopics.length > 0){
                        subTop = this.state.subTopics.length > 1 ? this.state.subTopics[this.state.subTopics.length - 2].data :
                        this.state.subTopics[this.state.subTopics.length - 1].data
                        idx = subTop.assignments.findIndex(x=>x.topic_id === data.parent_id)
                        if(idx !== -1){
                            this.loadSubTopic(subTop.assignments[idx], false, false)
                        }else{
                            idx = subTop.data.findIndex(x=>x.topic_id === data.parent_id)
                            if(idx !== -1){
                                this.loadSubTopic(subTop.data[idx], false, false)
                            }
                        }
                    }
                }else{
                    this.loadGranularItem(data.subject_module_level_id)
                    // this.loadTopic(data.subject_module_level_id, false)
                }
            }else{
                toast.error('Cannot mark as done.')
            }

        })
        .catch((e)=>{
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    openExclude = (data, e) =>{
        this.loadExcludedMinigames(data.topic_id)
        fetch(process.env.REACT_APP_API_URL+'api/v1/adaptive/modules/'+data.subject_module_id+'/mini-games/excluded/'+data.topic_id,{
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            }),
        })
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href='/'
            }
            let opt = []
            res.data.forEach(x=>{
                opt.push({
                    key: x.minigame_id,
                    text: x.minigame_name,
                    value: x.minigame_id,
                    image: { avatar: true, src: x.icon }
                })
            })
            this.setState({minigamesExcludeable: opt, excludeModal: true, selectedTopic: data})
        })
        .catch((e)=>{
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    saveExcludedMinigame = () =>{
        let data = this.state.selectedTopic
        fetch(process.env.REACT_APP_API_URL+'api/v1/adaptive/topics/'+data.topic_id+'/excluded-mini-games/save',{
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            }),
            method: 'post',
            body: JSON.stringify({mini_game_id: this.state.selectedExcludeMG})
        })
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href='/'
            }
            if(res.status){
                if(data.parent_id !== null){
                    let is_called = false
                    let subTop = this.state.topics
                    let idx = subTop.assignments.findIndex(x=>x.topic_id === data.parent_id)
                    if(idx !== -1){
                        this.loadSubTopic(subTop.assignments[idx], false, true)
                        is_called = true
                    }else{
                        idx = subTop.data.findIndex(x=>x.topic_id === data.parent_id)
                        if(idx !== -1){
                            this.loadSubTopic(subTop.data[idx], false, true)
                            is_called = true
                        }
                    }
    
                    if(!is_called && this.state.subTopics.length > 0){
                        subTop = this.state.subTopics.length > 1 ? this.state.subTopics[this.state.subTopics.length - 2].data :
                        this.state.subTopics[this.state.subTopics.length - 1].data
                        idx = subTop.assignments.findIndex(x=>x.topic_id === data.parent_id)
                        if(idx !== -1){
                            this.loadSubTopic(subTop.assignments[idx], false, false)
                        }else{
                            idx = subTop.data.findIndex(x=>x.topic_id === data.parent_id)
                            if(idx !== -1){
                                this.loadSubTopic(subTop.data[idx], false, false)
                            }
                        }
                    }
                }else{
                    this.loadGranularItem(data.subject_module_level_id)
                    // this.loadTopic(data.subject_module_level_id, false)
                }
            }
            this.openExclude(this.state.selectedTopic, null)
        })
        .catch((e)=>{
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    deleteExcludedMinigame = (id, e) =>{
        let data = this.state.selectedTopic
        fetch(process.env.REACT_APP_API_URL+'api/v1/adaptive/topics/'+data.topic_id+'/excluded-mini-games/delete/'+id,{
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            }),
            method: 'delete'
        })
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href='/'
            }
            if(res.status){
                if(data.parent_id !== null){
                    let is_called = false
                    let subTop = this.state.topics
                    let idx = subTop.assignments.findIndex(x=>x.topic_id === data.parent_id)
                    if(idx !== -1){
                        this.loadSubTopic(subTop.assignments[idx], false, true)
                        is_called = true
                    }else{
                        idx = subTop.data.findIndex(x=>x.topic_id === data.parent_id)
                        if(idx !== -1){
                            this.loadSubTopic(subTop.data[idx], false, true)
                            is_called = true
                        }
                    }
    
                    if(!is_called && this.state.subTopics.length > 0){
                        subTop = this.state.subTopics.length > 1 ? this.state.subTopics[this.state.subTopics.length - 2].data :
                        this.state.subTopics[this.state.subTopics.length - 1].data
                        idx = subTop.assignments.findIndex(x=>x.topic_id === data.parent_id)
                        if(idx !== -1){
                            this.loadSubTopic(subTop.assignments[idx], false, false)
                        }else{
                            idx = subTop.data.findIndex(x=>x.topic_id === data.parent_id)
                            if(idx !== -1){
                                this.loadSubTopic(subTop.data[idx], false, false)
                            }
                        }
                    }
                }else{
                    this.loadGranularItem(data.subject_module_level_id)
                    // this.loadTopic(data.subject_module_level_id, false)
                }
            }
            this.openExclude(this.state.selectedTopic, null)
        })
        .catch((e)=>{
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    loadExcludedMinigames = (id) =>{
        fetch(process.env.REACT_APP_API_URL+'api/v1/adaptive/topics/'+id+'/excluded-mini-games',{
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            }),
        })
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href='/'
            }

            this.setState({minigamesExcluded: res.data})
        })
        .catch((e)=>{
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    onChangeChapterForm = (e, data) =>{
        let form = this.state.chapterForm
        switch (data.name) {
            case 'chapter_name':
                form.name = data.value
                break;
            default:
                form.granular = data.value
                break;
        }
        this.setState({chapterForm: form})
    }

    hideDestiny = (id=null) =>{
        id = id === null ? this.state.sourceData.subject_module_level_id : id
        this.loadGranularItem(id, null)
        this.setState({destinyModal: false, sourceData: null})
    }

    moveTopDownContent = () =>{
        alert('This feature is not ready yet!')
    }

    render(){
        return(
            <Segment>
                <div className='adaptiveDetailTitle'>
                    <span>
                        <Link to={'/subject/detail/'+this.props.match.params.code+'/content'}>
                        <Icon name='arrow left'/> Back to Module List
                        </Link>
                    </span>
                    <span>{this.state.moduleName}</span>
                </div>
                <Breadcrumb size='large' className='adaptBread'>
                    {this.state.breadcrumbs.map((v,k)=>
                    {
                        let comp = null
                        if(this.state.breadcrumbs.length-1 === k){
                            comp = <Breadcrumb.Section key={k} active>{v.name}</Breadcrumb.Section>
                        }else{
                            comp = <React.Fragment key={k}>
                                <Breadcrumb.Section active
                                >{v.name}</Breadcrumb.Section>
                                <Breadcrumb.Divider/>
                            </React.Fragment>
                        }
                        return comp
                    })}
                </Breadcrumb>
                <Segment className='detailAdaptiveSegment'>
                    <div className='levelContent'>
                        {this.state.loadingLevel ? <Loader active inline='centered' size='small'>loading...</Loader> : 
                        <Menu vertical fluid className='listLevel' size='massive'>
                            <span>Assigned to you</span>
                            {this.state.levels.assignments.length > 0 ? 
                                this.state.levels.assignments.map((v, k)=>{
                                    // return this.generateLevel(v, k)
                                    return <React.Fragment key={k}>
                                    <div className='granular'>
                                        <Menu.Item>
                                            Granular {k}
                                        </Menu.Item>
                                    </div>
                                    {v.map((x,y)=>{
                                        return this.generateLevel(x, y)
                                    })}
                                    </React.Fragment>
                                })
                            : <p style={{fontSize:'smaller'}}>No assignment for you...</p>}
                            <Divider className='assignSeparator'/>
                            
                            {this.state.levels.data.map((v, k)=>{
                                return <React.Fragment key={k}>
                                <div className='granular'>
                                    <Menu.Item>
                                        Granular {k}
                                    </Menu.Item>
                                </div>
                                {v.map((x,y)=>{
                                    return this.generateLevel(x, y)
                                })}
                                </React.Fragment>
                            })}
                        </Menu>
                        }
                        {this.state.currentSubject.role === 3 && this.state.currentSubject.code !== "english" ? 
                        <div>
                            <Button className='tertiary' onClick={()=>this.setState({assignLevelModal: true, is_edit_chapter: false, chapterForm: {name:''}})}>
                                <Icon name='add'/> Add Chapter
                            </Button>
                        </div>
                        : null}
                    </div>
                    {this.state.topics !== null ? 
                    <div className='levelContent'>
                        <Menu vertical fluid className='listLevel' size='massive'>
                            <span>Assigned to you</span>
                            {this.state.topics.assignments.length > 0 ? 
                                this.state.topics.assignments.map((v, k)=>{
                                    return this.generateTopic(v, k)
                                })
                            : <p style={{fontSize:'smaller'}}>No assignment for you...</p>}

                            <Divider className='assignSeparator'/>
                            
                            {this.state.topics.data.map((v, k)=>{
                                return this.generateTopic(v, k)
                            })}
                        </Menu>
                        {this.state.currentTopic === 0 && this.state.currentSubject.role === 3 ? 
                            <div>
                                <Button className='tertiary' 
                                onClick={()=>this.setState({topicModal: true, isSubTopic: false, isUpdateTopic: false})}>
                                    <Icon name='add'/> Add New Topic
                                </Button>
                            </div>
                        : null}
                    </div>
                    : null}
                    
                    {this.state.subTopics.map((val, key)=>
                        <div className='levelContent' key={key}>
                            <Menu vertical fluid className='listLevel' size='massive'>
                                <span>Assigned to you</span>
                                {val.data.assignments.length > 0 ? 
                                    val.data.assignments.map((v, k)=>{
                                        return this.generateTopic(v, k)
                                    })
                                : <p style={{fontSize:'smaller'}}>No assignment for you...</p>}

                                <Divider className='assignSeparator'/>
                                
                                {val.data.data.map((v, k)=>{
                                    return this.generateTopic(v, k)
                                })}
                            </Menu>
                            {this.state.currentTopic === val.level && this.state.currentSubject.role === 3 ? 
                                <div>
                                    <Button className='tertiary' 
                                    onClick={()=>this.setState({topicModal: true, isSubTopic: true, isUpdateTopic: false})}>
                                        <Icon name='add'/> Add New Topic
                                    </Button>
                                </div>
                            : null}
                        </div>
                    )}
                </Segment>

                {/* modal */}
                <Modal size='mini' open={this.state.assignLevelModal}>
                    <Modal.Header>Manage Chapter</Modal.Header>
                    <Modal.Content className='levelContent'>
                        <Form>
                            {!this.state.is_chapter_move ? 
                            <Form.Input placeholder='Eg : Conversation' label='Chapter Name' value={this.state.chapterForm.name}
                            name='chapter_name' onChange={this.onChangeChapterForm}/>
                            : null}
                            
                            {!this.state.is_edit_chapter ? 
                            <Form.Select options={this.state.granularList} label='Granular Group' defaultValue={1}
                            name='chapter_granular' onChange={this.onChangeChapterForm}/>
                            : this.state.is_chapter_move ? 
                            <Form.Select options={this.state.granularList} label='Granular Group' defaultValue={1}
                            name='chapter_granular' onChange={this.onChangeChapterForm}/>
                            : null}
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={()=>this.setState({assignLevelModal: false, chapterForm: {name:''}})}
                        disabled={this.state.assignLevelLoading}>Cancel</Button>
                        <Button primary onClick={this.saveLevel}
                        disabled={this.state.assignLevelLoading} loading={this.state.assignLevelLoading}>Add</Button>
                    </Modal.Actions>
                </Modal>

                <Modal size='mini' open={this.state.topicModal}>
                    <Modal.Header>Topic</Modal.Header>
                    <Modal.Content>
                        <Form>
                            <Form.Input placeholder='Type here...' label='Topic Name' 
                            value={this.state.topicForm.name} name='name' onChange={this.topicOnChange}/>
                            {!this.state.isUpdateTopic ? 
                            <Form.Checkbox label='Has sub topics?' name='has_sub' 
                            onChange={this.topicOnChange} checked={this.state.topicForm.has_sub_topic}/> : null}
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={()=>this.setState({topicModal: false, topicForm:{
                            name: '',
                            has_sub_topic: false
                        }})} disabled={this.state.addTopicLoading}>Cancel</Button>
                        <Button primary onClick={this.addNewTopic}
                        disabled={this.state.addTopicLoading} loading={this.state.addTopicLoading}>Save</Button>
                    </Modal.Actions>
                </Modal>

                <Modal size='mini' open={this.state.assigModal}>
                    <Modal.Header>Assigment</Modal.Header>
                    <Modal.Content>
                        <Form>
                            <Form.Dropdown options={this.state.contributors} 
                            selection label='Contributor' onChange={(e, data)=>{
                                let form = this.state.assignForm
                                form.username = data.value
                                this.setState({assignForm: form})
                            }}/>
                            <div className='field'>
                            <label>Due Date</label>
                            <DateInput
                                placeholder='Due Date'
                                minDate={new Date()}
                                popupPosition='right center'
                                duration={10}
                                hideMobileKeyboard
                                value={this.state.assignForm.due_date}
                                iconPosition='right'
                                preserveViewMode={false}
                                autoComplete='off'
                                dateFormat='YYYY-MM-DD'
                                closable
                                onChange={(event, {name, value})=>{
                                    let form = this.state.assignForm
                                    form.due_date = value
                                    this.setState({assignForm: form})
                                }}
                            />
                        </div>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={()=>this.setState({assigModal: false, assignForm: {
                            username: '',
                            due_date: moment(new Date()).format('YYYY-MM-DD')
                        }})} disabled={this.state.assignTopicLoading}>Cancel</Button>
                        <Button primary onClick={this.assignTopic}
                        disabled={this.state.assignTopicLoading} loading={this.state.assignTopicLoading}>Save</Button>
                    </Modal.Actions>
                </Modal>

                <Modal size='tiny' open={this.state.excludeModal} onClose={()=>this.setState({excludeModal: false})}
                closeIcon>
                    <Modal.Header>Exclude Minigames</Modal.Header>
                    <Modal.Content scrolling>
                        <Grid stackable columns='equal'>
                            <Grid.Column>
                                <Dropdown style={{width: '100%'}} onChange={(e, data)=>this.setState({selectedExcludeMG: data.value})}
                                selection options={this.state.minigamesExcludeable}/>
                            </Grid.Column>
                            <Grid.Column width={5}>
                                <Button negative onClick={this.saveExcludedMinigame}>Exclude</Button>
                            </Grid.Column>
                        </Grid>
                        <Divider/>
                        <Table celled>
                            <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Mg No</Table.HeaderCell>
                                <Table.HeaderCell>Name</Table.HeaderCell>
                                <Table.HeaderCell>Action</Table.HeaderCell>
                            </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {this.state.minigamesExcluded.map((v,k)=>
                                <Table.Row key={k}>
                                    <Table.Cell>{v.mini_game_id}</Table.Cell>
                                    <Table.Cell>{v.mini_game_name}</Table.Cell>
                                    <Table.Cell>
                                        <Button size='mini' onClick={this.deleteExcludedMinigame.bind(null, v.excluded_id)}>Remove</Button>
                                    </Table.Cell>
                                </Table.Row>
                                )}
                            </Table.Body>
                        </Table>
                    </Modal.Content>
                    <Modal.Actions>
                        {/* <Button onClick={()=>this.setState({excludeModal: false})}>Cancel</Button> */}
                        <Button primary onClick={()=>this.setState({excludeModal: false})}>Finish</Button>
                    </Modal.Actions>
                </Modal>
                
                <DestinationFolderComponent destinyModal={this.state.destinyModal} hideDestiny={this.hideDestiny} closeDestiny={()=>this.setState({destinyModal: false, sourceData: null})}
                auth={this.props.auth.user.token} id={this.props.match.params.mid} subject={this.props.match.params.code}
                sourceData={this.state.sourceData}/>


                <Modal size='small' open={this.state.deleteConfirm}>
                    <Modal.Content style={{padding: 20, paddingBottom: 50}}>
                        <div className='publishModal'>
                            <div>
                                <Icon name='exclamation triangle' circular/>
                            </div>
                            <div className='content'>
                                <h3>Are you sure you want to delete?</h3>
                                <p>
                                    <b>You cannot undo this action.</b> This {typeof this.state.dataTobeRemoved === "object" ? "topic" : "chapter"} will be deleted immediately from Content+ and removed permanently from the game.
                                </p>
                            </div>
                        </div>
                        <Divider hidden/>
                        <Button onClick={()=>this.setState({deleteConfirm: false, dataTobeRemoved: null})} 
                        floated='right' primary disabled={this.state.removeLoading}>Cancel</Button>
                        {typeof this.state.dataTobeRemoved === "object" ? 
                        <Button onClick={this.processDeleteTopic} disabled={this.state.removeLoading}
                        loading={this.state.removeLoading} floated='right' secondary>Yes</Button>
                        : <Button onClick={this.processDeleteChapter} disabled={this.state.removeLoading}
                        loading={this.state.removeLoading} floated='right' secondary>Yes</Button>}
                    </Modal.Content>
                </Modal>
            </Segment>
        )
    }
}

export default connect(mapStateToProps)(DetailAdaptive)