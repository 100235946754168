import React from 'react'
import { Input, Textarea } from '../fields'
import { Image, Button, Divider, Icon, Grid, Header, Popup } from 'semantic-ui-react'
import ImageCropperComponent from '../../components/image_cropper'
import ContentEditable from 'react-contenteditable'
import sanitizeHtml from "sanitize-html"
import { toast } from 'react-toastify'
import TooltipVariableComponent from '../../components/tooltip_variable'

// module 7 form
class Module7Form extends React.Component{
    constructor(props){
        super(props)
        this.contentEditableanswer1 = React.createRef();
        this.contentEditableanswer2 = React.createRef();
        this.contentEditableanswer3 = React.createRef();
        this.contentEditableanswer4 = React.createRef();
        this.state = {
            image: null,
            modalOpen: false,
            answer1: '',
            answer1Length: 150,
            errorAnswer1: '',
            answer1Width: 0,
            showAnswer1: false,
            showVarError1: false,
            countAnswer1: 1,
            max_char: 30,

            answer2: '',
            answer2Length: 150,
            errorAnswer2: '',
            answer2Width: 0,
            showAnswer2: false,
            showVarError2: false,
            countAnswer2: 1,

            answer3: '',
            answer3Length: 150,
            errorAnswer3: '',
            answer3Width: 0,
            showAnswer3: false,
            showVarError3: false,
            countAnswer3: 1,

            answer4: '',
            answer4Length: 150,
            errorAnswer4: '',
            answer4Width: 0,
            showAnswer4: false,
            showVarError4: false,
            countAnswer4: 1,

            maxAnswerLength: 150
        }
    }

    // set editable content fix width & set form data when edit
    componentDidMount(){
        let data = this.props.data
        if(data !== undefined){
            this.countWord(data.question_object.round1.answer, 'answer1')
            this.countWord(data.question_object.round2.answer, 'answer2')
            this.countWord(data.question_object.round3.answer, 'answer3')
            this.countWord(data.question_object.round4.answer, 'answer4')
            this.setState({
                answer1: data.question_object.round1.answer,
                answer2: data.question_object.round2.answer,
                answer3: data.question_object.round3.answer,
                answer4: data.question_object.round4.answer
            })
        }

        setTimeout(()=>{
            this.setState({
                answer1Width: this.contentEditableanswer1.current.clientWidth,
                answer2Width: this.contentEditableanswer2.current.clientWidth,
                answer3Width: this.contentEditableanswer3.current.clientWidth,
                answer4Width: this.contentEditableanswer4.current.clientWidth,
            })
        }, 0)
    }

    // sanitize html
    sanitizeConf = {
        allowedTags: ["a"],
        allowedAttributes: { a: ["class"] }
    };

    fileInput = null

    // process the image after attached
    onChangeImage = (e)=>{
        e.preventDefault();
        let files;

        if(e.target.files[0] === undefined){
            return false
        }

        if(e.target.files[0].type !== 'image/jpeg'){
            toast.error('Please choose correct format image.')
            return false
        }

        if(e.target.files[0].size > 5000000){
            toast.error('Image should be equal to or less than 5MB.')
            return false
        }
        
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        
        const reader = new FileReader();
        reader.onload = () => {
            this.setState({image: reader.result, modalOpen: true})
        };
        reader.readAsDataURL(files[0]);
    }

    // set image value after crop
    setImageDisplay = (src) =>{
        this.fileInput.value = null
        if(src === null){
            this.setState({image: null, modalOpen: false})
            return false
        }
        this.setState({image: src, modalOpen: false})
    }

    // handle editable content
    handleChange = evt => {
        let html = sanitizeHtml(evt.target.value, this.sanitizeConf)
        let varData = html.match(/<a [^>]+>(.*?)<\/a>/g)
        let valueText = html
        if(varData !== null){
            varData.forEach(val=>{
                let extractWord = /<a [^>]+>(.*?)<\/a>/g.exec(val)
                extractWord[1] = extractWord[1].replace('&nbsp;', ' ')
                extractWord[1] = extractWord[1].replace('&amp;', '&')
                extractWord[1] = extractWord[1].replace('&lt;', '<')
                extractWord[1] = extractWord[1].replace('&gt;', '>')
                if(extractWord[1].length > this.state.max_char){
                    valueText = valueText.replace(extractWord.input, '<a class="ui green label">'+extractWord[1].slice(0,this.state.max_char)+'</a>')
                }
            })
        }

        html = valueText

        switch (evt.currentTarget.id) {
            case 'content_answer1':
                this.countWord(html, 'answer1')
                this.setState({ answer1: html});
                break;
            case 'content_answer2':
                this.countWord(html, 'answer2')
                this.setState({answer2: html});
                break;
            case 'content_answer3':
                this.countWord(html, 'answer3')
                this.setState({answer3: html});
                break;
            default:
                this.countWord(html, 'answer4')
                this.setState({answer4: html});
                break;
        }
    }

    // handle editable content kye press
    handleKeyDown = (e) =>{
        switch (e.currentTarget.id) {
            case 'content_answer1':
                let html = this.state.answer1
                if(e.which === 13) {
                    html = html+ '&nbsp;'
                    html = sanitizeHtml(html, this.sanitizeConf)
                    this.setState({answer1: html})
                    e.preventDefault()
                }

                if(e.which === 192){
                    html = html+ `<a class="ui green label"></a>`
                    html = sanitizeHtml(html, this.sanitizeConf)
                    this.setState({answer1: html})
                    e.preventDefault()
                }
                break;
            case 'content_answer2':
                let html2 = this.state.answer2
                if(e.which === 13) {
                    html2 = html2+ '&nbsp;'
                    html2 = sanitizeHtml(html2, this.sanitizeConf)
                    this.setState({answer2: html2})
                    e.preventDefault()
                }

                if(e.which === 192){
                    html2 = html2+ `<a class="ui green label"></a>`
                    html2 = sanitizeHtml(html2, this.sanitizeConf)
                    this.setState({answer2: html2})
                    e.preventDefault()
                }
                break;
            case 'content_answer3':
                let html3 = this.state.answer3
                if(e.which === 13) {
                    html3 = html3+ '&nbsp;'
                    html3 = sanitizeHtml(html3, this.sanitizeConf)
                    this.setState({answer3: html3})
                    e.preventDefault()
                }

                if(e.which === 192){
                    html3 = html3+ `<a class="ui green label"></a>`
                    html3 = sanitizeHtml(html3, this.sanitizeConf)
                    this.setState({answer3: html3})
                    e.preventDefault()
                }
                break;
            default:
                let html4 = this.state.answer4
                if(e.which === 13) {
                    html4 = html4+ '&nbsp;'
                    html4 = sanitizeHtml(html4, this.sanitizeConf)
                    this.setState({answer4: html4})
                    e.preventDefault()
                }

                if(e.which === 192){
                    html4 = html4+ `<a class="ui green label"></a>`
                    html4 = sanitizeHtml(html4, this.sanitizeConf)
                    this.setState({answer4: html4})
                    e.preventDefault()
                }
                break;
        }
    }

    // count editable content
    countWord = (p, type) =>{
        let val = p
        val = sanitizeHtml(val, this.sanitizeConf)
        let list = val.split('<a class="ui green label">')
        let words = []
        list.forEach(ed => {
            ed = ed.trim()
            ed = ed.replace('</a>', '')
            words.push(ed)
        });

        let kl = words.join(' ')
        let textLength = this.state.maxAnswerLength
        kl = kl.replace(/^\s+|\s+$/g, "").replace(/\s+/g, "")
        kl = kl.replace(/&amp;/g, '&')
        kl = kl.replace(/&lt;/g, '<')
        kl = kl.replace(/&gt;/g, '>')

        let varData = p.match(/<a [^>]+>(.*?)<\/a>/g)
        switch (type) {
            case 'answer1':
                this.setState({answer1Length: kl.length === 0 ? textLength : 
                    textLength - kl.length, countAnswer1: varData !== null ? varData.length : 0});
                break;
            case 'answer2':
                this.setState({answer2Length: kl.length === 0 ? textLength : 
                    textLength - kl.length, countAnswer2: varData !== null ? varData.length : 0});
                break;
            case 'answer3':
                this.setState({answer3Length: kl.length === 0 ? textLength : 
                    textLength - kl.length, countAnswer3: varData !== null ? varData.length : 0});
                break;
            default:
                this.setState({answer4Length: kl.length === 0 ? textLength : 
                    textLength - kl.length, countAnswer4: varData !== null ? varData.length : 0});
                break;
        }
    }

    // add tag/value on editable content
    addTag = (type, e) =>{
        switch (type) {
            case 'answer1':
                let hint = ''
                hint = this.state.answer1
                let inp = document.getElementById('i_answer1').value
                if(inp.trim() === ''){
                    return false
                }
                if(inp.length > this.state.max_char){
                    this.setState({showVarError1: true})
                    return false
                }
                hint += `<a class="ui green label">`+inp+`</a>&nbsp;`
                this.setState({answer1: hint, showAnswer1: false, showVarError1: false})
                this.contentEditableanswer1.current.focus();
                setTimeout(()=>{
                    this.countWord(hint, 'answer1')
                }, 0)
                break;
            case 'answer2':
                let hint2 = ''
                hint2 = this.state.answer2
                let inp2 = document.getElementById('i_answer2').value
                if(inp2.trim() === ''){
                    return false
                }
                if(inp2.length > this.state.max_char){
                    this.setState({showVarError2: true})
                    return false
                }
                hint2 += `<a class="ui green label">`+inp2+`</a>&nbsp;`
                this.setState({answer2: hint2, showAnswer2: false, showVarError2: false})
                this.contentEditableanswer2.current.focus();
                setTimeout(()=>{
                    this.countWord(hint2, 'answer2')
                }, 0)
                break;
            case 'answer3':
                let hint3 = ''
                hint3 = this.state.answer3
                let inp3 = document.getElementById('i_answer3').value
                if(inp3.trim() === ''){
                    return false
                }
                if(inp3.length > this.state.max_char){
                    this.setState({showVarError3: true})
                    return false
                }
                hint3 += `<a class="ui green label">`+inp3+`</a>&nbsp;`
                this.setState({answer3: hint3, showAnswer3: false, showVarError3: false})
                this.contentEditableanswer3.current.focus();
                setTimeout(()=>{
                    this.countWord(hint3, 'answer3')
                }, 0)
                break;
            default:
                let hint4 = ''
                hint4 = this.state.answer4
                let inp4 = document.getElementById('i_answer4').value
                if(inp4.trim() === ''){
                    return false
                }
                if(inp4.length > this.state.max_char){
                    this.setState({showVarError4: true})
                    return false
                }
                hint4 += `<a class="ui green label">`+inp4+`</a>&nbsp;`
                this.setState({answer4: hint4, showAnswer4: false, showVarError4: false})
                this.contentEditableanswer4.current.focus();
                setTimeout(()=>{
                    this.countWord(hint4, 'answer4')
                }, 0)
                break;
        }
    }

    render() {
        let answer1Error = ''
        let answer1El = document.getElementById('answer1')
        answer1El = answer1El !== null ? document.getElementById('answer1').getAttribute('class') : ''
        if(answer1El.includes('error')){
            answer1Error = ' error'
        }

        let answer2Error = ''
        let answer2El = document.getElementById('answer2')
        answer2El = answer2El !== null ? document.getElementById('answer2').getAttribute('class') : ''
        if(answer2El.includes('error')){
            answer2Error = ' error'
        }

        let answer3Error = ''
        let answer3El = document.getElementById('answer3')
        answer3El = answer3El !== null ? document.getElementById('answer3').getAttribute('class') : ''
        if(answer3El.includes('error')){
            answer3Error = ' error'
        }

        let answer4Error = ''
        let answer4El = document.getElementById('answer4')
        answer4El = answer4El !== null ? document.getElementById('answer4').getAttribute('class') : ''
        if(answer4El.includes('error')){
            answer4Error = ' error'
        }



        let answer1Var = true
        if(this.state.answer1 !== '' && !sanitizeHtml(this.state.answer1, this.sanitizeConf).includes('</a>')){
            answer1Var = false
        }

        let answer2Var = true
        if(this.state.answer2 !== '' && !sanitizeHtml(this.state.answer2, this.sanitizeConf).includes('</a>')){
            answer2Var = false
        }

        let answer3Var = true
        if(this.state.answer3 !== '' && !sanitizeHtml(this.state.answer3, this.sanitizeConf).includes('</a>')){
            answer3Var = false
        }

        let answer4Var = true
        if(this.state.answer4 !== '' && !sanitizeHtml(this.state.answer4, this.sanitizeConf).includes('</a>')){
            answer4Var = false
        }

        const wrongAnswerInfo = <Popup trigger={<Icon name='question circle' color='blue' style={{fontSize: 'small'}} link/>} content={
            <span>Wrong input must be different with <b>"Correct Answer"</b> and cannot be duplicate with other wrong input on each round!</span>
        }/>
        return(
            <>
                <td>
                    <Image style={{width: 100, height: 100}} size='small' src={this.state.image !== null ? this.state.image : require('../../assets/images/default.png')}/>
                    <Input required value={this.state.image} type='hidden' name="image"/>
                    <input style={{display:'none'}} accept="image/jpeg" ref={(file)=>this.fileInput = file} id='q_image' 
                    type="file" onChange={this.onChangeImage}/>
                    <Popup trigger={
                        <Button type='button' size='mini' secondary onClick={()=>{
                            this.setState({image: null})
                            this.fileInput.click()
                        }}>Upload Image</Button>
                    } content='Image size (Max: 5MB)'></Popup>
                    <ImageCropperComponent open={this.state.modalOpen} loading={false} imgSrc={this.state.image} fileInput={this.fileInput} 
                    ratio={1 / 1} act={this.setImageDisplay}/>
                </td>
                {this.props.is_adaptive !== undefined ? 
                <td>
                    <Input name="topic" label='Topic' required placeholder='Type here...' wlength={70}/>
                </td>
                : null}
                <td style={this.state.answer1Width !== 0 ? {width: this.state.answer1Width} : null}>
                    <Textarea label='Question' name="question1" required placeholder='Type here...' wlength={150}/>
                    <div className='field'>
                        <label>Answer <b style={{color:'#db2828'}}> * </b>
                        <TooltipVariableComponent open={false}/>
                        </label>
                        <ContentEditable style={this.state.answer1Width !== 0 ? {width: this.state.answer1Width} : null} 
                        className={'editableContent'+ answer1Error}
                            innerRef={this.contentEditableanswer1}
                            html={this.state.answer1}
                            onChange={this.handleChange}
                            onKeyDown={this.handleKeyDown}
                            placeholder={'Type here...'}
                            id='content_answer1'
                        />
                        <span style={this.state.answer1Length < 0 ? {color: '#db2828', textAlign: 'right', float: 'right'} : 
                        {textAlign: 'right', float: 'right'}}>{this.state.answer1Length}</span>
                        
                        <Textarea input_error={this.state.answer1Length < 0 || !answer1Var ? true : false} 
                        values={this.state.answer1} hidden={true} name="answer1" required/>

                        {this.state.showAnswer1 ? 
                        <Grid columns='equal'>
                            <Grid.Column width={10}>
                                <Popup content={'Max characters is '+this.state.max_char} trigger={
                                    <div className='field'>
                                        <input id='i_answer1' placeholder='Type correct answer...' onKeyDown={(e)=>{
                                            let v1 = document.getElementById('i_answer1').value
                                            this.setState({showVarError1: v1.length > this.state.max_char ? true : false})
                                            if (e.key === 'Enter' || e.key === 'Tab') {
                                                if(v1.length <= this.state.max_char){
                                                    this.addTag('answer1')
                                                }
                                            }
                                        }}/>
                                        {this.state.showVarError1 ? 
                                        <div className="ui red pointing label">Your input is more than {this.state.max_char} characters</div>
                                        : null}
                                    </div>
                                }/>
                                
                            </Grid.Column>
                            <Grid.Column>
                                <Button className='tertiary' size='mini' type='button' icon onClick={this.addTag.bind(null, 'answer1')} disabled={this.state.showVarError1}>
                                    <Icon color='green' name='check'/>
                                </Button>
                                <Button className='tertiary' size='mini' type='button' icon onClick={()=>this.setState({showAnswer1: false})}>
                                    <Icon color='grey' name='remove'/>
                                </Button>
                            </Grid.Column>
                        </Grid> : 
                        <Button type='button' secondary size='mini' disabled={
                            sanitizeHtml(this.state.answer1, this.sanitizeConf).includes('</a>')? true : false
                        }
                        onClick={()=>{
                            this.setState({showAnswer1: true})
                            setTimeout(()=>{
                                document.getElementById('i_answer1').focus()
                            }, 100)
                        }}>
                            Add correct answer.
                        </Button>}
                    </div>
                    <Divider hidden/>
                    <Header color='red' as='h5'>Wrong Answers {wrongAnswerInfo}</Header>
                    <Input name="a1_wrong1" required placeholder='Exp: are' wlength={30}/>
                    <Input name="a1_wrong2" required placeholder='Exp: are' wlength={30}/>
                    <Input name="a1_wrong3" required placeholder='Exp: are' wlength={30}/>
                </td>
                <td style={this.state.answer2Width !== 0 ? {width: this.state.answer2Width} : null}>
                    <Textarea label='Question' name="question2" required placeholder='Type here...' wlength={150}/>
                    <div className='field'>
                        <label>Answer <b style={{color:'#db2828'}}> * </b>
                        <TooltipVariableComponent open={false}/>
                        </label>
                        <ContentEditable style={this.state.answer2Width !== 0 ? {width: this.state.answer2Width} : null} 
                        className={'editableContent'+ answer2Error}
                            innerRef={this.contentEditableanswer2}
                            html={this.state.answer2}
                            onChange={this.handleChange}
                            onKeyDown={this.handleKeyDown}
                            placeholder={'Type here...'}
                            id='content_answer2'
                        />
                        <span style={this.state.answer2Length < 0 ? {color: '#db2828', textAlign: 'right', float: 'right'} : 
                        {textAlign: 'right', float: 'right'}}>{this.state.answer2Length}</span>
                        
                        <Textarea input_error={this.state.answer2Length < 0 || !answer2Var ? true : false} 
                        values={this.state.answer2} hidden={true} name="answer2" required/>

                        {this.state.showAnswer2 ? 
                        <Grid columns='equal'>
                            <Grid.Column width={10}>
                                <Popup content={'Max characters is '+this.state.max_char} trigger={
                                    <div className='field'>
                                        <input id='i_answer2' placeholder='Type correct answer...' onKeyDown={(e)=>{
                                            let v2 = document.getElementById('i_answer2').value
                                            this.setState({showVarError2: v2.length > this.state.max_char ? true : false})
                                            if (e.key === 'Enter' || e.key === 'Tab') {
                                                if(v2.length <= this.state.max_char){
                                                    this.addTag('answer2')
                                                }
                                            }
                                        }}/>
                                        {this.state.showVarError2 ? 
                                        <div className="ui red pointing label">Your input is more than {this.state.max_char} characters</div>
                                        : null}
                                    </div>
                                }/>
                            </Grid.Column>
                            <Grid.Column>
                                <Button className='tertiary' size='mini' type='button' icon onClick={this.addTag.bind(null, 'answer2')} disabled={this.state.showVarError2}>
                                    <Icon color='green' name='check'/>
                                </Button>
                                <Button className='tertiary' size='mini' type='button' icon onClick={()=>this.setState({showAnswer2: false})}>
                                    <Icon color='grey' name='remove'/>
                                </Button>
                            </Grid.Column>
                        </Grid> : 
                        <Button type='button' secondary size='mini' disabled={
                            sanitizeHtml(this.state.answer2, this.sanitizeConf).includes('</a>')? true : false
                        }
                        onClick={()=>{
                            this.setState({showAnswer2: true})
                            setTimeout(()=>{
                                document.getElementById('i_answer2').focus()
                            }, 100)
                            }}>
                            Add correct answer.
                        </Button>}
                    </div>
                    <Divider hidden/>
                    <Header color='red' as='h5'>Wrong Answers {wrongAnswerInfo}</Header>
                    <Input name="a2_wrong1" required placeholder='Exp: are' wlength={30}/>
                    <Input name="a2_wrong2" required placeholder='Exp: are' wlength={30}/>
                    <Input name="a2_wrong3" required placeholder='Exp: are' wlength={30}/>
                </td>
                <td style={this.state.answer3Width !== 0 ? {width: this.state.answer3Width} : null}>
                    <Textarea label='Question' name="question3" required placeholder='Type here...' wlength={150}/>
                    <div className='field'>
                        <label>Answer <b style={{color:'#db2828'}}> * </b>
                        <TooltipVariableComponent open={false}/>
                        </label>
                        <ContentEditable style={this.state.answer3Width !== 0 ? {width: this.state.answer3Width} : null} 
                        className={'editableContent'+ answer3Error}
                            innerRef={this.contentEditableanswer3}
                            html={this.state.answer3}
                            onChange={this.handleChange}
                            onKeyDown={this.handleKeyDown}
                            placeholder={'Type here...'}
                            id='content_answer3'
                        />
                        <span style={this.state.answer3Length < 0 ? {color: '#db2828', textAlign: 'right', float: 'right'} : 
                        {textAlign: 'right', float: 'right'}}>{this.state.answer3Length}</span>
                        
                        <Textarea input_error={this.state.answer3Length < 0 || !answer3Var ? true : false} 
                        values={this.state.answer3} hidden={true} name="answer3" required/>

                        {this.state.showAnswer3 ? 
                        <Grid columns='equal'>
                            <Grid.Column width={10}>
                                <Popup content={'Max characters is '+this.state.max_char} trigger={
                                    <div className='field'>
                                        <input id='i_answer3' placeholder='Type correct answer...' onKeyDown={(e)=>{
                                            let v3 = document.getElementById('i_answer3').value
                                            this.setState({showVarError3: v3.length > this.state.max_char ? true : false})
                                            if (e.key === 'Enter' || e.key === 'Tab') {
                                                if(v3.length <= this.state.max_char){
                                                    this.addTag('answer3')
                                                }
                                            }
                                        }}/>
                                        {this.state.showVarError3 ? 
                                        <div className="ui red pointing label">Your input is more than {this.state.max_char} characters</div>
                                        : null}
                                    </div>
                                }/>
                            </Grid.Column>
                            <Grid.Column>
                                <Button className='tertiary' size='mini' type='button' icon onClick={this.addTag.bind(null, 'answer3')} disabled={this.state.showVarError3}>
                                    <Icon color='green' name='check'/>
                                </Button>
                                <Button className='tertiary' size='mini' type='button' icon onClick={()=>this.setState({showAnswer3: false})}>
                                    <Icon color='grey' name='remove'/>
                                </Button>
                            </Grid.Column>
                        </Grid> : 
                        <Button type='button' secondary size='mini' disabled={
                            sanitizeHtml(this.state.answer3, this.sanitizeConf).includes('</a>')? true : false
                        }
                        onClick={()=>{
                            this.setState({showAnswer3: true})
                            setTimeout(()=>{
                                document.getElementById('i_answer3').focus()
                            }, 100)
                        }}>
                            Add correct answer.
                        </Button>}
                    </div>
                    <Divider hidden/>
                    <Header color='red' as='h5'>Wrong Answers {wrongAnswerInfo}</Header>
                    <Input name="a3_wrong1" required placeholder='Exp: are' wlength={30}/>
                    <Input name="a3_wrong2" required placeholder='Exp: are' wlength={30}/>
                    <Input name="a3_wrong3" required placeholder='Exp: are' wlength={30}/>
                </td>
                <td style={this.state.answer4Width !== 0 ? {width: this.state.answer4Width} : null}>
                    <Textarea label='Question' name="question4" required placeholder='Type here...' wlength={150}/>
                    <div className='field'>
                        <label>Answer <b style={{color:'#db2828'}}> * </b>
                        <TooltipVariableComponent open={false}/>
                        </label>
                        <ContentEditable style={this.state.answer4Width !== 0 ? {width: this.state.answer4Width} : null} 
                        className={'editableContent'+ answer4Error}
                            innerRef={this.contentEditableanswer4}
                            html={this.state.answer4}
                            onChange={this.handleChange}
                            onKeyDown={this.handleKeyDown}
                            placeholder={'Type here...'}
                            id='content_answer4'
                        />
                        <span style={this.state.answer4Length < 0 ? {color: '#db2828', textAlign: 'right', float: 'right'} : 
                        {textAlign: 'right', float: 'right'}}>{this.state.answer4Length}</span>
                        
                        <Textarea input_error={this.state.answer4Length < 0 || !answer4Var ? true : false} 
                        values={this.state.answer4} hidden={true} name="answer4" required/>

                        {this.state.showAnswer4 ? 
                        <Grid columns='equal'>
                            <Grid.Column width={10}>
                                <Popup content={'Max characters is '+this.state.max_char} trigger={
                                    <div className='field'>
                                        <input id='i_answer4' placeholder='Type correct answer...' onKeyDown={(e)=>{
                                            let v4 = document.getElementById('i_answer4').value
                                            this.setState({showVarError4: v4.length > this.state.max_char ? true : false})
                                            if (e.key === 'Enter' || e.key === 'Tab') {
                                                if(v4.length <= this.state.max_char){
                                                    this.addTag('answer4')
                                                }
                                            }
                                        }}/>
                                        {this.state.showVarError4 ? 
                                        <div className="ui red pointing label">Your input is more than {this.state.max_char} characters</div>
                                        : null}
                                    </div>
                                }/>
                            </Grid.Column>
                            <Grid.Column>
                                <Button className='tertiary' size='mini' type='button' icon onClick={this.addTag.bind(null, 'answer4')} disabled={this.state.showVarError4}>
                                    <Icon color='green' name='check'/>
                                </Button>
                                <Button className='tertiary' size='mini' type='button' icon onClick={()=>this.setState({showAnswer4: false})}>
                                    <Icon color='grey' name='remove'/>
                                </Button>
                            </Grid.Column>
                        </Grid> : 
                        <Button type='button' secondary size='mini' disabled={
                            sanitizeHtml(this.state.answer4, this.sanitizeConf).includes('</a>')? true : false
                        }
                        onClick={()=>{
                            this.setState({showAnswer4: true})
                            setTimeout(()=>{
                                document.getElementById('i_answer4').focus()
                            }, 100)
                        }}>
                            Add correct answer.
                        </Button>}
                    </div>
                    <Divider hidden/>
                    <Header color='red' as='h5'>Wrong Answers {wrongAnswerInfo}</Header>
                    <Input name="a4_wrong1" required placeholder='Exp: are' wlength={30}/>
                    <Input name="a4_wrong2" required placeholder='Exp: are' wlength={30}/>
                    <Input name="a4_wrong3" required placeholder='Exp: are' wlength={30}/>
                </td>
            </>
        )
    }
}

export default Module7Form