import React, { useState, useEffect } from 'react';
import { Grid, Icon, Menu, Button, Header, Card, Divider, Popup, Loader } from 'semantic-ui-react';
import { FormProvider, Form } from 'react-advanced-form';
import { Link } from 'react-router-dom';
import messages from '../../forms/subject/validate_message';
import { connect } from 'react-redux';
import AddSubjectForm from '../../forms/subject/add';
import storage from 'redux-persist/lib/storage';
import { toast } from 'react-toastify';
import CoverImageSecton from './create_subject_section/cover_image';
import PrerequisiteSection from './create_subject_section/prerequisite';
import {getSubjectRedux, filterSubjectRedux} from '../../helpers/redux';

// to detect every store of redux/state changed
function mapStateToProps(state) {
    return { 
        add_subject_step: state.add_subject_step,
        auth: state.auth,
        preq_subject_selected: state.preq_subject_selected
    }
}

// to detect every store of redux/state changed
function mapDispatchToProps(dispatch) {
    return { 
        setPreqSubjectSelected: (n) => dispatch({ type: 'SELECTED_PREQ_SUBJECT', payload: n }),
        setPreqSubject: (n) => dispatch({type: 'PREQ_SUBJECT', payload: n}),
        navAction: (n) => dispatch({type: 'CHANGE_NAV', payload: n}),
        addSubjectStepAction: (n) => dispatch({type: 'CURRENT_SUBJECT', payload: n}),
    }
}

// add new subject page component
const AddSubjectPage = (props) =>{
    let url_string = window.location.href
    let url = new URL(url_string)
    let code_param = url.searchParams.get("code")
    let stepList = [
        {name: 'Basic Information', done: false},
        {name: 'Choose Category', done: false},
        {name: 'Prerequisite Subject', done: false},
        {name: 'Cover Image', done: false}
    ]
    const [currentSubject, setCurrentSubject] = useState(code_param !== null ? getSubjectRedux(props.add_subject_step, code_param) : null)
    const [activeStep, setStep] = useState(0)
    const [categories, setCategory] = useState([])
    const [subCategories, setSubCategory] = useState([])
    const [steps, setCurrentStep] = useState([])
    const [saveLoading, setSaveLoading] = useState(false)
    const [loading, setLoading] = useState(true)
    const userInfo = props.auth
    const myHeaders = new Headers({
        'Authorization': 'Token '+userInfo.user.token
    });
    const [form, setForm] = useState(null)
    useEffect(()=>{
        if(currentSubject !== null){
            loadCategories(currentSubject.name, currentSubject.description)
        }else if(code_param !== null){
            fetch(process.env.REACT_APP_API_URL+'api/v1/subjects/'+code_param, {
                headers: myHeaders,
            }).then(function(res){
                return res.json()
            }).then(function(res){
                if(res.status !== undefined){
                    if(res.status){
                        let cs = res.data
                        if(cs.category_id !== null){
                            window.location.href='/subject/detail/'+code_param+'/content'
                        }else{
                            setUpListStep(cs)
                            setCurrentSubject(cs)
                            props.addSubjectStepAction(filterSubjectRedux(props.add_subject_step, cs))
                            loadCategories(cs.name, cs.description)
                        }
                    }
                }else{
                    storage.removeItem('persist:root')
                    window.location.href='/'
                }
            })
        }else{
            let subject_storage = window.localStorage.getItem('add_subject')
            subject_storage = JSON.parse(subject_storage)
            setUpListStep(subject_storage)
            if(subject_storage !== null){
                setCurrentSubject(subject_storage)
                props.addSubjectStepAction(filterSubjectRedux(props.add_subject_step, subject_storage))
                loadCategories(subject_storage.name, subject_storage.description)
            }
            setLoading(false)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const setUpListStep = (crData) =>{
        let stepListTemp = stepList
        if(crData !== null){
            if(crData.name !== null){
                stepListTemp[0].done = true
            }
            if(crData.category_id !== null){
                stepListTemp[1].done = true
            }
            if(crData.pre_requisite !== null){
                stepListTemp[2].done = true
            }
            if(crData.image !== null){
                stepListTemp[3].done = true
            }
            setCurrentStep(stepListTemp)
            let idx = stepListTemp.findIndex(x=>!x.done)
            if(idx !== -1){
                setStep(idx)
            }
        }else{
            if(steps.length === 0){
                setCurrentStep(stepList)
            }
        }
    }

    // fetch categories for add new subject
    const loadCategories = (name, desc) =>{
        fetch(process.env.REACT_APP_API_URL+'api/v1/categories/menu', {
            headers: myHeaders,
            method:'post',
            body: JSON.stringify({
                subject_title: name,
                subject_desc: desc
            })
        }).then(function(response) {
            return response.json()
        }).then(function(response) {
            setLoading(false)
            if(response.status !== undefined){
                let data = response.data
                let idx = 0
                response.data.forEach(function(value){
                    data[idx].active = false
                    for (let index = 0; index < value.sub_list.length; index++) {
                        data[idx].sub_list[index].active = false
                    }
                    idx++
                })
                setCategory(data)
            }else{
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
        }).catch(function() {
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }
    
    // send post request to save subject information (name, description)
    const saveSubject = ({ serialized }) =>{
        setSaveLoading(true)
        if(currentSubject !== null){
            serialized.code = currentSubject.code
        }
        fetch(process.env.REACT_APP_API_URL+'api/v1/subjects/save/', {
            method: 'post',
            headers: myHeaders,
            body: JSON.stringify(serialized)
        }).then(function(response) {
            loadCategories(serialized.name, serialized.description)
            return response.json()
        }).then(function(response) {
            setSaveLoading(false)
            if(response.status !== undefined){
                if(response.status){
                    saveLocalStorage(response.data)
                    setCurrentSubject(response.data)
                    props.addSubjectStepAction(filterSubjectRedux(props.add_subject_step, response.data))
                    let data = steps
                    data[0].done = true
                    changeStep(1)
                    setCurrentStep([...data])
                }else{
                    toast.error(response.message)
                }
            }else{
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
        }).catch(function(e) {
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
        return new Promise((resolve) => resolve())
    }

    // set category active when one of them selected
    const selectCategory = (id, type, subid=0) =>{
        if(type === 'category'){
            let data = categories
            let catIdx = categories.findIndex(x=>x.id === id)
            if(catIdx !== -1){
                data[catIdx].active = true
                let catOther = data.filter(x=>x.id !== id)
                for (let index = 0; index < catOther.length; index++) {
                    catOther[index].active = false
                }
                setCategory([...data])
                setSubCategory([...data[catIdx].sub_list])
            }
        }else if(type === 'both'){
            let data = categories
            let catIdx = categories.findIndex(x=>x.id === id)
            if(catIdx !== -1){
                data[catIdx].active = true
                let catOther = data.filter(x=>x.id !== id)
                for (let index = 0; index < catOther.length; index++) {
                    catOther[index].active = false
                }
                let subidx = data[catIdx].sub_list.findIndex(x=>x.id === subid)
                data[catIdx].sub_list[subidx].active = true
                setCategory([...data])
                setSubCategory([...data[catIdx].sub_list])
            }
        }else{
            let data = subCategories
            let catIdx = subCategories.findIndex(x=>x.id === id)
            if(catIdx !== -1){
                data[catIdx].active = true
                let catOther = data.filter(x=>x.id !== id)
                for (let index = 0; index < catOther.length; index++) {
                    catOther[index].active = false
                }
                setSubCategory([...data])
            }
        }
    }

    // change indicator and step when go back and go to next step
    const changeStep = (param) =>{
        if(param === 1){
            if(!steps[0].done){
                return false
            }
            if(currentSubject !== null && currentSubject.category_id !== null){
                if(currentSubject.category_parent_id !== null){
                    selectCategory(currentSubject.category_parent_id, 'both', currentSubject.category_id)
                }else{
                    selectCategory(currentSubject.category_id, 'category')
                }
            }
        }else if(param === 2){
            if(!steps[1].done){
                return false
            }
        }
        else if(param === 3){
            if(!steps[2].done){
                return false
            }
        }
        setStep(param)
    }

    // when click save and continue button to process the action
    const saveContinue = () =>{
        if(activeStep === 0){
            form.submit()
        }else if(activeStep === 1){
            let catId = 0
            let selectedCat = categories.filter(x=>x.active)
            if(selectedCat.length > 0){
                selectedCat = selectedCat[0]
                if(selectedCat.sub_list.length > 0){
                    let selectedSubCat = selectedCat.sub_list.filter(x=>x.active)
                    catId = selectedSubCat.length > 0 ? selectedSubCat[0].id : selectedCat.id
                }else{
                    catId = selectedCat.id
                }
            }
            if(catId !== 0){
                setSaveLoading(true)
                fetch(process.env.REACT_APP_API_URL+'api/v1/subjects/save/', {
                    method: 'post',
                    headers: myHeaders,
                    body: JSON.stringify({code: currentSubject.code, category: catId})
                }).then(function(response) {
                    return response.json()
                }).then(function(response) {
                    setSaveLoading(false)
                    if(response.status !== undefined){
                        if(response.status){
                            saveLocalStorage(response.data)
                            setCurrentSubject(response.data)
                            props.addSubjectStepAction(filterSubjectRedux(props.add_subject_step, response.data))
                            let data = steps
                            data[1].done = true
                            changeStep(2)
                            setCurrentStep([...data])
                        }else{
                            toast.error(response.message)
                        }
                    }else{
                        storage.removeItem('persist:root')
                        window.location.href = '/'
                    }
                }).catch(function() {
                    toast.error(<div>
                        Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
                    </div>)
                })
            }else{
                toast.error('Please select the category.')
                return false;
            }
        }else if(activeStep === 2){
            setSaveLoading(true)
            let data = props.preq_subject_selected
            if(data.length === 0){
                toast.error('Please complete prerequisite detail.')
                setSaveLoading(false)
                return false;
            }
            let isError = false
            data.forEach(val => {
                let level = val.levels.findIndex(x=>x.selected)
                if(level === -1 && !val.not_sure){
                    isError = true
                }
            });

            if(isError){
                toast.error('Please check your level detail.')
                setSaveLoading(false)
                return false;
            }else{
                let params = []

                data.forEach(val => {
                    let lvl = ''
                    let lvlIdx = val.levels.findIndex(x=>x.selected)
                    lvl = lvlIdx !== -1 ? val.levels[lvlIdx].text : 'not sure'
                    params.push({
                        subject_code: val.code,
                        is_required: val.is_required,
                        level: lvl
                    })
                });

                fetch(process.env.REACT_APP_API_URL+'api/v1/subjects/save/', {
                    method: 'post',
                    headers: myHeaders,
                    body: JSON.stringify({code: currentSubject.code, prerequisite: JSON.stringify(params)})
                }).then((res)=>{
                    return res.json()
                }).then((res)=>{
                    setSaveLoading(false)
                    if(res.status === undefined){
                        storage.removeItem('persist:root')
                        window.location.href = '/'
                    }

                    if(res.status){
                        saveLocalStorage(res.data)
                        setCurrentSubject(res.data)
                        props.addSubjectStepAction(filterSubjectRedux(props.add_subject_step, res.data))
                        let data = steps
                        data[2].done = true
                        changeStep(3)
                        setCurrentStep([...data])
                        toast.success('Prerequisite has been set.')
                    }else{
                        toast.error(res.message)
                    }
                }).catch(function() {
                    toast.error(<div>
                        Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
                    </div>)
                })
            }
        }
    }

    // skip button for prerequisite section
    const skipPre = () =>{
        if(currentSubject.pre_requisite !== null || currentSubject.pre_requisite !== ''){
            fetch(process.env.REACT_APP_API_URL+'api/v1/subjects/save/', {
                method: 'post',
                headers: myHeaders,
                body: JSON.stringify({code: currentSubject.code, prerequisite: null})
            }).then((res)=>{
                return res.json()
            }).then((res)=>{
                if(res.status === undefined){
                    storage.removeItem('persist:root')
                    window.location.href = '/'
                }
    
                if(res.status){
                    saveLocalStorage(res.data)
                    setCurrentSubject(res.data)
                    steps[2].done = true
                    changeStep(3)
                }else{
                    toast.error(res.message)
                }
            }).catch(function(e) {
                toast.error(<div>
                    Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
                </div>)
            })
        }else{
            steps[2].done = true
            changeStep(3)
        }
    }

    const saveLocalStorage = (data) =>{
        window.localStorage.setItem('add_subject', JSON.stringify(data))
    }

    // to render each section based on active step index
    const renderSection = (index) =>{
        let component = {}
        switch (index) {
            case 0:
                component = <FormProvider messages={messages}>
                    <Form id='subject_form' action={saveSubject} className='ui form' ref={el => setForm(el)}>
                        <AddSubjectForm currentSubject={currentSubject}/>
                    </Form>
                </FormProvider>
                break;
            case 1:
                component = <div>
                    <Header as='h4' color='grey'>Choose A Category for “{currentSubject.name}” 
                    <Popup trigger={<Icon style={{fontSize:14, position:'absolute'}} name='question outline circle' color='grey' link/>}
                    content="Choose a category that your subject falls under. Subjects can be browsed based on category within the game."
                    basic/>
                    </Header>
                    <i style={{fontSize:12, color:'grey'}}>
                        <Icon name='star'/> Suggested Categories
                    </i>
                    <Card.Group itemsPerRow={5}>
                        {categories.map((v,k)=>
                            <Card onClick={selectCategory.bind(null, v.id, 'category')} key={k} className={'subject_category'+(v.active ? ' active' : '')}>
                                <span><Icon name={v.icon}/> {v.name}</span>
                                {v.is_suggested ? <Icon size='small' name='star' color='grey' style={{position:'absolute', right:0, margin:5}}/> : ''}
                            </Card>)}
                    </Card.Group>
                    <Divider hidden/>
                    {subCategories.length > 0 ? 
                        <div>
                            <Header as='h4' color='grey'>Choose Sub-Category for “{currentSubject.name}” 
                            <Popup trigger={<Icon style={{fontSize:14, position:'absolute'}} name='question outline circle' color='grey' link/>}
                            content="Choose a sub-category for your subject. Subjects can be browsed based on sub-category within the game."
                            basic/>
                            </Header>
                            <i style={{fontSize:12, color:'grey'}}>
                                <Icon name='star'/> Suggested Sub-Categories
                            </i>
                            <Card.Group itemsPerRow={5}>
                                {subCategories.map((v,k)=>
                                <Card onClick={selectCategory.bind(null, v.id, 'subcategory')} key={k} className={'subject_category'+(v.active ? ' active' : '')}>
                                    <span><Icon name={v.icon}/> {v.name}</span>
                                    {v.is_suggested ? <Icon size='small' name='star' color='grey' style={{position:'absolute', right:0, margin:5}}/> : ''}
                                </Card>)}
                            </Card.Group>
                        </div> 
                    : ''}
                </div>
                break;
            case 2:
                component = <PrerequisiteSection code={currentSubject.code} myHeaders={myHeaders} {...props}/>
                break;
            default:
                component = <CoverImageSecton currentSubject={currentSubject} myHeaders={myHeaders} {...props}/>
                break;
        }
        return component
    }

    const saveAsDraft = (type = null, e) =>{
        props.setPreqSubjectSelected([])
        props.setPreqSubject([])
        window.localStorage.removeItem('add_subject')
        if(type === null){
            props.navAction('subject')
        }
    }

    return(
        <>
        {loading ? <Loader active/> : 
        <Grid columns='equal'>
            <Grid.Column width={4}>
                <Menu vertical className='add_subject'>
                    {steps.map((v,k)=><div key={k} className={'subject item'+(activeStep === k ? ' active' : '')}
                    onClick={changeStep.bind(null, k)}>
                        <div className={'round'+(v.done ? ' active' : '')}>
                            {v.done ? <Icon name='check'/> : k+1}
                        </div>
                        {v.name}
                    </div>)}
                </Menu>
            </Grid.Column>
            <Grid.Column>
                <div className="ui segments">
                    <div className="ui segment segment__header">
                        <div className="ui grid">
                            <div className="two columns row">
                                <div className="middle aligned column">
                                    <Icon.Group size='large'>
                                    <Icon name='folder' />
                                    <Icon corner name='add' />
                                    </Icon.Group>
                                    Create New Subject
                                </div>
                                <div className="right aligned column">
                                    <Link onClick={saveAsDraft.bind(null)} to='/subject' 
                                    className='ui button tertiary'>
                                        {activeStep > 0 ? 'Save as a draft' : 'Cancel, go back to my subject'}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ui segment">
                        {renderSection(activeStep)}
                    </div>
                    <div className="ui right aligned segment segment__footer">
                        {activeStep === 1 ? 
                            <Button floated='left' onClick={changeStep.bind(null, 0)}
                            className='ui button tertiary'><Icon name='arrow left'/> Basic Infomation</Button> 
                        : ''}

                        {activeStep === 2 ? 
                            <Button floated='left' onClick={changeStep.bind(null, 1)}
                            className='ui button tertiary'><Icon name='arrow left'/> Choose Category</Button> 
                        : ''}

                        {activeStep === 3 ? 
                            <Button floated='left' onClick={changeStep.bind(null, 2)}
                            className='ui button tertiary'><Icon name='arrow left'/> Prerequisite Subject</Button> 
                        : ''}

                        {activeStep === 2 && props.preq_subject_selected.length === 0 ? 
                            <Button onClick={skipPre} className='ui button tertiary' id='btn_skip'>Skip</Button> 
                        : ''}

                        {activeStep < 3 ? 
                            <Button disabled={saveLoading} loading={saveLoading} primary onClick={saveContinue} id="add_subject_btn">
                            Save & Continue <Icon name='arrow right'/>
                            </Button> 
                        : 
                            <Link id='btn_create_subject' onClick={saveAsDraft.bind(null, 'create')} to={'/subject/detail/'+currentSubject.code+'/content'} 
                            className='ui button positive'>Create Subject</Link>}
                    </div>
                </div>
            </Grid.Column>
        </Grid>
        }
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(AddSubjectPage)