import React from "react";
import { Breadcrumb, Icon, Segment, Grid, Loader } from "semantic-ui-react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ChapterSteps from "./sections/steps";
import ChapterSection from "./sections/chapter";
import TeachingFormatSection from "./sections/teaching_format";
import MinigameSection from "./sections/minigame";
import ContentReviewSection from "./sections/content_review";
import CompletionPercentageSection from "./sections/completion_percentage";
import { getSubjectRedux } from "../../../helpers/redux";
import storage from 'redux-persist/lib/storage';
import { toast } from "react-toastify";

// to detect every store of redux/state changed
function mapStateToProps(state) {
    return { 
        add_subject_step: state.add_subject_step,
        chapter_step_active: state.chapter_step_active,
        auth: state.auth
    }
}

// to detect every store of redux/state changed
function mapDispatchToProps(dispatch) {
    return { 
        setStepActiveIndex: (n) => dispatch({ type: 'CHAPTER_INDEX_ACTIVE', payload: n }),
        setChapterRequestData: (n) => dispatch({ type: 'CHAPTER_REQUEST_DATA', payload: n })
    }
}

// add new chapter
class AddChapterPage extends React.Component{
    constructor(props){
        super(props)
        const subjectDetail = getSubjectRedux(this.props.add_subject_step, this.props.match.params.code)
        this.state = {
            myHeaders: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            }),
            currentSubject: subjectDetail,
            loading: true
        }
    }

    componentDidMount(){
        window.onbeforeunload = function(e) {
            return "Changes you made may not be saved.";
        };
        
        if(this.state.currentSubject === null){
            this.loadSubject()
        }else{
            this.setState({loading: false})
        }
    }

    loadSubject = () =>{
        fetch(process.env.REACT_APP_API_URL+'api/v1/subjects/'+this.props.match.params.code, {
            headers: this.state.myHeaders
        })
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href='/'
            }
            let subjectDetail = res.data
            if(subjectDetail.role !== 3){
                window.location.href = '/subject'
                return false
            }
            this.setState({currentSubject: res.data, loading: false})
        })
        .catch((e)=>{
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    render() {
        let chapterIndex = this.props.chapter_step_active
        let components = {}
        chapterIndex = !chapterIndex ? 1 : chapterIndex
        switch (chapterIndex) {
            case 1:
                components = <ChapterSection subject={this.state.currentSubject}/>
                break;
            case 2:
                components = <CompletionPercentageSection/>
                break;
            case 3:
                components = <TeachingFormatSection myHeaders={this.state.myHeaders}/>
                break;
            case 4:
                components = <MinigameSection myHeaders={this.state.myHeaders}/>
                break;
            default:
                components = <ContentReviewSection myHeaders={this.state.myHeaders} subject={this.state.currentSubject}/>
            break;
        }
        return (
            <>
            {this.state.loading ? <Loader active/> : 
            <div>
                <Grid columns='equal'>
                    <Grid.Column>
                        <Breadcrumb>
                            <Breadcrumb.Section>
                                <Icon name='folder' color='grey'/>
                                <Link style={{color: 'grey', textDecoration:'underline'}} 
                                to={'/subject/detail/'+this.state.currentSubject.code+'/content'}>
                                    {this.state.currentSubject.name}
                                </Link>
                            </Breadcrumb.Section>
                            <Breadcrumb.Divider icon='right chevron' />
                            <Breadcrumb.Section active>Add Chapter</Breadcrumb.Section>
                        </Breadcrumb>
                    </Grid.Column>
                    <Grid.Column>
                        <Link to={'/subject/detail/'+this.state.currentSubject.code+'/content'} 
                        style={{float:'right', color:'#2196f3'}}>cancel, go back to subject dashboard</Link>
                    </Grid.Column>
                </Grid>

                <ChapterSteps/>
                
                <Segment style={{marginBottom: 20}}>
                    {components}
                </Segment>
            </div>
            }
            </>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddChapterPage)