import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Grid, Tab, Divider, Button, Icon, Modal, Header, Segment, MenuItem } from 'semantic-ui-react'
import AddSubjectForm from '../../../forms/subject/add'
import { FormProvider, Form } from 'react-advanced-form';
import messages from '../../../forms/subject/validate_message';
import { toast } from 'react-toastify'
import storage from 'redux-persist/lib/storage'
import CoverImageSecton from "../create_subject_section/cover_image";
import PrerequisiteSection from "../create_subject_section/prerequisite";
import { filterSubjectRedux, getSubjectRedux } from '../../../helpers/redux'

// subject setting page
const SettingPage = (props) =>{
    const subjectDetail = getSubjectRedux(props.add_subject_step, props.match.params.code)
    const userInfo = props.auth
    const [form, setForm] = useState(null)
    const [showSaveBtn, setShowSaveBtn] = useState(true)
    const [confirmOpen, setConfirmOpen] = useState(false)
    const [loadingButton, setLoadingButton] = useState(false)
    const [tabIndex, setTabIndex] = useState(0)

    // check the prerequisite data of subject
    useEffect(()=>{
        if(subjectDetail.pre_requisite === null || subjectDetail.pre_requisite === ''){
            props.setPreqSubjectSelected([])
            props.setPreqSubject([])
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const myHeaders = new Headers({
        'Authorization': 'Token '+userInfo.user.token
    });

    // if the subject not complete will redirect to subject form
    if(subjectDetail.category_id === null && subjectDetail.role === 3 && !subjectDetail.adaptive){
        return <Redirect to={'/subject/add?code='+props.match.params.code}/>
    }

    // save subject information
    const saveSubject = ({ serialized }) =>{
        setLoadingButton(true)
        serialized.code = subjectDetail.code
        fetch(process.env.REACT_APP_API_URL+'api/v1/subjects/save/', {
            method: 'post',
            headers: myHeaders,
            body: JSON.stringify(serialized)
        }).then(function(response) {
            return response.json()
        }).then(function(response) {
            setLoadingButton(false)
            if(response.status !== undefined){
                if(response.status){
                    toast.success(response.message)
                    props.addSubjectStepAction(filterSubjectRedux(props.add_subject_step, response.data))
                    setTimeout(()=>{
                        window.location.href = ''
                    }, 1000)
                }else{
                    toast.error(response.message)
                }
            }else{
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
        })
        return new Promise((resolve) => resolve())
    }

    // tab panes data
    const panes = [
        { menuItem: <MenuItem id='b_info'>Basic Information</MenuItem>, render: () => 
            <FormProvider messages={messages}>
                <Form id='subject_form' action={saveSubject} className='ui form' ref={el => setForm(el)}>
                    <AddSubjectForm currentSubject={subjectDetail}/>
                </Form>
            </FormProvider>
        },
        { menuItem: <MenuItem id='c_image'>Cover Image</MenuItem>, render: () => 
            <CoverImageSecton currentSubject={subjectDetail} myHeaders={myHeaders} {...props}/>
        },
        { menuItem: <MenuItem id='p_subject'>Prerequisite Subject</MenuItem>, render: () => 
            <PrerequisiteSection code={props.match.params.code} myHeaders={myHeaders} {...props}/>
        },
    ]

    // handle tab change
    const handleTabChange = (event, data) =>{
        if(data.activeIndex === 1){
            setShowSaveBtn(false)
        }else{
            setShowSaveBtn(true)
        }
        setTabIndex(data.activeIndex)
    }

    // when delete subject button
    const handleDeleteSubject = () =>{
        fetch(process.env.REACT_APP_API_URL+'api/v1/subjects/delete/'+subjectDetail.code, {
            method: 'delete',
            headers: myHeaders
        }).then(function(response) {
            setConfirmOpen(false)
            return response.json()
        }).then(function(response) {
            if(response.status !== undefined){
                if(response.status){
                    toast.success('Subject deleted.')
                }else{
                    toast.error(response.message)
                }
                setTimeout(()=>{
                    window.location.href='/subject'
                }, 2000)
            }else{
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
        })
    }

    // save changes for subject info and prerequisite
    const saveChanges = () =>{
        if(tabIndex === 0){
            form.submit()
        }else if(tabIndex === 2){
            let data = props.preq_subject_selected
            if(data.length === 0){
                toast.error('Please complete prerequisite detail.')
                return false;
            }
            let isError = false
            data.forEach(val => {
                let level = val.levels.findIndex(x=>x.selected)
                if(level === -1 && !val.not_sure){
                    isError = true
                }
            });
    
            if(isError){
                toast.error('Please check your level detail.')
                return false;
            }else{
                let params = []
    
                data.forEach(val => {
                    let lvl = ''
                    let lvlIdx = val.levels.findIndex(x=>x.selected)
                    lvl = lvlIdx !== -1 ? val.levels[lvlIdx].text : 'not sure'
                    params.push({
                        subject_code: val.code,
                        is_required: val.is_required,
                        level: lvl
                    })
                });
    
                fetch(process.env.REACT_APP_API_URL+'api/v1/subjects/save/', {
                    method: 'post',
                    headers: myHeaders,
                    body: JSON.stringify({code: subjectDetail.code, prerequisite: JSON.stringify(params)})
                }).then((res)=>{
                    return res.json()
                }).then((res)=>{
                    if(res.status === undefined){
                        storage.removeItem('persist:root')
                        window.location.href = '/'
                    }
    
                    if(res.status){
                        props.addSubjectStepAction(filterSubjectRedux(props.add_subject_step, res.data))
                        toast.success('Prerequisite has been set.')
                    }else{
                        toast.error(res.message)
                    }
                }).catch(function() {
                    toast.error(<div>
                        Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
                    </div>)
                })
            }
        }
    }

    return(
        <Segment>
            <Tab onTabChange={handleTabChange} menu={{ vertical: true, secondary: true }} panes={panes} />
            <Grid columns='equal'>
                <Grid.Column width={4} style={{position: 'absolute',top: 150}}>
                    <Divider/>
                    <Button onClick={()=>setConfirmOpen(true)} style={{color:'#F44336'}} className='tertiary'>
                        <Icon name='trash'/> Delete this subject
                    </Button>
                </Grid.Column>
                <Grid.Column>
                    {showSaveBtn ? <Button disabled={loadingButton} loading={loadingButton} 
                    onClick={saveChanges} floated='right' primary id='save_setting_btn'>
                        <Icon name='save'/> Save Changes
                    </Button> : ''}
                </Grid.Column>
            </Grid>

            {/* modal confirm */}
            <Modal open={confirmOpen} basic size='small'>
                <Header icon='archive' content='Delete Confirmation' />
                <Modal.Content>
                    <p style={{color:'#FFF'}}>Are you sure want to delete this subject?</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button secondary onClick={()=>setConfirmOpen(false)}>No</Button>
                    <Button primary onClick={handleDeleteSubject}>Yes</Button>
                </Modal.Actions>
            </Modal>
        </Segment>

    )
}

// to detect every store of redux/state changed
const mapStateToProps = (state) =>{
    return{
        add_subject_step: state.add_subject_step,
        auth: state.auth,
        preq_subject_selected: state.preq_subject_selected,

    }
}

// to detect every store of redux/state changed
const mapDispatchToProps = (dispatch) =>{
    return { 
        setPreqSubjectSelected: (n) => dispatch({ type: 'SELECTED_PREQ_SUBJECT', payload: n }),
        setPreqSubject: (n) => dispatch({type: 'PREQ_SUBJECT', payload: n}),
        addSubjectStepAction: (n) => dispatch({type: 'CURRENT_SUBJECT', payload: n}),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingPage)