import React from "react";
import { connect } from "react-redux";
import { 
    Loader, Card, Header, Button, 
    Icon, Container, Divider, Modal, Dropdown, Tab
} from "semantic-ui-react";
import { toast } from "react-toastify";
import storage from 'redux-persist/lib/storage';
import ReactPlayer from 'react-player'
import { PlatformInit } from "../../../../helpers/global";
import EmptyComponent from "../../../../components/empty";

// to detect every store of redux/state changed
function mapStateToProps(state) {
    return { 
        chapter_step_active: state.chapter_step_active,
        chapter_request_data: state.chapter_request_data
    }
}

// to detect every store of redux/state changed
function mapDispatchToProps(dispatch) {
    return { 
        setStepActiveIndex: (n) => dispatch({ type: 'CHAPTER_INDEX_ACTIVE', payload: n }),
        setChapterRequestData: (n) => dispatch({ type: 'CHAPTER_REQUEST_DATA', payload: n })
    }
}

// display list of miniagames based on module/teaching format
class MinigameSection extends React.Component{
    constructor(props){
        super(props)
        let tF = this.props.chapter_request_data.teaching_format
        let tfSelect = []
        tF.forEach(x=>{
            tfSelect.push({
                text: x.teaching_material_name,
                value: x.module_id,
                image: { avatar: false, src: x.logo }
            })
        })
        this.state = {
            mgCategorize: {},
            loading: true,
            videoUrl: [],
            modalOpen: false,
            teachingFormat: tfSelect,
            platform: this.props.chapter_request_data.platforms,
            selectedTF: this.props.chapter_request_data.module_id,
            selectedPlatform: this.props.chapter_request_data.platforms.filter(x=>x.active).map(x=>{
                return x.value
            }).join(',')
        }
    }

    // fetch minigame data
    componentDidMount(){
        let moduleId = this.props.chapter_request_data.module_id
        if(moduleId !== null){
            this.loadData()
        }
    }

    loadData = () =>{
        let url = process.env.REACT_APP_API_URL+'api/v1/modules/'+this.state.selectedTF+'/minigames'
        url = this.state.selectedPlatform !== '' ? url+'?platform='+this.state.selectedPlatform : url
        this.setState({loading: true})
        fetch(url, {
            headers: this.props.myHeaders
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
            let mg = {}
            res.data.forEach(val =>{
                if(mg[val.module_name] !== undefined){
                    mg[val.module_name].push(val)
                }else{
                    mg[val.module_name] = [val]
                }
            })

            this.setState({loading: false, mgCategorize: mg})
        }).catch(()=>{
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    // back to teaching format
    backToTitleStep = () =>{
        this.props.setStepActiveIndex(3)
    }

    // choose minigame
    chooseMinigame = (mg, e) =>{
        let chapterRequestData = this.props.chapter_request_data
        chapterRequestData.minigame_id = mg.minigame_id
        chapterRequestData.module_id_game = mg.module_id
        chapterRequestData.mg_name = mg.minigame_name
        chapterRequestData.platforms = this.state.platform
        chapterRequestData.module_id = this.state.selectedTF
        this.props.setChapterRequestData(chapterRequestData)
        this.props.setStepActiveIndex(5)
    }

    filterPlatform = (e, data) =>{
        let platform = this.state.platform
        platform = platform.filter(x=>x.value !== '').map(x=>{
            if(x.value === data.id){
                x.active = !x.active
            }
            return x
        })
        let selected = platform.filter(x=>x.active).map(x=>{
            return x.value
        }).join(',')

        this.setState({platform: platform, selectedPlatform: selected})
        setTimeout(()=>{
            this.loadData()
        }, 100)
    }

    teachingFormatChange = (e, data) =>{
        if(this.state.selectedTF !== data.value){
            this.setState({selectedTF: data.value})
            setTimeout(()=>{
                this.loadData()
            }, 100)
        }
    }

    openVideo = (data, e) =>{
        let tabPlatform = []
        PlatformInit().forEach(x=>{
            tabPlatform.push({
                menuItem: x.text,
                render: () => <Tab.Pane attached={false}>
                    {data.video[x.value] !== undefined && data.video[x.value] !== '' ? 
                    <ReactPlayer width={'100%'} url={data.video[x.value]}/>
                    : <EmptyComponent/>}
                </Tab.Pane>
            })
        })
        this.setState({modalOpen: true, videoUrl: tabPlatform})
    }

    generateMGCard = (v, k) =>{
        let idx = 0
        let img = ''
        Object.keys(v.screenshot).forEach(x=>{
            if(idx === 0){
                img = v.screenshot[x]
            }
            idx++
        })
        return (
            <Card raised key={k}>
                <div></div>
                <div className='mgImage' 
                style={{background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.25) 100%),url('+img[0]+')'}}></div>
                <div className='mgIcon' style={{background: 'url('+v.icon+') no-repeat center'}}></div>
                <Card.Content>
                    <Header as='h3'>
                        {v.minigame_name} {v.is_doc ? <Icon color='blue' circular name='gamepad'/> : null}{v.is_chatbot ? <Icon circular color='blue' name='telegram'/> : null}
                    </Header>
                </Card.Content>
                <Card.Description style={{padding:10}}>
                    <p>{v.description}</p>
                </Card.Description>
                <div className='mgAction'>
                    <Button className='tertiary' onClick={this.openVideo.bind(null, v)}>
                        <Icon name='play'/> Play Video
                    </Button>
                    <Button id={'btn_choose_'+v.minigame_id} primary onClick={this.chooseMinigame.bind(null, v)}>
                        Choose
                    </Button>
                </div>
            </Card>
        )
    }

    render() {
        return (
            <div>
                <Container textAlign='center'>
                    <Header as='h2' style={{color: 'rgba(3, 28, 48, 0.65)'}}>Choose one from the following minigame templates.</Header>
                    <p>You can add more minigames for your chapter later.</p>
                    <Dropdown placeholder='Teaching Format' search 
                    defaultValue={this.props.chapter_request_data.module_id}
                    selection options={this.state.teachingFormat || []} onChange={this.teachingFormatChange}/>
                    <Button.Group className='platformSwitch' style={{marginLeft: 10}}>
                        {this.state.platform.map((v,k)=>
                        <Button key={k} secondary id={v.value} active={v.active}
                        onClick={this.filterPlatform}>
                            <Icon name={v.icon}/> {v.text}
                        </Button>
                        )}
                    </Button.Group>
                </Container>
                <Divider hidden/>
                {this.state.loading ? 
                    <Loader active/>
                    : Object.keys(this.state.mgCategorize).length > 0 ? 
                    Object.keys(this.state.mgCategorize).map((item, k)=>
                        <div key={k}>
                        <Divider hidden/>
                        <Header as='h2' color='grey'>
                            {item}
                        </Header>
                        <Card.Group className='minigameCard' itemsPerRow={3}>
                            {this.state.mgCategorize[item].map((v,k)=>
                                {
                                    return this.generateMGCard(v, k)
                                }
                            )}
                        </Card.Group>
                        </div>
                    )
                    : <EmptyComponent/> 
                }
                <Divider hidden/>
                <Button className='tertiary' onClick={this.backToTitleStep}>
                    <Icon name='arrow left'/> Back to choose the other teaching format
                </Button>

                {/* video modal will displayed when play video clicked */}
                <Modal open={this.state.modalOpen}  closeIcon onClose={()=>this.setState({modalOpen: false})}>
                    <Modal.Header>Minigame Video</Modal.Header>
                    <Modal.Content>
                        <Tab menu={{ secondary: true }} panes={this.state.videoUrl} />
                    </Modal.Content>
                </Modal>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MinigameSection)