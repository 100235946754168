import React from 'react'
import { Header, Segment, Divider, Button, Icon } from 'semantic-ui-react'
import { Form } from 'react-advanced-form'
import messages from '../../forms/question/validation_message'
import CompletionProfileForm from '../../forms/profile/completion'
import {connect} from 'react-redux'
import storage from 'redux-persist/lib/storage'
import {toast} from 'react-toastify'

// to detect every store of redux/state changed
function mapStateToProps(state) {
    return { 
        auth: state.auth
    }
}

// to detect every store of redux/state changed
function mapDispatchToProps(dispatch) {
    return { 
        loginAction: (n) => {
            let state = {
                is_logged: true,
                user: n
            }
            dispatch({ type: 'LOGIN', payload: state })
        },
        navAction: (n) => dispatch({ type: 'CHANGE_NAV', payload: n })
    }
}

class CompletionProfilePage extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            phone_code: '',
            timezone: '',
            loading: false
        }
    }

    form = null
    saveProfile = ({serialized}) =>{
        let param = {
            first_name: serialized.f_name,
            last_name: serialized.l_name,
            phone_number: this.state.phone_code+serialized.phone,
            birthday: serialized.b_date,
            timezone: this.state.timezone,
            short_bio: serialized.short_bio,
            password: serialized.password,
            contact_info: {
                web: serialized.w_url !== undefined ? serialized.w_url : '',
                linkedin: serialized.linkedin !== undefined ? serialized.linkedin : '',
                twitter: serialized.twitter !== undefined ? serialized.twitter : '',
                facebook: serialized.facebook !== undefined ? serialized.facebook : '',
                instagram: serialized.instagram !== undefined ? serialized.instagram : ''
            }
        }

        if(!this.validateDate(serialized.b_date)){
            toast.error('Please check your birth day format, make sure the format is YYYY-MM-DD')
            return new Promise((resolve) => resolve())
        }

        if(serialized.username !== undefined){
            param.username = serialized.username
        }

        if(serialized.password !== undefined){
            param.password = serialized.password
        }



        this.setState({loading: true})
        fetch(process.env.REACT_APP_API_URL+'api/v1/user/info/update', 
            {
                method: 'post',
                headers: new Headers({
                    'Authorization': 'Token '+this.props.auth.user.token
                }),
                body: JSON.stringify(param)
            }
        ).then((res)=>{
            return res.json()
        }).then((res)=>{
            this.setState({loading: false})
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }

            if(res.status){
                toast.success('Profile Updated.')
                setTimeout(()=>{
                    this.props.loginAction(res.data)
                    this.props.navAction('subject')
                }, 1000)
            }
        }).catch((e)=>{
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
        return new Promise((resolve) => resolve())
    }

    handleSubmit = () =>{
        var phone = document.getElementById("phone_picker").childNodes;
        var tz = document.getElementById("timezone_picker").childNodes;
        phone = phone[1].textContent
        phone = phone.split(' ')
        this.setState({phone_code: phone[0]})

        tz = tz[1].textContent
        tz = tz.split(' ')
        this.setState({timezone: tz[0]})

        this.form.submit()
    }

    validateDate = (dateString) =>{
        if(dateString !== "" && dateString !== undefined){
            let regEx = /^\d{4}-\d{2}-\d{2}$/;
            if(!dateString.match(regEx)) return false;  // Invalid format
            let d = new Date(dateString);
            let dNum = d.getTime();
            if(!dNum && dNum !== 0) return false; // NaN value, Invalid date
            return d.toISOString().slice(0,10) === dateString;
        }
        return true
    }    

    render() {
        return (
            <div>
                <Header as='h1'>
                    Complete Your Profile Information
                </Header>
                <Segment style={{paddingBottom: 50, marginBottom: 50}}>
                    <Form messages={messages} action={this.saveProfile} id='profile_form' className='ui form' ref={el => this.form = el}>
                        <CompletionProfileForm auth={this.props.auth}/>
                    </Form>
                    <Divider hidden/>
                    <Button id='btn_save_profile' floated='right' primary onClick={this.handleSubmit} loading={this.state.loading} disabled={this.state.loading}>
                        <Icon name='save'/> Save Profile
                    </Button>
                </Segment>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompletionProfilePage)