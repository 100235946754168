import React from 'react'
import { Loader, Grid, Button, Divider } from 'semantic-ui-react'
import {connect} from 'react-redux'
import storage from 'redux-persist/lib/storage';
import { toast } from 'react-toastify'
import EmptyComponent from '../../components/empty';
import ActivityText from './activity_text';

// to detect every store of redux/state changed
function mapStateToProps(state) {
    return { 
        auth: state.auth
    }
}

class ProfileActivitySection extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            activePage: 1,
            loading: false,
            activityHistory: [],
            isEmpty: false
        }
    }

    divRef = null

    componentDidMount(){
        this.loadData()
    }

    loadData = (activePage = 1) =>{
        let username = this.props.match.params.username
        username = username === 'me' ? this.props.auth.user.username : username
        this.setState({loading: true})
        fetch(process.env.REACT_APP_API_URL+'api/v1/user/activity-history?username='+username, {
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            }),
            method:'post',
            body: JSON.stringify({
                page: activePage,
                n: 10
            })
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }

            let oldData = this.state.activityHistory
            let data = []
            let isEmpty = false

            if(res.data.length === 0 || res.data.length < 10){
                isEmpty = true
            }

            data = oldData.concat(res.data)

            this.setState({activityHistory: data, loading: false, isEmpty: isEmpty})

            if(activePage > 1){
                this.divRef.scrollTop = this.divRef.scrollHeight;
            }
        }).catch((e)=>{
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    loadMore = () =>{
        this.setState({activePage: this.state.activePage + 1})
        this.loadData(this.state.activePage + 1)
    }

    render() {
        return (
            <div className='ui segment' style={{height: '75vh', overflow:'auto'}} ref={(el) => { this.divRef = el }}>
                {this.state.loading ? <Loader active/>
                : 
                    this.state.activityHistory.length > 0 ?
                    <>
                        <Grid columns='equal'>
                        {this.state.activityHistory.map((v,k)=>
                            <ActivityText key={k} data={v}/>
                        )}
                        </Grid>

                        <Divider hidden/>
                        {!this.state.isEmpty ? 
                            <div style={{textAlign: 'center'}}>
                                <Button loading={this.state.loading}
                                onClick={this.loadMore} primary>
                                    Load More
                                    </Button>
                            </div>
                        : null}
                    </>
                    : <EmptyComponent size='tiny'/>
                }
            </div>
        )
    }
}

export default connect(mapStateToProps)(ProfileActivitySection)