import React, {useState} from 'react'
import {Grid, Divider, Button, Icon, Modal, Loader} from 'semantic-ui-react'
/* Fields */
import { Input, Textarea } from '../fields'
import { Form, FormProvider } from 'react-advanced-form'
import ChangePasswordForm from '../auth/change_password';
import messages from '../auth/validate_message';
import rules from '../auth/validate_rule';
import { toast } from 'react-toastify';
import storage from 'redux-persist/lib/storage';

let styles = {
    link: {
        cursor: 'pointer',
        textDecoration: 'underline',
        color: 'black',
        fontWeight: 500
    }
}

// module 1 form
const SettingProfileForm = (props) =>{
    const [modalOpen, setModalOpen] = useState(false)
    const [form, setForm] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false)
    const [emailProcess, setEmailProcess] = useState(false)
    const myHeaders = new Headers({
        'Authorization': 'Token '+props.token
    });
    
    let userData = props.data
    let web,fb,instagram,linked,twitter = ''
    if(userData.contact_info !== null){
        web = userData.contact_info.web !== undefined && userData.contact_info.web !== null ? userData.contact_info.web : ''
        fb = userData.contact_info.facebook !== undefined && userData.contact_info.facebook !== null ? userData.contact_info.facebook : ''
        instagram = userData.contact_info.instagram !== undefined && userData.contact_info.instagram !== null ? userData.contact_info.instagram : ''
        linked = userData.contact_info.linkedin !== undefined && userData.contact_info.linkedin !== null ? userData.contact_info.linkedin : ''
        twitter = userData.contact_info.twitter !== undefined && userData.contact_info.twitter !== null ? userData.contact_info.twitter : ''
    }

    const handleChangePassword = (serialized) =>{
        setBtnLoading(true)
        let dataForm = serialized.serialized
        fetch(process.env.REACT_APP_API_URL+'api/v1/auth/password/',{
            method: 'post',
            headers: myHeaders,
            body: JSON.stringify({old_pass: dataForm.old_password, new_pass: dataForm.new_password})
        }).then(function(response) {
            return response.json()
        }).then(function(response) {
            setBtnLoading(false)
            toast.dismiss();
            if(response.status !== undefined){
                if(response.status){
                    toast.success(response.message);
                    setModalOpen(false)
                }else{
                    toast.error(response.message);
                }
            }else{
                storage.removeItem('persist:root')
                window.location = '/'
            }
        }).catch((e)=>{
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
        return new Promise((resolve) => resolve())
    }

    const resendEmail = () =>{
        setEmailProcess(true)
        fetch(process.env.REACT_APP_API_URL+'api/v1/auth/resend-verify-email',{
            headers: myHeaders
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            setEmailProcess(false)
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }

            if(res.status){
                toast.success('Verification link sent')
            }
        }).catch((e)=>{
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    return <Grid columns='equal'>
        <Grid.Column>
            <Input label='Email' name="email" initialValue={userData.email}/>
            {userData.email_verified ? <span style={{fontSize: 12, color:'#00B02F'}}>Email Verified</span> : 
                <span style={{fontSize: 12, color:'#794b02'}}>
                    Email isn't verified yet, check your email or {emailProcess ? <Loader size='small' inline active/> : <b onClick={resendEmail} style={styles.link}>Resend Verification</b> } 
                </span>
            }
            
            <Textarea initialValue={userData.short_bio} label='Short Bio' name="short_bio" wlength={160}/>
            <Divider hidden/>
            <Button type='button' id="profile_setting_changepass" secondary onClick={()=>setModalOpen(true)}>
                <Icon name='lock'/> Change Password
            </Button>
        </Grid.Column>
        <Grid.Column>
            <Input initialValue={web} label='Website URL' name="w_url"/>
            <Input initialValue={linked} label='Linkedin Username' name="linkedin"/>
            <Input initialValue={twitter} label='Twitter Username' name="twitter"/>
            <Input initialValue={fb} label='Facebook Username' name="facebook"/>
            <Input initialValue={instagram} label='Instagram Username' name="instagram"/>
        </Grid.Column>



        {/* modal */}
        <Modal open={modalOpen} size='mini'>
            <Modal.Header>Change Password</Modal.Header>
            <Modal.Content>
                <FormProvider rules={rules} messages={messages}>
                    <Form action={handleChangePassword} className='ui form' ref={(e)=>setForm(e)}>
                        <ChangePasswordForm/>
                    </Form>
                </FormProvider>
            </Modal.Content>
            <Modal.Actions>
                <Button style={{color:'grey'}} onClick={()=>setModalOpen(false)} className='tertiary'>Cancel</Button>
                <Button loading={btnLoading} disabled={btnLoading} className='tertiary' onClick={()=>form.submit()}>Save</Button>
            </Modal.Actions>
        </Modal>
    </Grid>
}

export default SettingProfileForm