import React, { useState } from 'react'
import { Container, Button, Popup } from 'semantic-ui-react'
import CardComponent from '../../../components/card'
import { useDispatch } from 'react-redux';
import { addSubjectStepAction } from '../../../store/actions/subject'
import { toast } from 'react-toastify';
import storage from 'redux-persist/lib/storage';
import ImageCropperComponent from '../../../components/image_cropper';
import { filterSubjectRedux } from '../../../helpers/redux';

// cover image section that set cover image for subject
// params : props header and current subject
const CoverImageSecton = (props) =>{
    const [fileInput, setFileInput] = useState(null)
    const dispatch = useDispatch()
    const [imgSrc, setImgSrc] = useState(null)
    const [modalOpen, setModalOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [currSub, setCurrSub] = useState([props.currentSubject])

    // process the image after attached
    const onChangeImage = (e)=>{
        e.preventDefault();
        let files;

        if(e.target.files[0] === undefined){
            return false
        }

        if(e.target.files[0].type !== 'image/jpeg'){
            toast.error('Please choose correct format image.')
            return false
        }

        if(e.target.files[0].size > 2000000){
            toast.error('Image should be equal to or less than 2MB.')
            return false
        }

        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        
        const reader = new FileReader();
        reader.onload = () => {
            setImgSrc(reader.result)
            setModalOpen(true)
        };
        reader.readAsDataURL(files[0]);
    }

    // upload the cover image when select the image file
    const addConverImage = (src) =>{
        fileInput.value = null
        setFileInput(fileInput)
        if(src === null){
            setImgSrc(null)
            setModalOpen(false)
            return false
        }
        setLoading(true)
        fetch(process.env.REACT_APP_API_URL+'api/v1/subjects/save/', {
            method: 'post',
            headers: props.myHeaders,
            body: JSON.stringify({image: src, code: props.currentSubject.code})
        }).then(function(response) {
            return response.json()
        }).then(function(response) {
            if(response.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
            if(response.status){
                dispatch(addSubjectStepAction(filterSubjectRedux(props.add_subject_step, response.data)))
                setCurrSub([response.data])
                setLoading(false)
                setModalOpen(false)
                fileInput.value = null
            }
        }).catch(function(e) {
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    return(
        <Container text className='cover_image'>
            <table>
                <tbody>
                    <tr valign="top">
                        <td><CardComponent data={currSub} type='single'/></td>
                        <td style={{paddingLeft: 20}}>
                            <h3>REVIEW</h3>
                            <p>This is how your cover image will look on subject list.</p>
                            <input onChange={onChangeImage} accept="image/jpeg" style={{display:'none'}} ref={el => setFileInput(el)} type='file' name='cover_image'/>
                            <Popup trigger={
                                <Button secondary onClick={()=>fileInput.click()}>Change Cover Image</Button>
                            } content='Image size (Max: 2MB)'></Popup>
                            
                        </td>
                    </tr>
                </tbody>
            </table>
            <ImageCropperComponent open={modalOpen} loading={loading} imgSrc={imgSrc} fileInput={fileInput} 
            ratio={16 / 9} act={addConverImage}/>
        </Container>
    )
}
export default CoverImageSecton