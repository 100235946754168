import React from 'react';
import { toast } from 'react-toastify';
import {connect} from 'react-redux';
import { Segment, Card, Loader, Icon } from 'semantic-ui-react';
import EmptyComponent from '../../../../components/empty';
import storage from 'redux-persist/lib/storage'
import {Link} from 'react-router-dom';
import './style.scss'

function mapStateToProps(state) {
    return { 
        add_subject_step: state.add_subject_step,
        auth: state.auth
    }
}

class AdaptiveContent extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            loading: true,
            modules: []
        }
    }

    componentDidMount(){
        this.loadData()
    }

    loadData = () =>{
        fetch(process.env.REACT_APP_API_URL+'api/v1/adaptive/'+this.props.match.params.code+'/modules', {
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            })
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href='/'
            }
            this.setState({modules: res.data, loading: false})
        }).catch((e)=>{
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })

    }

    selectName = (name, e) =>{
        localStorage.setItem('ADAPTIVE_MODULE_NAME', name)
    }

    render(){
        return(
            <Segment>
                {this.state.loading ? <Loader active/> : 
                    <Card.Group itemsPerRow={2}>
                        {this.state.modules.length > 0 ? 
                            this.state.modules.map((v,k)=>
                                <Link key={k} onClick={this.selectName.bind(null, v.module_name)}
                                className="ui adaptive card" to={window.location.pathname+'/'+v.subject_module_id}>
                                    <div>
                                        <Icon name='folder' size='massive'/>
                                    </div>
                                    <Card.Content>
                                        <Card.Header>{v.module_name}</Card.Header>
                                        <Card.Description>{v.mini_games.join(', ')}</Card.Description>
                                    </Card.Content>
                                </Link>
                            ) : <EmptyComponent/>
                        }
                    </Card.Group>
                }
            </Segment>
        )
    }
}

export default connect(mapStateToProps)(AdaptiveContent)