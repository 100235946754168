import React from 'react'
import { Grid, Button, Icon, Divider, Table, Pagination, Dropdown,
Modal,
Loader,
Checkbox,
Container, Image,
Input,
Form, Popup, Tab,
List,
Dimmer} from 'semantic-ui-react'
import '../style.scss'
import {connect} from 'react-redux'
import storage from 'redux-persist/lib/storage';
import { toast } from 'react-toastify'
import ReactPlayer from 'react-player'
import  Carousel  from  'semantic-ui-carousel-react';
import ImageCropperComponent from '../../../components/image_cropper';
import { PlatformInit } from '../../../helpers/global';
import EmptyComponent from '../../../components/empty';

// to detect every store of redux/state changed
function mapStateToProps(state){
    return{
        auth: state.auth
    }
}

function initValue(type){
    let result = {
        module_id: 1,
        name: '',
        minimum_entries: 0,
        description: '',
        icon: '',
        platforms: ['doc'],
        video_link: {doc:''}
    }
    if(type === 1){
        result = PlatformInit()
        result[0].active = true
    }
    return result
}

class MinigamesPage extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            minigameList: [],
            loading: true,
            filter: {
                page: 1,
                n: 10,
                order_by: 0,
                asc: 1,
                unpublished_only: 0,
                search: ''
            },
            totalPage: 0,
            totalRecord: 0,
            videoModal: false,
            screenshotModal: false,
            selectedVideo: [],
            selectedSS: [],
            formModal: false,
            moduleList: [],
            cropModal: false,
            imgSrc: '',
            form: initValue(0),
            selectedMG: null,
            loadingSave: false,
            teachingFormatList: [],
            selectedTeachingFormat: [],
            loadingModal: true,
            platform: initValue(1),
            refreshPreviewLoading: false,
            selectedPreview: null
        }
    }

    fileInput = null

    componentDidMount(){
        this.loadModule()
        this.loadData()
    }

    loadModule = () =>{
        fetch(process.env.REACT_APP_API_URL+'api/v1/admin/modules', {
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            })
        })
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
            let mdl = []
            let form = this.state.form
            res.data.forEach(val=>{
                mdl.push({
                    key: val.module_id,
                    value: val.module_id,
                    text: val.name
                })
            })
            form.module_id = mdl.length > 0 ? mdl[0].value : 0
            this.setState({moduleList: mdl, form: form})
            this.loadTeachingFormat()
        }).catch((e)=>{
            toast.error('something wrong happened on getting minigames.')
        })
    }

    loadTeachingFormat = () =>{
        fetch(process.env.REACT_APP_API_URL+'api/v1/admin/teaching-materials', {
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            })
        })
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
            this.setState({teachingFormatList: res.data, loadingModal: false})
        })
        .catch((e)=>{
            toast.error('something wrong happened on getting teaching format.')
        })
    }

    loadData = () =>{
        fetch(process.env.REACT_APP_API_URL+'api/v1/admin/mini-games', {
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            }),
            method: 'post',
            body: JSON.stringify(this.state.filter)
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
            this.setState({minigameList: res.data, loading: false, 
                totalPage: res.total_page, 
                totalRecord: res.total_record})
        }).catch((e)=>{
            toast.error('something wrong happened on getting minigames.')
        })
    }

    handleSort = (clickedColumn) => () => {
        let filter = this.state.filter
        filter.order_by = clickedColumn
        filter.asc = filter.asc === 1 ? 0 : 1
        this.setState({
            filter: filter
        })

        setTimeout(()=>{
            this.loadData()
        }, 100)
    }

    handleChangePagination = (e, data) =>{
        let filter = this.state.filter
        filter.page = data.activePage
        this.setState({filter: filter})
        window.setTimeout(()=>{
            this.loadData()
        }, 100)
    }

    handleChangeEntries = (e, data) =>{
        let filter = this.state.filter
        filter.n = data.value
        this.setState({filter: filter})
        window.setTimeout(()=>{
            this.loadData()
        }, 100)
    }

    handleActions = (id, e, btn) =>{
        let config = {
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            })
        }
        let type = btn.id
        if(type === 'delete'){
            if(window.confirm('Are you sure want to delete this?')){
                config.method = 'delete'
            }else{
                return false
            }
        }else{
            let msg = `Are you sure want to publish this? When published minigame will display and cannot be unpublished.`
            if(!window.confirm(msg)){
                return false
            }
        }
        fetch(process.env.REACT_APP_API_URL+'api/v1/admin/mini-games/'+type+'/'+id, config)
        .then((res)=>{
            return res.json()
        }).then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
            if(res.status){
                toast.success(type+ ' mini game success.')
                this.loadData()
            }else{
                toast.error('error '+type+ ' user.')
            }
        }).catch((e)=>{
            toast.error('something wrong happened on handling '+type+' of minigame')
        })
    }

    handleFilter = (e, data) =>{
        let filter = this.state.filter
        switch (data.name) {
            case 'search':
                filter.search = data.value
                break;
            default:
                filter.unpublished_only = data.checked
                break;
        }
        this.setState({filter: filter})
        setTimeout(()=>{
            this.loadData()
        }, 200)
    }

    saveMinigame = () =>{
        let form = this.state.form
        let messages = []
        if(form.name === ''){
            messages.push(<List.Item key='0'>Minigame Name is Required.</List.Item>)
        }
        if(form.minimum_entries === 0){
            messages.push(<List.Item key='1'>Minimun Entries is Required.</List.Item>)
        }

        if(this.state.selectedTeachingFormat.length === 0){
            messages.push(<List.Item key='2'>Teaching Format is Required.</List.Item>)
        }

        if(form.platforms.length === 0){
            messages.push(<List.Item key='3'>Platform is Required.</List.Item>)
        }else{
            let plt = this.state.platform
            plt = plt.filter(x=>x.active)
            plt.forEach(x=>{
                if(form.video_link[x.value] === undefined || form.video_link[x.value] === ''){
                    messages.push(<List.Item key={x.value}>Video Link {x.text} is Required.</List.Item>)
                }
            })
        }
        
        if(form.icon === '' && (this.state.selectedMG === null || this.state.selectedMG.length === 0)){
            messages.push(<List.Item key='4'>Icon is Required.</List.Item>)
        }

        if(messages.length > 0){
            toast.error(<List bulleted>
                {messages}
            </List>)
            return false
        }

        form.teaching_materials = this.state.selectedTeachingFormat
        this.setState({loadingSave: true})
        fetch(process.env.REACT_APP_API_URL+'api/v1/admin/mini-games/save', {
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            }),
            method: 'post',
            body: JSON.stringify(form)
        })
        .then((res)=>{
            return res.json()
        }).then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
            if(res.status){
                toast.success('Minigame saved.')
                this.setState({selectedMG: null, loadingSave: false, formModal: false, form: initValue(0), platform: initValue(1)})
                this.loadData()
            }else{
                toast.error('Minigame cannot be saved.')
            }
        }).catch((e)=>{
            toast.error('something wrong happened on saving minigame.')
        })
    }

    renderNumber = (k)=>{
        let n = this.state.filter.n * this.state.filter.page
        n = n - this.state.filter.n + k + 1
        return n
    }

    onChangeImage = (e)=>{
        e.preventDefault();
        let files;

        if(e.target.files[0] === undefined){
            return false
        }

        if(e.target.files[0].type !== 'image/jpeg'){
            toast.error('Please choose correct format image.')
            return false
        }

        if(e.target.files[0].size > 2000000){
            toast.error('Image should be equal to or less than 2MB.')
            return false
        }
        
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        
        const reader = new FileReader();
        reader.onload = () => {
            this.setState({cropModal: true, imgSrc: reader.result})
        };
        reader.readAsDataURL(files[0]);
    }

    setIconMinigame = (src) =>{
        this.fileInput.value = null
        let form = this.state.form
        if(src === null){
            form.icon = ''
            this.setState({cropModal: false, imgSrc: '', form: form})
            return false
        }
        form.icon = src
        this.setState({cropModal: false, form: form})
    }

    handleChangeForm = (e, data) =>{
        let form = this.state.form
        switch (data.name) {
            case 'mg_name':
                form.name = data.value
                break;
            case 'mg_module':
                form.module_id = data.value
                break;
            case 'mg_entries':
                form.minimum_entries = data.value
                break;
            case 'mg_desc':
                form.description = data.value
                break;
            default:
                form.video_link[data.name] = data.value
                break;
        }
        this.setState({form: form})
    }

    editSelected = (data, e) =>{
        let form = this.state.form
        let platform = this.state.platform
        platform.filter(x=>x.active).map(x=>{
            x.active = false
            return x
        })
        let tf = []
        form.id = data.mini_game_id
        form.name = data.name
        form.minimum_entries = data.minimum_entries
        form.module_id = data.module_id
        form.description = data.description
        form.platforms = data.platforms
        data.platforms.forEach(val=>{
            form.video_link[val] = data.video[val]
            let idx = platform.findIndex(x=>x.value === val)
            if(idx !== -1){
                platform[idx].active = true
            }
        })
        tf = data.teaching_materials.map((x)=>{
            return x.teaching_material_id
        })
        this.setState({selectedMG: data, form: form, formModal: true, 
            selectedTeachingFormat: tf, platform: platform})
    }

    handleTeachingFormat = (e, data) =>{
        let tf = this.state.selectedTeachingFormat
        let tf_id = parseInt(data.id)
        if(data.checked){
            let idx = tf.findIndex(x=>x === tf_id)
            if(idx === -1){
                tf.push(tf_id)
            }
        }else{
            let idx = tf.findIndex(x=>x === tf_id)
            if(idx !== -1){
                tf.splice(idx, 1)
            }
        }
        this.setState({selectedTeachingFormat: tf})
    }

    handlePlatform = (e, data) =>{
        let platform = this.state.platform
        let form = this.state.form
        let idx = platform.findIndex(x=>x.value === data.id)
        if(idx !== -1){
            platform[idx].active = data.checked
            if(!data.checked){
                delete form.video_link[data.id]
            }
        }
        form.platforms = platform.filter(x=>x.active).map(x=>{
            return x.value
        })
        this.setState({platform: platform, form: form})
    }

    refreshPreview = () =>{
        this.setState({refreshPreviewLoading: true})
        this.openScreenshot(this.state.selectedPreview)
    }

    openScreenshot = (data, e) =>{
        this.setState({selectedPreview: data})
        let tabPlatform = []
        this.state.platform.forEach(x=>{
            let ss = []
            if(data.screenshots[x.value] !== undefined){
                data.screenshots[x.value].forEach(xx=>{
                    ss.push({
                        render:()=>{
                            return <Image fluid src={xx}/>
                        }
                    })
                })
            }
            tabPlatform.push({
                menuItem: x.text,
                render: () => <Tab.Pane attached={false}>
                    {ss.length > 0 ? 
                    <Carousel
                        elements={ss}
                        animation="slide left"
                        showNextPrev={ss.length > 1}
                        showIndicators={ss.length > 1}
                    />
                    : <EmptyComponent/>}
                </Tab.Pane>
            })
        })

        this.setState({screenshotModal: true, selectedSS: tabPlatform, refreshPreviewLoading: false})
    }

    openVideo = (data, e) =>{
        let tabPlatform = []
        this.state.platform.forEach(x=>{
            tabPlatform.push({
                menuItem: x.text,
                render: () => <Tab.Pane attached={false}>
                    {data.video[x.value] !== undefined && data.video[x.value] !== '' ? 
                    <ReactPlayer width={'100%'} url={data.video[x.value]}/>
                    : <EmptyComponent/>}
                </Tab.Pane>
            })
        })
        this.setState({
            videoModal: true, selectedVideo: tabPlatform
        })
    }

    openAddMGModal = () =>{
        this.setState({selectedMG: null, form: initValue(0), platform: initValue(1), formModal: true})
    }

    render() {
        let activePlatform = this.state.platform.filter(x=>x.active)
        return (
            <Container style={{paddingTop: 80, minHeight: '66.5vh'}}>
                {this.state.loading ? <Loader active/> :
                    <>
                    <Grid columns='equal'>
                        <Grid.Row>
                            <Grid.Column>
                                <div className="ui icon rounded input">
                                    <Input type="text" icon='search' name='search' onChange={this.handleFilter}
                                    placeholder="Search a word or sentences..."/>
                                </div>
                                <Checkbox name='unpublish' toggle onChange={this.handleFilter}
                                label='Unpublished Minigames Only' style={{marginLeft: 15}}/>
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <Button primary floated='right' 
                                onClick={this.openAddMGModal}>
                                    <Icon name='add'/> Add New Minigame
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Table fixed sortable className='admin' striped>
                        <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={1}>
                            No
                            </Table.HeaderCell>
                            <Table.HeaderCell
                            sorted={this.state.filter.order_by === 0 ? 
                                this.state.filter.asc === 1 ? 'ascending' : 'descending'    
                            : null}
                            onClick={this.handleSort(0)}
                            >
                            MG Name
                            </Table.HeaderCell>
                            <Table.HeaderCell
                            sorted={this.state.filter.order_by === 1 ? 
                                this.state.filter.asc === 1 ? 'ascending' : 'descending'    
                            : null}
                            onClick={this.handleSort(1)}
                            >
                            Module
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                            Icon
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                            Screenshot & Video
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                            Description
                            </Table.HeaderCell>
                            <Table.HeaderCell
                            sorted={this.state.filter.order_by === 2 ? 
                                this.state.filter.asc === 1 ? 'ascending' : 'descending'    
                            : null}
                            onClick={this.handleSort(2)}
                            >
                            Min Entry
                            </Table.HeaderCell>
                            <Table.HeaderCell
                            sorted={this.state.filter.order_by === 3 ? 
                                this.state.filter.asc === 1 ? 'ascending' : 'descending'    
                            : null}
                            onClick={this.handleSort(3)}
                            >
                            Used By
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                            Platform
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                            Action
                            </Table.HeaderCell>
                        </Table.Row>
                        </Table.Header>
                        <Table.Body>
                        {this.state.minigameList.map((v, k)=>
                        <Table.Row key={k} className={v.banned ? 'banned' : ''}>
                            <Table.Cell>{this.renderNumber(k)}</Table.Cell>
                            <Table.Cell>{v.name}</Table.Cell>
                            <Table.Cell>{v.module_name}</Table.Cell>
                            <Table.Cell>
                                <Image size='tiny' src={v.icon+'?'+new Date().getTime()}/>
                            </Table.Cell>
                            <Table.Cell>
                            {v.screenshots !== null ? 
                            <div className='seeDetail'
                            onClick={this.openScreenshot.bind(null, v)}>Open Screenshot</div>
                            : null}
                            {v.video !== null ? 
                            <div className='seeDetail' 
                            onClick={this.openVideo.bind(null, v)}>Open Video</div>
                            : null}
                            </Table.Cell>
                            <Table.Cell>{v.description}</Table.Cell>
                            <Table.Cell><b>{v.minimum_entries}</b> entries</Table.Cell>
                            <Table.Cell>
                                <p>Subjects: <b>{v.used_by_subject}</b></p>
                                <p>Chapters: <b>{v.used_by_chapter}</b></p>
                            </Table.Cell>
                            <Table.Cell>
                                {v.is_doc !== undefined && v.is_doc ? 
                                    <Popup trigger={<Icon name='gamepad' color='blue' circular/>} content='DOC Game'/>
                                : null}
                                {v.is_chatbot !== undefined && v.is_chatbot ? 
                                    <Popup trigger={<Icon name='telegram' color='blue' circular/>} content='Telegram Chatbot'/>
                                : null}
                            </Table.Cell>
                            <Table.Cell>
                                {!v.published ? 
                                    <>
                                    <Button size='mini' onClick={this.handleActions.bind(null, v.mini_game_id)}
                                    id='publish' positive>
                                        <Icon name='paper plane'/> Publish
                                    </Button>
                                    <Button size='mini' onClick={this.handleActions.bind(null, v.mini_game_id)}
                                    id='delete' secondary>
                                        <Icon name='trash'/> Delete
                                    </Button>
                                    </>
                                : null                                    
                                }
                                <Button size='mini' onClick={this.editSelected.bind(null, v)}
                                id='unverify' secondary>
                                    <Icon name='pencil'/> Edit
                                </Button>
                            </Table.Cell>
                        </Table.Row>
                        )}
                        </Table.Body>
                    </Table>
                    
                    
                    {/* pagination */}
                    <Divider hidden/>
                    <div>
                        Show <Dropdown defaultValue={10} compact selection options={[
                            {key: 10, text: '10', value: 10},
                            {key: 25, text: '25', value: 25},
                            {key: 50, text: '50', value: 50},
                            {key: 100, text: '100', value: 100},
                        ]} onChange={this.handleChangeEntries} /> entries
                        <div className='pagination'>
                            <span style={{marginRight: 10}}>
                                Showing {this.state.minigameList.length} of {this.state.totalRecord} entries
                            </span>
                            <Pagination activePage={this.state.filter.page} size='small' onPageChange={this.handleChangePagination} 
                            totalPages={this.state.totalPage} />
                        </div>
                    </div>
                    </>
                }

                <Modal open={this.state.videoModal} closeIcon centered={false}
                onClose={()=>this.setState({videoModal: false})}>
                    <Modal.Header>Minigame Video</Modal.Header>
                    <Modal.Content>
                        <Tab menu={{ secondary: true }} panes={this.state.selectedVideo} />
                    </Modal.Content>
                </Modal>

                <Modal open={this.state.screenshotModal} 
                onClose={()=>this.setState({screenshotModal: false})} closeIcon>
                    <Modal.Header>
                        Minigame Screenshot
                        <p className='refreshModal'>Minigame screenshot not loaded well? <Button size='mini' color='green' onClick={this.refreshPreview}>refresh</Button></p>
                    </Modal.Header>
                    <Modal.Content>
                        <Dimmer inverted active={this.state.refreshPreviewLoading}>
                            <Loader active>Loading...</Loader>
                        </Dimmer>
                        <Tab menu={{ secondary: true }} panes={this.state.selectedSS} />
                    </Modal.Content>
                </Modal>
                
                <Modal open={this.state.formModal}>
                    <Modal.Header>Save Minigame</Modal.Header>
                    <Modal.Content>
                        {this.state.loadingModal ? <Loader active/> :
                        <Form>
                            <Grid columns='equal'>
                                <Grid.Column>
                                    <Form.Input name='mg_name' label='Minigame Name' value={this.state.form.name}
                                    onChange={this.handleChangeForm} placeholder='Ex: Copy Parrot'/>
                                    {this.state.moduleList.length > 0 ? 
                                    <Form.Select name='mg_module' onChange={this.handleChangeForm}
                                    options={this.state.moduleList} search 
                                    value={this.state.form.module_id}
                                    label='Module'/>
                                    : null}
                                    <Form.Input name='mg_entries' type='number' label='Minigame Entries' value={this.state.form.minimum_entries}
                                    onChange={this.handleChangeForm} placeholder='Type Minimum Entries...'/>
                                    <Form.TextArea name='mg_desc' label='Description' value={this.state.form.description}
                                    onChange={this.handleChangeForm} placeholder='Type Description...'/>
                                </Grid.Column>
                                <Grid.Column>
                                    <label>Teaching Format</label>
                                    <br/>
                                    {this.state.teachingFormatList.map((v, k)=>{
                                        let idx = this.state.selectedTeachingFormat.findIndex(x=>x===v.teaching_material_id)
                                        let is_checked = false
                                        if(idx !== -1){
                                            is_checked = true
                                        }
                                        return <Checkbox key={k} label={v.name} checked={is_checked}
                                        onChange={this.handleTeachingFormat} 
                                        style={{marginRight: 10}} id={v.teaching_material_id}/>
                                    }
                                    )}
                                    <Divider hidden/>
                                    <label>Platform</label><br/>
                                    {this.state.platform.map((v,k)=>
                                        <Checkbox key={k} label={v.text} checked={v.active}
                                        onChange={this.handlePlatform} 
                                        style={{marginRight: 10}} id={v.value}/>
                                    )}
                                    <Divider hidden/>
                                    {activePlatform.map((v,k)=>
                                    <Form.Input key={k} label={'Video Link '+v.text} name={v.value} 
                                    value={this.state.form.video_link[v.value] || ''}
                                    onChange={this.handleChangeForm} placeholder='Type Video Link...'/>
                                    )}
                                    <label>Icon</label>
                                    {this.state.form.icon !== '' ? 
                                        <Image size='tiny' src={this.state.form.icon}/>
                                    : this.state.selectedMG !== null ? 
                                        <Image size='tiny' src={this.state.selectedMG.icon}/>
                                    : <><Divider hidden/><Divider hidden/></>}

                                    <input onChange={this.onChangeImage} 
                                    accept="image/jpeg" 
                                    style={{display:'none'}} ref={el => this.fileInput = el} 
                                    type='file' name='icon'/>
                                    <br/>
                                    <Popup trigger={
                                        <Button secondary size='mini' onClick={()=>this.fileInput.click()}>
                                            <Icon name='folder open'/> Browse File
                                        </Button>
                                    } content='Image size (Max: 2MB)'></Popup>
                                    <ImageCropperComponent open={this.state.cropModal} loading={this.state.loading} 
                                    imgSrc={this.state.imgSrc} fileInput={this.fileInput} ratio={1 / 1} act={this.setIconMinigame}/>
                                    
                                </Grid.Column>
                            </Grid>
                        </Form>
                        }
                    </Modal.Content>
                    <Modal.Actions>
                        <Button className='tertiary' disabled={this.state.loadingSave}
                        onClick={()=>this.setState({formModal: false, selectedMG: null, form: initValue(0), platform: initValue(1)})}>Cancel</Button>
                        <Button primary onClick={this.saveMinigame} disabled={this.state.loadingSave}
                        loading={this.state.loadingSave}>Save Minigame</Button>
                    </Modal.Actions>
                </Modal>
            </Container>
        )
    }
}

export default connect(mapStateToProps)(MinigamesPage)