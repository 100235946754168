import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import storage from 'redux-persist/lib/storage';
import { Loader, Segment, Card, Icon } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import EmptyComponent from '../../../components/empty';
import { getSubjectRedux } from '../../../helpers/redux';

// to detect every store of redux/state changed
function mapStateToProps(state) {
    return { 
        add_subject_step: state.add_subject_step,
        auth: state.auth
    }
}

// to detect every store of redux/state changed
function mapDispatchToProps(dispatch) {
    return { 
        subNavAction: (n) => dispatch({ type: 'CHANGE_SUB_NAV', payload: n }),
        addSubjectStepAction: (n) => dispatch({ type: 'CURRENT_SUBJECT', payload: n }),
    }
}

// subject detail content includes chapter and minigames info
class DiagnosticPage extends React.Component{
    constructor(props){
        super(props)
        const currentSubject = getSubjectRedux(this.props.add_subject_step, this.props.match.params.code)
        if(currentSubject.category_id === null && currentSubject.role === 3 && !currentSubject.adaptive){
            window.location.href = '/subject/add?code='+this.props.match.params.code
            return false
        }
        this.state = {
            currentSubject: currentSubject,
            mgList: [],
            loading: true
        }
    }
    componentDidMount = () =>{
        fetch(process.env.REACT_APP_API_URL+'api/v1/diagnostic/games/'+this.props.match.params.code, {
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            })
        })
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }

            this.setState({mgList: res.data, loading: false})
        })
        .catch((e)=>{
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }
    render() {
        return (
            <Segment>
                {this.state.loading ? <Loader active/> : 
                    <Card.Group itemsPerRow={2}>
                        {this.state.mgList.length > 0 ? 
                            this.state.mgList.map((v,k)=>
                                <Link key={k} className="ui adaptive card" 
                                to={'/diagnostic/'+this.state.currentSubject.code+'/'+v.game_id}>
                                    <div>
                                        <Icon name='folder' size='massive'/>
                                    </div>
                                    <Card.Content>
                                        <Card.Header>{v.name}</Card.Header>
                                        <Card.Description>{v.description}</Card.Description>
                                    </Card.Content>
                                </Link>
                            ) : <EmptyComponent/>
                        }
                    </Card.Group>
                }
            </Segment>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DiagnosticPage)