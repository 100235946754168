import React from 'react';
import { toast } from 'react-toastify';
import { Step, Icon, Button, Loader, Dimmer, Modal, Header } from 'semantic-ui-react';
import RCPassageForm from './passage';
import RCPreview from './preview';
import RCQuestionForm from './question';
import storage from 'redux-persist/lib/storage';
import './style.scss'

export default class RCForm extends React.Component{
    constructor(props){
        super(props)
        let qid = localStorage.getItem('rc_edit_id')
        let qgen_data = localStorage.getItem('qgen_data')
        qgen_data = JSON.parse(qgen_data)
        this.state = {
            currentStep: 1,
            loading: false,
            generateModal: false,
            qgen_data: qgen_data,
            question_id: qid,
            topicData: null
        }
    }

    componentDidMount(){
        if(this.state.question_id !== null){
            this.changeStep(true)
        }
    }

    changeStep = (type, e) =>{
        let num = this.state.currentStep
        num = type ? num+1 : num-1
        this.setState({currentStep: num})
    }

    save = (param) =>{
        let data = param.data
        let request = null
        if(param.type === 'passage'){
            request = {
                topic: data.topic.value,
                passage: data.passage.value,
                source_type: data.source_type,
                source_title: data.source_title.value,
                source_url: data.source_url.value,
                retrieve_date: data.retrieve_date,
                image: data.image,
                show_image: data.show_image,
                reading_time: data.reading_time,
                is_automatic: data.is_automatic
            }
            let psg = localStorage.getItem('passage')
            let passBy = false
            let isAutoChange = false
            psg = JSON.parse(psg)
            if(psg !== null){
                if(psg.passage === request.passage){
                    passBy = true
                }

                if(psg.is_automatic !== request.is_automatic){
                    isAutoChange = true
                }
            }
            localStorage.setItem('passage', JSON.stringify(request))

            if(request.is_automatic && !passBy){
                this.setState({loading: true})
                let reqGQ = {
                    topic: request.topic,
                    passage: request.passage,
                    source_type: request.source_type,
                    source_title: request.source_title,
                    source_url: request.source_url,
                    retrieve_date: request.retrieve_date,
                    image: request.image,
                    show_image: request.show_image,
                    reading_time: request.reading_time,
                    is_automatic: request.is_automatic,
                    response_time: 0,
                    questions: []
                }
                if(reqGQ.image === null || reqGQ.image === ""){
                    delete reqGQ["image"]
                }

                let requestData = {
                    is_adaptive: this.props.is_adaptive,
                    question_object: reqGQ
                }

                if(this.state.question_id !== null){
                    requestData.id = this.state.question_id
                }

                if(this.state.qgen_data !== null && (this.state.qgen_data.is_canceled || this.state.qgen_data.status === 3)){
                    fetch(process.env.REACT_APP_API_URL+'api/v1/external/question-generation/retry/'+this.state.qgen_data.queue_id,{
                        headers: this.props.header
                    })
                    .then((res)=>{
                        return res.json()
                    })
                    .then((res)=>{
                        localStorage.removeItem('passage')
                        localStorage.removeItem('qgen_data')
                        localStorage.removeItem('question')
                        localStorage.removeItem('rc_edit_id')
                        this.setState({loading: false, generateModal: true})
                    })
                    .catch((e)=>{
                        toast.error(<div>
                            Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
                        </div>)
                    })

                    return false
                }

                if(this.props.is_topic !== undefined){
                    this.setState({generateModal: true, topicData: reqGQ})
                    localStorage.removeItem('passage')
                    localStorage.removeItem('qgen_data')
                    localStorage.removeItem('rc_edit_id')
                    localStorage.removeItem('question')
                    return false
                }

                fetch(process.env.REACT_APP_API_URL+'api/v1/questions/'+this.props.mg+'/save', {
                    headers: this.props.header,
                    method: 'post',
                    body: JSON.stringify(requestData)
                }).then((res)=>{
                    return res.json()
                }).then((res)=>{
                    localStorage.removeItem('question')
                    localStorage.removeItem('passage')
                    localStorage.removeItem('qgen_data')
                    localStorage.removeItem('rc_edit_id')
                    this.setState({loading: false, generateModal: true})
                }).catch((e)=>{
                    toast.error(<div>
                        Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
                    </div>)
                })
            }else{
                if(isAutoChange){
                    localStorage.removeItem('question')
                }
                this.changeStep(true)
            }
        }else if(param.type === 'question'){
            let emptyQuestion = param.data.question.filter(x=>x.question === '' || x.answer === '')
            if(emptyQuestion.length > 0){
                toast.warn(emptyQuestion.length+' question(s) not completed. Please make sure the question and answer entered.')
                return false
            }

            this.changeStep(true)
            localStorage.setItem('question', JSON.stringify(param.data))
        }else{
            let data = param.data.passage
            let req = {
                topic: data.topic,
                passage: data.passage,
                source_type: data.source_type,
                source_title: data.source_title,
                source_url: data.source_url,
                retrieve_date: data.retrieve_date,
                image: data.image,
                show_image: data.show_image,
                reading_time: data.reading_time,
                is_automatic: data.is_automatic,
                response_time: param.data.questions.response_time,
                questions: param.data.questions.question
            }

            if(req.image === null || req.image === ""){
                delete req["image"]
            }

            if(this.props.is_topic !== undefined){
                localStorage.removeItem('passage')
                localStorage.removeItem('generated_question')
                localStorage.removeItem('question')
                localStorage.removeItem('qgen_data')
                localStorage.removeItem('rc_edit_id')
                this.props.action(req)
                return false
            }

            this.setState({loading: true})
            
            let requestData = {
                is_adaptive: this.props.is_adaptive,
                question_object: req
            }

            if(this.state.question_id !== null){
                requestData.id = this.state.question_id
            }

            fetch(process.env.REACT_APP_API_URL+'api/v1/questions/'+this.props.mg+'/save', {
                headers: this.props.header,
                method: 'post',
                body: JSON.stringify(requestData)
            }).then((res)=>{
                return res.json()
            }).then((res)=>{
                this.setState({loading: false})
                if(res.status === undefined){
                    storage.removeItem('persist:root')
                    window.location.href = '/'
                }

                if(res.status){
                    toast.success('Question saved.')
                    localStorage.removeItem('passage')
                    localStorage.removeItem('generated_question')
                    localStorage.removeItem('question')
                    localStorage.removeItem('qgen_data')
                    localStorage.removeItem('rc_edit_id')
                    this.props.action()
                }else{
                    toast.error('Cannot save question.')
                }
            }).catch((e)=>{
                toast.error(<div>
                    Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
                </div>)
            })
        }
    }

    render() {
      return (
        <div style={{paddingBottom: 50}}>
        <Step.Group widths={3} className='customStep'>
            <Step active={this.state.currentStep === 1}>
            <div className='chapterNumber'>
                <h1>1</h1>
            </div>
            <Step.Content>
                <Step.Title>Passage</Step.Title>
            </Step.Content>
            </Step>
            <Step active={this.state.currentStep === 2}>
            <div className='chapterNumber'>
                <h1>2</h1>
            </div>
            <Step.Content>
                <Step.Title>Questions</Step.Title>
            </Step.Content>
            </Step>
            <Step active={this.state.currentStep === 3}>
            <div className='chapterNumber'>
                <h1>3</h1>
            </div>
            <Step.Content>
                <Step.Title>Preview</Step.Title>
            </Step.Content>
            </Step>
        </Step.Group>

        {this.state.currentStep === 1 ? 
        <RCPassageForm action={this.save}/>
        : null}
        {this.state.currentStep === 2 ? 
        <>
        
        {this.state.loading ?  
        <Dimmer active inverted>
            <Loader active content='Generating Question...' size='huge'/>
        </Dimmer>
        : 
        <>
        <Button className='tertiary' onClick={this.changeStep.bind(null, false)}
        style={{marginLeft: -20}}>
            <Icon name='arrow left'/> Back to add new passage
        </Button>
        <RCQuestionForm action={this.save}/>
        </>}
        </>
        : null}
        {this.state.currentStep === 3 ? 
        <>
        {this.state.loading ? 
        <Dimmer active inverted>
            <Loader active content='Saving Question...' size='huge'/>
        </Dimmer>
        : null}
        <Button className='tertiary' onClick={this.changeStep.bind(null, false)}
        style={{marginLeft: -20}}>
            <Icon name='arrow left'/> Back to add questions
        </Button>
        <RCPreview action={this.save}/>
        </>
        : null}

        <Modal size='tiny' open={this.state.generateModal}>
            <Modal.Header>Question Generating</Modal.Header>
            <Modal.Content>
                <Header as='h3'>
                    Your question is queued, please wait until the status is done before you manage the question(s).
                </Header>
            </Modal.Content>
            <Modal.Actions>
                {this.props.is_topic !== undefined ? 
                <Button primary onClick={()=>{
                    this.props.action(this.state.topicData)
                    this.setState({generateModal: false})
                }}>OK</Button>
                : <Button primary onClick={()=>this.props.closeModal()}>OK</Button>}
                
            </Modal.Actions>
        </Modal>
        </div>
      )
    };
}